<template>
<div>
    <div style="margin:5px;padding:5px">

<p class="mt-3 mb-5" style="text-align:left;font-size:25px;font-weight:bold">
           {{name.code}} {{name.name}} รถปกติ
    <span style="float:right;">
        จำนวน {{count}} คัน
    </span>
</p>
    <!-- <div v-for="(l, i) in list" :key="i">
      {{ l.code }} {{ l.name }} -->
      <table class="table table-bordered">
        <thead>
          <tr class="table-active">
            <!-- <th scope="col">ลำดับที่</th> -->
            <th scope="col">ลำดับรถ</th>
            <th scope="col">เงื่อนไข</th>
            <!-- <th scope="col">ประเภทรถ</th> -->
            <th scope="col">เลขทะเบียนรถ</th>
            <th scope="col">ยี่ห้อรถ</th>
            <th scope="col">เลขตัวรถ</th>
            <th scope="col">เลขเครื่องยนต์</th>
            <th scope="col">วันที่สิ้นอายุภาษี</th>
            <th scope="col">วันอนุมัติ</th>
            <th scope="col">ลักษณะ/มาตรฐาน(ช่าง)</th>
            <th scope="col">สถานะ</th>
            <th scope="col">สถานะการต่อภาษี</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in list" :key="i">
            <!-- <th scope="row">
              <a data-toggle="modal"
                style="color: black"                
                @click="editnotyettax(d.car_id,d.tax_status_id)">{{ d.no }}</a>
            </th> -->
            <th scope="row">
              <a data-toggle="modal"
                style="color: black"                
                @click="editnotyettax(d.car_id,d.tax_status_id)">{{ d.car_no }}</a>
            </th>
            <td>
              <a data-toggle="modal"
                style="color: black"                
                @click="editnotyettax(d.car_id,d.tax_status_id)"><span v-html="d.symbols"></span></a>
            </td>
            <!-- <td>
              <a :href="'cars/' + d.car_id">{{ d.code }} {{ d.car_type }}</a>
            </td> -->
            <td>
              <a data-toggle="modal"
                style="color: black"                
                @click="editnotyettax(d.car_id,d.tax_status_id)">{{ d.license }}</a>
            </td>
            <td>
              <a data-toggle="modal"
                style="color: black"                
                @click="editnotyettax(d.car_id,d.tax_status_id)">{{ d.brand }}</a>
            </td>
            <td>
              <a data-toggle="modal"
                style="color: black"                
                @click="editnotyettax(d.car_id,d.tax_status_id)">{{ d.VIN }}</a>
            </td>
            <td>
              <a data-toggle="modal"
                style="color: black"                
                @click="editnotyettax(d.car_id,d.tax_status_id)">{{ d.engine_number }}</a>
            </td>
            <td>
              <a data-toggle="modal"
                style="color: black"                
                @click="editnotyettax(d.car_id,d.tax_status_id)">{{
                convertdatetothai(d.tax_expiration_date)
              }}</a>
            </td>
            <td>
              <a data-toggle="modal"
                style="color: black"                
                @click="editnotyettax(d.car_id,d.tax_status_id)">{{
                convertdatetothai(d.approval_date)
              }}</a>
            </td>
            <td>
              <a data-toggle="modal"
                style="color: black"                
                @click="editnotyettax(d.car_id,d.tax_status_id)">{{ d.characteristic }}</a>
            </td>
            <td>
              <a v-if="d.status == 1"  data-toggle="modal"
                style="color: black"                
                @click="editnotyettax(d.car_id,d.tax_status_id)">ใช้งานอยู่</a>
              <a v-else data-toggle="modal"
                style="color: black"                
                @click="editnotyettax(d.car_id,d.tax_status_id)">ไม่ใช้งาน</a>
            </td>
             <td>
              <a data-toggle="modal"
                style="color: black"                
                @click="editnotyettax(d.car_id,d.tax_status_id)">{{ d.tax_status }}</a>
            </td> 
            <td> <a :href="'/receiptList'">
                <button type="button" class="btn btn-warning">
                  <i class="fa fa-edit"></i></button
              ></a></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="modal fade"
      id="editnotyettaxdata"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editnotyettaxdata"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ข้อมูลการต่อทะเบียน</h5> 
            <button
              type="button"
              id="closetax"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >           
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 mt-2">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >สถานะการต่อทะเบียน <span style="color: red"> *</span></label
                  >
                 <select
          v-model="tax_status_id"
          class="form-control"
          id="exampleFormControlSelect1"
        >
          <option
            v-for="(s, i) in tax_status"
            v-bind:value="s.tax_status_id"
            :key="i + 1"
          >
             {{ s.name }}
          </option>
        </select>
                </div>
              </div>
              <div class="col-md-8"></div>
            </div>    
          </div>
          <div class="row mt-3 mb-5">
        <div class="col-md-12 text-center">
          &nbsp;
          <button
            class="btn btn-success"
            style="color: white; width: 200px"
            @click="save()"
          >
            บันทึก
          </button>
          &nbsp;
          <!-- <button
              class="btn btn-danger"
              style="color: white; width: 200px"
              @click="next()"
            >
              ลบ
            </button> -->
        </div>
      </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CarService from "../services/CarService.js";
import CarTypesService from "../services/CarTypesService";

export default {
  name: "Carlist",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      car_types:[],
      car_type:'',
      name:{},
      count:'',
      tax_status_id:'',
      tax_status:[]
    };
  },
  methods: {
    editnotyettax(id,status){
      this.car_id = id
      this.tax_status_id = status
    },
    save(){
      var status = {
        tax_status_id:this.tax_status_id
      }
CarService.updateTaxStatus(this.car_id,status).then(()=>{
  alert('บันทึกสำเร็จ')
  document.getElementById("closetax").click();
  setTimeout(function () {
                            location.reload();
                          }, 300);

})
    },
    getTaxStatus(){
CarTypesService.getTaxStatus().then((res)=>{
  this.tax_status = res.data
})
    },
    getList() {
      CarService.getCarGroupNotYetTaxAll(this.car_type).then((res) => {
        console.log(res.data);
        this.list = res.data
      });
    },
    getCount() {
      CarService.getCarGroupNotYetTaxAll("",this.car_type).then((res) => {
        console.log(res.data);
        this.count = res.data[0].count
      });
      },
    getType(){
CarTypesService.getCarType(this.car_type).then((res)=>{
  this.name = res.data
})
    },
    getcartypes() {
      CarTypesService.getCarTypes('').then((res) => {
        console.log(res.data);
        this.car_types.push({
          car_type_id: 0,
          name: "ทั้งหมด",
        })
        for (let c = 0; c < res.data.length; c++) {
          this.car_types.push({
          car_type_id: res.data[c].car_type_id,
          name: res.data[c].code +" "+ res.data[c].name,
        })
        }
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
  },
  mounted() {
    this.car_type = this.$route.params.id
    console.log(this.car_type);
    this.getList();
    this.getType()
    this.getCount()
    this.getTaxStatus()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
  text-decoration: none;
}
</style>
