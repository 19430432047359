<template>
  <div class="container mt-3">   
  <div class="row col text-right" v-if="car_id == 0">
    <div class="col-md-6"></div>
     <div class="col-md-6">
      <div>
            <input
              v-model="license"
              type="text"
              class="form-control form-control-sm"
              id="exampleFormControlInput1"
              placeholder="เลขทะเบียนรถ"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            /><button
                class="btn btn-success btn-sm"
                type="button"
                @click="getLicense()"
              >
                ค้นหา
              </button>
            </div>
  </div>
  </div>

    <div
      v-if="editstatus"
      class="card mt-3 mb-5"
      style="padding-left: 1.5cm; background-color: white; padding-right: 1.5cm"
    >    
      <div style="margin-top: 15px; background-color: white">
        <div>
          <div
            style="text-align: center;background-color: white; margin: 0px"
            class="mb-3"
          >
            <label style="text-align: center; font-size: 16px;"
              >หนังสือสัญญาเดินรถร่วมห้างหุ้นส่วนจำกัด จ.โชคชัยหิรัญ</label
            >
          </div>
          <span>
            เขียนที่&nbsp;&nbsp;<input
              v-model="datas.writeat"
              type="text"
            />&nbsp;&nbsp;&nbsp;&nbsp; วันที่&nbsp;&nbsp;<input
              v-model="datas.date"
              type="text"
            /><br />
          </span>
          <span>
            หนังสือสัญญาฉบับนี้ทำขึ้นระหว่าง&nbsp;&nbsp;<input
              v-model="datas.one_contracts"
              style="width: 500px"
              type="text"
            /><br />
          </span>
          <span>
            บ้านเลขที่&nbsp;&nbsp;<input
              v-model="datas.house_no"
              type="text"
            />&nbsp;&nbsp;&nbsp;&nbsp;หมู่&nbsp;&nbsp;<input
              v-model="datas.moo"
              style="width: 90px"
              type="text"
            />&nbsp;&nbsp;&nbsp;ตรอก/ซอย&nbsp;&nbsp;<input
              v-model="datas.soi"
              type="text"
            />&nbsp;&nbsp;&nbsp;ถนน&nbsp;&nbsp;<input
              v-model="datas.road"
              type="text"
            />&nbsp;&nbsp;&nbsp;<br />
          </span>
          <span>
            ตำบล&nbsp;&nbsp;<input
              v-model="datas.subdistrict_id"
              type="text"
            />&nbsp;&nbsp;&nbsp; อำเภอ&nbsp;&nbsp;<input
              v-model="datas.district_id"
              type="text"
            />&nbsp;&nbsp;&nbsp;จังหวัด&nbsp;&nbsp;<input
              v-model="datas.province_id"
              type="text"
            />&nbsp;&nbsp;&nbsp;<br />
          </span>
          <span>
            ต่อไปนี้ในสัญญานี้เรียกว่า "ผู้เดินรถร่วม" ฝ่ายหนึ่งกับ
            ห้างหุ้นห่วนจำกัด จ.โชคชัยหิรัญ<br />
          </span>
          <span>
            โดย&nbsp;&nbsp;<input
              v-model="datas.by_data"
              type="text"
            />&nbsp;&nbsp;&nbsp;และ&nbsp;&nbsp;<input
              v-model="datas.and_data"
              type="text"
            />&nbsp;&nbsp;&nbsp;ซึ่งต่อไปนี้ในสัญญา เรียกว่า
            "ผู้รับเดินรถร่วม"<br />
          </span>
          <span> ทั้งสองฝ่ายตกลงทำสัญญากันไว้มีข้อความดังต่อไปนี้<br /> </span>
          <div class="aa">
            <p>
              1.<span style="margin-left:10px">สัญญาฉบับนี้ทำขึ้นเนื่องจากผู้เดินรถร่วมประสงค์ประกอบอาชีพเดินรถรับจ้างบรรทุกสัตว์และหรือสิ่งของทั่วราชอาณาจักรโดยขอเข้าร่วมใช้ใบอนุญาตประกอบการขนส่งกับทางห้างหุ้นส่วนจำกัด
              จ.โชคชัยหิรัญ</span>
            </p>
            <p>
              2.<span style="margin-left:7px">ผู้รับเดินรถร่วมเป็นผู้มีสิทธิประกอบการขนส่งไม่ประจำทางด้วยรถบรรทุกโดยได้รับอนุญาตจากนายทะเบียนตามกฎหมายว่าด้วยการขนส่งทางบก
              เลขที่
              <input v-model="datas.commuter_license" type="text" />
              ออกวันที่&nbsp;&nbsp;<input
                v-model="datas.commuter_date"
                type="text"
              />&nbsp;&nbsp;&nbsp; เดือน&nbsp;<input
                v-model="datas.commuter_month"
                type="text"
              />&nbsp;พ.ศ.&nbsp;<input v-model="datas.commuter_year" type="text" />&nbsp;
            </span></p>
            <p>
              3.<span style="margin-left:7px">ผู้เดินรถร่วมเป็นเจ้าของกรรมสิทธิ์หรือผู้เช่าซื้อรถหมายเลขทะเบียน
              <input v-model="datas.license" type="text" /> เชียงราย
              &nbsp;&nbsp;ยี่ห้อ
              <input v-model="datas.brand" type="text" />&nbsp;&nbsp;
              <br />เลขเครื่องยนต์
              <input v-model="datas.VIN" type="text" />เลขตัวรถ
              <input v-model="datas.engine_number" type="text" />
            </span></p>
            <p>
              4.<span style="margin-left:7px">ผู้เดินรถร่วมยินยอมที่จะปฏิบัติตามเงื่อนไขและระเบียบของทางราชการและของทางห้างหุ้นส่วนฯ
              ทุกประการ หากผู้เดินรถร่วมไม่ปฏิบัติตามเงื่อนไขข้อใดข้อหนึ่งหนังสือสัญญาเดินรถร่วมฉบับนี้ถือว่าไม่สมบูรณ์และให้ถือเป็นโมฆะทันทีโดยผู้เดินรถร่วมตกลงยอมรับสัญญา/ข้อตกลงต่าง
              ๆ ด้วยความสมัครใจโดยไม่ได้มีการบังคับ ขู่เข็ญ แต่อย่างใดทั้งสิ้น
            </span></p>
            <p>
              5.<span style="margin-left:7px">ค่าใช้จ่ายต่าง ๆ ในการใช้รถ เช่น ค่าน้ำมันเชื้อเพลิง
              น้ำหล่อลื่น ค่าบำรุงรักษา ค่าธรรมเนียมและค่าภาษีอากรต่าง ๆ
              ของผู้เดินรถร่วมเป็นผู้ชำระเองทั้งสิ้นการเสียภาษีรถต้องแนบสัญญาเดินรถร่วมของทางห้างฯ
            </span></p>
            <p>
              6.<span style="margin-left:7px">กรณีผู้รับเดินรถร่วมจำต้องชดใช้ค่าเสียหายเบื้องต้นให้แก่ผู้เสียหายอันเนื่องมาจากการใช้รถของผู้เดินรถร่วมตามที่บัญญัติไว้ในกฎหมายว่าด้วยกรมการขนส่งทางบกไปด้วยประการใด
              ๆ ก็ตาม ผู้เดินรถร่วมตกลงยินยอมรับสภาพหนี้กับทางห้างฯ
              และจะชำระและชดใช้ค่าใช้จ่ายต่างๆรวมทั้งดอกเบี้ยตามกฎหมายแก่ผู้รับเดินรถร่วมภายใน 7 วัน
              นับตั้งแต่วันที่ผู้รับเดินรถร่วมได้ชดใช้ไปหากผู้เดินรถร่วมไม่ปฏิบัติตามสัญญายินยอมถูกปรับเป็นเงิน 10
              เท่าของยอดเงินที่ได้ชำระไปในทันที
            </span></p>
            <p>
              7.<span style="margin-left:7px">ผู้เดินรถร่วม/ผู้ประจำรถมิใช่เป็นตัวแทนหรือลูกจ้างของผู้รับเดินรถร่วมแต่อย่างใดหากนำรถคันดังกล่าวไปใช้ในกระทำความผิดตามกฎหมายไม่ว่ากรณีใดๆก็ตามหากมีผู้ใดกล่าวหาว่าบุคคลดังกล่าวและเรียกร้องค่าเสียหายใด
              ๆทั้ง ทางแพ่งและทางอาญาในนามของผู้เดินรถร่วมกับผู้รับเดินรถร่วม
              ผู้เดินรถร่วม/ผู้ประจำรถต้องรับผิดชอบแต่เพียงฝ่ายเดียวในทุกกรณีและผู้รับเดินรถร่วมมิได้มีค่าตอบแทนและผลประโยชน์ร่วมใด
              ๆ ในการกระทำและการประกอบอาชีพใด ๆ ของผู้เดินรถร่วม/ผู้ประจำรถ
              อนึ่ง
              ผู้เดินรถร่วมจะต้องจัดทำประกันภัยรถยนต์ตามที่กฎหมายบังคับรวมทั้งต้องจัดทำประกันภัยรถยนต์ประเภทหนึ่ง
              สอง หรือสาม ในระหว่างที่เดินรถร่วมกับทางห้างฯ
            </span></p>
            <p>
              8.<span style="margin-left:7px">สัญญานี้ผู้รับเดินรถร่วมมีสิทธิ์ที่จะบอกเลิกผู้เดินรถร่วมได้ทันทีโดยมิต้องบอกกล่าว
              โดยไม่ต้องทำหนังสือแจ้งการบอกเลิกสัญญาแก่ผู้เดินรถร่วม
              ในกรณีที่ผู้เดินรถร่วมกระทำการด้วยเจตนาในทางไม่ปฏิบัติตามเงื่อนไข
              และระเบียบหรือกระทำการอื่นใดจนเป็นเหตุให้น่าจะเกิดความเสียหายแก่ผู้รับเดินรถร่วม</span><br />
              <span style="margin-left:45px">สัญญาฉบับนี้ทำขึ้นเป็นสองฉบับมีข้อความตรงกันทั้งสองฝ่ายได้อ่านเข้าใจดีแล้วจึงลงลายมือชื่อเป็นสำคัญต่อหน้า
              พยานและคู่สัญญาได้ยึดถือสัญญาฉบับนี้ไว้ฝ่ายละฉบับ
           </span> </p>
          </div>
        </div>
        <div class="row mt-5" style="text-align: center; font-size: 14px">
          <div class="col-sm-6">
            <span style="text-align: center"
              >ลงชื่อ..............................................................................ผู้รับเดินรถร่วม</span
            >
            <br />
            <span
              >(&nbsp;&nbsp;<input v-model="datas.signone" style="width:220px" type="text" />&nbsp;&nbsp;)</span
            >
          </div>
          <div class="col-sm-6">
            <span style="text-align: center"
              >ลงชื่อ..................................................................ผู้เดินรถร่วม</span
            >
            <br />
            <span
              >(&nbsp;&nbsp;<input v-model="datas.signtwo" style="width:220px" type="text" />&nbsp;&nbsp;)</span
            >
          </div>
          <div class="col-sm-6 mt-3">
            <span style="text-align: center"
              >ลงชื่อ.............................................................................พยาน</span
            >
            <br />
            <span
              >(&nbsp;&nbsp;<input v-model="datas.signthree" style="width:220px" type="text" />&nbsp;&nbsp;)</span
            >
          </div>
          <div class="col-sm-6 mt-3">
            <span style="text-align: center"
              >ลงชื่อ.......................................................................พยาน</span
            >
            <br />
            <span
              >(&nbsp;&nbsp;<input v-model="datas.signfour" style="width:220px" type="text" />&nbsp;&nbsp;)</span
            >
          </div>
        </div>
        <div style="text-align: center" class="mt-3">
          <label
            style="
              text-align: center;
              font-style: italic;
              font-weight: bold;
            "
            >408 หมู่ 9 ถ.ซุปเปอร์ไฮเวย์ ตำบลเมืองพราน อำเภอพาน จังหวัดเชียงราย
            57120 โทรศัพท์/โทรสาร. 0-5372-2010</label
          >
        </div>
      </div>
    <section></section>
    <div>
          <div class="form-group col-md-12">
        <label for="exampleFormControlInput1">รูปภาพคู่มือรถ</label>
        <input
          id="file"
          class="form-control form-control-sm"
          type="file"
          accept="image/*"
          @change="onFileChange"
        />
         <table class="table mt-3">
          <thead>
            <tr>
              <th scope="col">ชื่อไฟล์</th>
              <th scope="col">รูปภาพ</th>
              <th scope="col">จัดการ</th>
            </tr>
          </thead>
          <tbody v-if="docs.length > 0">
            <tr v-for="(dd, i) in docs" :key="i + 1">
              <td><a :href="dd.path" target="_blank">{{ dd.name }}</a></td>
              <td><img :src="dd.image" width="50%"></td>
              <td>
                <button
                  class="btn btn-outline-danger"
                  data-toggle="modal"
                  data-target="#deleteDocData"  
                  data-backdrop="static" data-keyboard="false"                
                  @click="getdocid(dd.id)"
                >
                  <i class="fa fa-trash" style="font-size: 12px"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <section></section>
    <div>
         <img :src="datas.image" width="100%"/>
    </div>
    </div>
    <div id="app" ref="document" v-else>
      <TransportEdit />
	</div>

    <div class="row mt-3 mb-5">
      <div class="col-md-12 text-center">
        &nbsp;
        <button
        v-if="editstatus"
          class="btn btn-success"
          style="color: white; width: 200px"
          @click="save()"
        >
          บันทึก
        </button>
        
         <button
         v-else
          class="btn btn-warning"
          style="color: white; width: 200px"
          @click="edit()"          
        >
          แก้ไข
        </button>
        &nbsp;
        <button
        v-if="car_id != 0 && !editstatus"
          class="btn btn-info"
          style="color: white; width: 200px"
          @click="exportToPDF()"
        >
          ดาวน์โหลดไฟล์
        </button>
      </div>
    </div>
         <!-- Modal Delete-->
        <div
          class="modal fade"
          id="deleteDocData"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="editDataLabel">
                  ยืนยันการลบรูปภาพ
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="deleteDoc()"
                >
                  ยืนยัน
                </button>
                <button
                  type="button"
                  id="closedeletedoc"
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
  </div>
</template>

<script src="https://api-sbpacdb.ponnipa.in.th/picker_date.js"></script>

<script>
import VueHtml2pdf from "vue-html2pdf";
import ContentToPrint from "./ContentToPrint";

import CarService from "../services/CarService";
// import CarService from "../services/CarService.js";
// import ConvertService from "../services/ConvertService";
// import CarSymbolService from "../services/CarSymbolService";
import TransportEdit from "./TransportEdit.vue";
import TransportService from "../services/TransportService";
import html2pdf from 'html2pdf.js'

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  components: {
    TransportEdit,
    VueHtml2pdf,
    ContentToPrint,
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: [],
      car_id: 0,
      image: "",
      itemPreviewImage: "",
      symbol: [],
    editstatus:false,
      tran_id: 0,
      filteredList:[],
      licenses:[],
      license:'',
      docs:[],
      docid:''
    };
  },
  methods: {
    getdocid(id){
this.docid = id
    },
    deleteDoc() {
      // console.log(this.docid);
      for (let t = 0; t < this.docs.length; t++) {
        if (this.docs[t].id == this.docid) {
          this.docs.splice(t, 1);
          document.getElementById("closedeletedoc").click();
        }
      }
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.createImage(files[0]);
      // this.createBase64Image(selectedImage);
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.itemPreviewImage = evt.target.result;
        this.image = evt.target.result;
        this.docs.push({
          id: this.docs.length + 1,
          name : file.name,
          image : evt.target.result,
          width:0,
          height:0
        })
        console.log(this.docs);
      };

      reader.readAsDataURL(file);
    },
    getlicense(s){
      // console.log(s);
this.datas = s
this.datas.license = s.license
this.filteredList = []
    },
    getLicense() {
      if (this.license != '') {
        CarService.getLicenseOwner(this.license).then((res) => {
        console.log(res.data);
        if (res.data == '') {
          this.datas = {}
          alert('ไม่พบข้อมูลทะเบียนนี้ในระบบ')
        }else{
        this.datas = res.data;
        }
      });
      }
      
    },
    edit(){
      this.editstatus = true;
      window.scrollTo(0, 0);
    },
    exportToPDF () {
      this.downloadstatus = true
				html2pdf(this.$refs.document, {
					margin: [1,1.5,0,1.2],
					filename: 'หนังสือสัญญาเดินรถร่วม ทะเบียน'+this.datas.license+'.pdf',
					jsPDF: { unit: 'cm', format: 'a4', orientation: 'p' },
          pagebreak: {after: 'section'}
				})
			},
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    save() {
      if (this.datas.writeat == null) {
        alert("กรุณากรอกเขียนที่");
      } else if (this.datas.date == null) {
        alert("กรุณากรอกวันที่");
      } else if (this.datas.one_contracts == null) {
        alert("กรุณากรอกการจัดทำหนังสือสัญญาระหว่าง");
      } else if (this.datas.house_no == null) {
        alert("กรุณากรอกบ้านเลขที่");
      } else if (this.datas.subdistrict_id == null) {
        alert("กรุณากรอกตำบล");
      } else if (this.datas.district_id == null) {
        alert("กรุณากรอกอำเภอ");
      } else if (this.datas.province_id == null) {
        alert("กรุณากรอกจังหวัด");
      } else if (this.datas.by_data == null) {
        alert("กรุณากรอกผู้เดินร่วมรถ");
      } else if (this.datas.and_data == null) {
        alert("กรุณากรอกผู้เดินร่วมรถ");
      } else if (this.datas.commuter_license == null) {
        alert("กรุณากรอกเลขทะเบียนรถผู้รับเดินรถ");
      } else if (this.datas.commuter_date == null) {
        alert("กรุณากรอกวันที่ได้รับอนุญาตประกอบการขนส่งไม่ประจำทาง");
      } else if (this.datas.license == null) {
        alert("กรุณากรอกเลขทะเบียนรถผู้เดินร่วมรถ");
      } else if (this.datas.brand == null) {
        alert("กรุณากรอกยีห้อ");
      } else if (this.datas.VIN == null) {
        alert("กรุณากรอกเลขเครื่องยนต์");
      } else if (this.datas.engine_number == null) {
        alert("กรุณากรอกเลขตัวรถ");
      } else {
        var trans = {
          // car_owner_id: this.car_id,
          car_id:this.datas.car_id,
          writeat: this.datas.writeat,
          date: this.datas.date,
          one_contracts: this.datas.one_contracts,
          house_no: this.datas.house_no,
          moo: this.datas.moo,
          soi: this.datas.soi,
          road: this.datas.road,
          subdistrict_id: this.datas.subdistrict_id,
          district_id: this.datas.district_id,
          province_id: this.datas.province_id,
          by_data: this.datas.by_data,
          and_data: this.datas.and_data,
          commuter_license: this.datas.commuter_license,
          commuter_date: this.datas.commuter_date,
          commuter_month: this.datas.commuter_month,
          commuter_year: this.datas.commuter_year,
          license: this.datas.license,
          brand: this.datas.brand,
          VIN: this.datas.VIN,
          engine_number: this.datas.engine_number,
          signone: this.datas.signone,
          signtwo: this.datas.signtwo,
          signthree: this.datas.signthree,
          signfour: this.datas.signfour,
        };
        console.log(trans);
        if (this.car_id != 0) {
          TransportService.updateTransport(this.car_id, trans).then((res) => {
            console.log(res.data);
            TransportService.deleteTransportImage(this.car_id).then(()=>{

            })
            if (this.docs.length > 0){
              for (let d = 0; d < this.docs.length; d++) {
                var img ={
                  transport_contract_id:this.car_id,
                  name:this.docs[d].name,
                  image:this.docs[d].image,
                  width:this.docs[d].width,
                  height:this.docs[d].height,
                }
                TransportService.createTransportImage(img).then(()=>{

                })
              }
            }
            this.$router.push("/transport/" + this.car_id);
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 300);
            window.scrollTo(0, 0);
          });
        } else {
          TransportService.createTransport(trans).then((res) => {
            this.car_id = res.data.id
            if (this.docs.length > 0){
              for (let d = 0; d < this.docs.length; d++) {
                var img ={
                  transport_contract_id:this.car_id,
                  name:this.docs[d].name,
                  image:this.docs[d].image,
                  width:this.docs[d].width,
                  height:this.docs[d].height,
                }
                TransportService.createTransportImage(img).then(()=>{

                })
              }
            }
            this.$router.push("/transport/" + this.car_id);
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 300);
            window.scrollTo(0, 0);
          });
        }
      }
    },
    getcar() {
      TransportService.getTransport(this.car_id).then((res) => {
        console.log(res.data);
        this.datas = res.data;
      });
    },
    getdoc(){
TransportService.getTransportImage(this.car_id).then((res)=>{
  this.docs = res.data
})
    },
    searchlicense() {
      this.filteredList = []
      this.filteredList = this.licenses.filter((post) => {
        return post.license.toLowerCase().includes(this.datas.license.toLowerCase());
      });
    },
  },
  mounted() {
    this.car_id = this.$route.params.id;
    // console.log(this.car_id);
    if (this.car_id != 0) {
      this.getcar();
      this.getdoc();
      this.editstatus = false;
      // TransportService.getTransport(this.car_id).then((res) => {
      //   // console.log(res.data);
      //   if (res.data.length == 0) {
      //     this.tran_id = 0;
      //     this.getcar();
      //   } else {
      //     this.tran_id = res.data.id;
      //     this.datas = res.data;
      //      this.datas.sub_district_name = res.data.subdistrict_id
      //      this.datas.district_name = res.data.district_id
      //      this.datas.province_name = res.data.province_id
      //   }
      // });
    } else {
      this.getLicense()
      this.editstatus = true;
      this.datas.status = 1;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input {
  margin: 5px;
  font-size: 14px;
  /* font-family:Angsana New !important; */
  font-family: 'Niramit';
}
label {
   font-size: 14px;
  font-weight: bold;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
   color: black;
}
span {
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
   color: black;
}
body {
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  font-size: 14px; 
   color: black;
}
div.aa {
  text-align: justify;
  /* font-family:Angsana New; */
   color: black;
   font-family: 'Niramit';
}
p {
  text-indent: 25px;
  text-align: justify;
  margin-bottom: 0px;
  font-size: 14px;
  /* font-family:Angsana New; */
  color: black;
  font-family: 'Niramit';
}
</style>
