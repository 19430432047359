<template>
  <div class="container mt-3">
    <div class="col text-right mb-3" style="text-align:right">
      <a @click="edit()" v-if="!statusedit">
        <button
          class="btn btn-success"
          style="color: white; background-color: #09cc68"
        >
          <i class="fa fa-plus"></i> แก้ไขข้อมูล
        </button></a
      >
    </div>
    <div class="row mb-5" v-if="!statusedit">
      <div class="col-md-3">
        <label for="exampleFormControlInput1">ชื่อเจ้าของรถ</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.name }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">เบอร์โทรศัพท์</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.phone|| '-' }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">Line Token</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.access_token || '-'}}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">ที่อยู่</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.house_no }} <span v-if="datas.moo">{{ 'ม.' + datas.moo }}</span> {{ 'ต.' + datas.sub_districtname }} {{ 'อ.' + datas.districtname }} {{ 'จ.' +datas.provincename }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">เลขทะเบียนรถ</label>
      </div>
      <div class="col-md-9">
        <label class="content" v-for="(l,i) in license" :key="i"> {{ l.license }} &nbsp;</label>
      </div>
      <!-- <div class="col-md-3">
        <label for="exampleFormControlInput1">ลำดับที่</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.no }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">ลำดับรถ</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.car_no }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">เงื่อนไข</label>
      </div>
      <div class="col-md-9">
        <label v-html="datas.symbols || '-'"></label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">ประเภทรถ</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.car_type || "-" }}</label>
      </div>      
      <div class="col-md-3">
        <label for="exampleFormControlInput1">ประเภทการวิ่งรถ</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.driving_type || "-" }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">เลขทะเบียนรถ</label>
      </div>
       <div class="col-md-9">
        <label class="content">{{ datas.license || "-" }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">ยี่ห้อรถ</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.brand || "-" }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">เลขตัวรถ</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.VIN || "-" }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">เลขเครื่องยนต์</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.engine_number || "-" }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">วันที่สิ้นอายุภาษี</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ convertdatetothai(datas.tax_expiration_date) }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">วันที่อนุมัติ</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ convertdatetothai(datas.approval_date) }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">ลักษณะ/มาตรฐาน (ช่าง)</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.characteristic || "-" }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">สถานะ</label>
      </div>
      <div class="col-md-9" v-if="datas.status == 1">
        <label class="content">ใช้งานอยู่</label>
        <label v-if="datas.status != 1">ไม่ใช้งาน</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">สถานะการต่อภาษี</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.tax_status || "-" }}</label>
      </div>

      <div class="col-md-3">
        <label for="exampleFormControlInput1">รูปภาพ</label>
      </div>
      <div class="col-md-9">
        <label
          ><img v-if="itemPreviewImage" :src="itemPreviewImage" />
          <label v-else> -</label></label
        >
      </div> -->
    </div>
    <div class="row mb-5" v-if="statusedit">
      <OwnerCarEdit />
    </div>
  </div>
</template>

<script src="https://api-sbpacdb.ponnipa.in.th/picker_date.js"></script>

<script>
import OwnerCarEdit from "./OwnerCarEdit.vue";
import OwnerService from "../services/OwnerService";
import CarsOwnersService from "../services/CarsOwnersService";

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  components: {
    OwnerCarEdit,
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: [],
      car_id: 0,
      image: "",
      itemPreviewImage: "",
      symbol: [],
      statusedit: false,
      license:[]
    };
  },
  methods: {
    getCarAll() {
      CarsOwnersService.getCarAllByOwner(this.car_id).then((res) => {
        // console.log(res.data);
        this.license = res.data;
      });
    },
    edit() {
      this.statusedit = true;
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    getcar() {
      OwnerService.getOwner(this.car_id).then((res) => {
        console.log(res.data);
        this.datas = res.data;
        this.itemPreviewImage = this.datas.image;
      });
    },
  },
  mounted() {
    this.car_id = this.$route.params.id;
    if (this.car_id != 0) {
      this.getcar();
      this.getCarAll()
    } else {
      this.statusedit = true;
      this.datas.status = 1;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-weight: bold;
}
.content{
  font-weight: normal !important;;
}
</style>
