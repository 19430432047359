import http from "../http-common";

class MasterFormService {
  getMasterForms() {
    return http.get('/master_form');
  } 
  createMasterForm(data) {
    return http.post('/master_form', data);
  }
  createcolumnMasterForm(data) {
    return http.post('/master_form/createcolumn', data);
  }
  getMasterForm(id) {
    return http.get(`/master_form/` + id);
  }
  updateMasterForm(id, data) {
    return http.put(`/master_form/${id}`, data);
  }  
}

export default new MasterFormService();