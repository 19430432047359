import http from "../http-common";

class CarService {
  getCars(license,type) {
    return http.get('/cars?license='+license+"&&type="+type);
  }  
  getCarsNull(license,type) {
    return http.get('/cars/getcarnulllist?license='+license+"&&type="+type);
  }  
  getLicense() {
    return http.get('/cars/getlicense');
  } 
  getLicenseOwner(license) {
    return http.get('/cars/getlicenseandowner?license='+license);
  } 
  getCarGroupType(type) {
    return http.get('/cars/getcounttype?type='+type);
  }
  getCarGroupExpire(name,type,license) {
    return http.get('/cars/getcountexpire?name='+name+"&&type="+type+"&&license="+license);
  }
  getCarGroupExpireAll(name,type) {
    return http.get('/cars/getcountexpireall?name='+name+"&&type="+type);
  }
  getCarGroupNormalAll(name,type) {
    return http.get('/cars/getcountnormal?name='+name+"&&type="+type);
  }
  getCarGroupExpirePerMonth() {
    return http.get('/cars/getcountexpirepermonth/');
  }
  getCarGroupNotYetTaxAll(name,type) {
    return http.get('/cars/getcountnotyettax?name='+name+"&&type="+type);
  }
  getCarGroupCheckNotYetTaxAll(name,type) {
    return http.get('/cars/getcountchecknotyettax?name='+name+"&&type="+type);
  }
  createCar(data) {
    return http.post('/cars', data);
  }
  getCar(id) {
    return http.get(`/cars/` + id);
  }
  updateCar(id, data) {
    return http.put(`/cars/${id}`, data);
  }
  updateTaxStatus(id, data) {
    return http.put(`/cars/updatetaxstatus/${id}`, data);
  }
  getDrivingTypeAll(name,type) {
    return http.get('/cars/getcountdrivingtype?name='+name+"&&type="+type);
  }
  getCarNullAll(name,type) {
    return http.get('/cars/getcountcarnull?name='+name+"&&type="+type);
  }
  createImageCar(data) {
    return http.post('/car_images', data);
  }
  getImagesCar(id) {
    return http.get(`/car_images/` + id);
  }
  deleteImagesCar(id) {
    return http.delete(`/car_images/` + id);
  }
  updateCarStatus(id) {
    return http.delete(`/cars/updatestatus/` + id);
  }
  updateCarNotStatus(id) {
    return http.delete(`/cars/updatestatuscarnot/` + id);
  }
  updateExpireCar(id,data) {
    return http.put(`/cars/updateExpireCar/${id}`, data);
  }
  
}

export default new CarService();