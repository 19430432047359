<template>
  <div style="padding: 0px;">
    <div
      style="text-align: center; background-color: white; margin: 0px;"
      class="mb-3"
    >
      <label style="text-align: center; font-size: 16px"
        >หนังสือสัญญาเดินรถร่วมห้างหุ้นส่วนจำกัด จ.โชคชัยหิรัญ</label
      >
    </div>
    <div class="row">
      <div class="col-md-6"><input value="เขียนที่" readonly class="title" style="width:15%;"/><input v-model="datas.writeat" readonly  type="text" class="content" name="name" style="width:85%;"  /></div>
      <div class="col-md-6"> <input value="วันที่" readonly class="title" style="width:10%;"/><input v-model="datas.date" readonly type="text" class="content" name="name" style="width:89%;"  /></div>
      <div class="col-md-12"><input value="หนังสือสัญญาฉบับนี้ทำขึ้นระหว่าง" readonly class="title" style="width:31%;"/> <input v-model="datas.one_contracts" readonly type="text" class="content" name="name" style="width:68%;"  /></div>
<div class="col-md-3"><input value="บ้านเลขที่" readonly class="title" style="width:40%;"/> <input v-model="datas.house_no" readonly type="text" class="content" name="name" style="width:57%;"  /></div>
<div class="col-md-3"><input value="หมู่" readonly class="title" style="width:20%;height:90%;"/> <input v-model="datas.house_no" readonly type="text" class="content" name="name" style="width:73%;"  /></div>
<div class="col-md-3"><input value="ตรอก/ซอย" readonly class="title" style="width:47%;"/> <input v-model="datas.moo" readonly type="text" class="content" name="name" style="width:50%;"  /></div>
<div class="col-md-3"><input value="ถนน" readonly class="title" style="width:30%;"/> <input v-model="datas.soi" readonly type="text" class="content" name="name" style="width:65%;"  /></div>
<div class="col-md-4"><input value="ตำบล" readonly class="title" style="width:20%;"/> <input v-model="datas.subdistrict_id" readonly type="text" class="content" name="name" style="width:77%;height:80%;"  /></div>
<div class="col-md-4"><input value="อำเภอ" readonly class="title" style="width:20%;"/> <input v-model="datas.district_id" readonly type="text" class="content" name="name" style="width:77%;height:80%;"  /></div>
<div class="col-md-4"><input value="จังหวัด" readonly class="title" style="width:23%;"/> <input v-model="datas.province_id" readonly type="text" class="content" name="name" style="width:73%;height:80%;"  /></div>
<div class="col-md-12"> ต่อไปนี้ในสัญญานี้เรียกว่า "ผู้เดินรถร่วม" ฝ่ายหนึ่งกับ ห้างหุ้นห่วนจำกัด
      จ.โชคชัยหิรัญ</div>
      <div class="col-md-4" style="padding-right:0px"><input value="โดย" readonly class="title" style="width:13%;"/> <input v-model="datas.by_data" readonly type="text" class="content" name="name" style="width:78%;"  /></div>
<div class="col-md-3" style="padding:0px"><input value="และ" readonly class="title" style="width:15%;"/> <input v-model="datas.and_data" readonly type="text" class="content" name="name" style="width:78%;"  /></div>
<div class="col-md-5" style="padding:0px"><input value='ซึ่งต่อไปนี้ในสัญญา เรียกว่า "ผู้รับเดินรถร่วม"' readonly class="title" style="width:100%;height:90%"/> </div>

    </div>    
    <span> ทั้งสองฝ่ายตกลงทำสัญญากันไว้มีข้อความดังต่อไปนี้<br /> </span>    
      <p>
        1.<span style="margin-left:10px">สัญญาฉบับนี้ทำขึ้นเนื่องจากผู้เดินรถร่วมประสงค์ประกอบอาชีพเดินรถรับจ้างบรรทุกสัตว์และหรือสิ่งของทั่วราชอาณาจักรโดยขอเข้าร่วมใช้ใบอนุญาตประกอบการขนส่งกับทางห้างหุ้นส่วนจำกัด จ.โชคชัยหิรัญ
      </span></p>
      <p>
        2.<span style="margin-left:7px">ผู้รับเดินรถร่วมเป็นผู้มีสิทธิประกอบการขนส่งไม่ประจำทางด้วยรถบรรทุกโดยได้รับอนุญาตจากนายทะเบียนตามกฎหมายว่าด้วยการขนส่งทางบก เลขที่.....{{datas.commuter_license}}..... ออกวันที่......{{datas.commuter_date}}..... เดือน......{{datas.commuter_month}}....... พ.ศ......{{datas.commuter_year}}......</span>
      </p>
      <p>
        3.<span style="margin-left:7px">ผู้เดินรถร่วมเป็นเจ้าของกรรมสิทธิ์หรือผู้เช่าซื้อรถหมายเลขทะเบียน ......{{datas.license}}.....&nbsp;เชียงราย ยี่ห้อ.....{{datas.brand}}...... เลขเครื่องยนต์.....{{datas.VIN}}..... เลขตัวรถ......{{datas.engine_number}}......
      </span></p>
      <p>
        4.<span style="margin-left:7px">ผู้เดินรถร่วมยินยอมที่จะปฏิบัติตามเงื่อนไขและระเบียบของทางราชการและของทางห้างหุ้นส่วนฯทุกประการ หากผู้เดินรถร่วมไม่ปฏิบัติตามเงื่อนไขข้อใดข้อหนึ่งหนังสือสัญญาเดินรถร่วมฉบับนี้ถือว่าไม่สมบูรณ์และให้ถือเป็นโมฆะทันทีโดยผู้เดินรถร่วมตกลงยอมรับสัญญา/ข้อตกลงต่างๆ ด้วยความสมัครใจโดยไม่ได้มีการบังคับ ขู่เข็ญ แต่อย่างใดทั้งสิ้น</span>
      </p>
      <p>
        5.<span style="margin-left:7px">ค่าใช้จ่ายต่าง ๆ ในการใช้รถ เช่น ค่าน้ำมันเชื้อเพลิง น้ำหล่อลื่น
        ค่าบำรุงรักษา ค่าธรรมเนียมและค่าภาษีอากรต่าง ๆ
        ของผู้เดินรถร่วมเป็นผู้ชำระเองทั้งสิ้นการเสียภาษีรถต้องแนบสัญญาเดินรถร่วมของทางห้างฯ
      </span></p>
      <p>
        6.<span style="margin-left:7px">กรณีผู้รับเดินรถร่วมจำต้องชดใช้ค่าเสียหายเบื้องต้นให้แก่ผู้เสียหายอันเนื่องมาจากการใช้รถของผู้เดินรถร่วมตามที่บัญญัติไว้ในกฎหมายว่าด้วยกรมการขนส่งทางบกไปด้วยประการใด
        ๆ ก็ตาม ผู้เดินรถร่วมตกลงยินยอมรับสภาพหนี้กับทางห้างฯ และจะชำระและชดใช้ค่าใช้จ่ายต่างๆรวมทั้งดอกเบี้ยตามกฎหมายแก่ผู้รับเดินรถร่วมภายใน 7 วัน นับตั้งแต่วันที่ผู้รับเดินรถร่วมได้ชดใช้ไปหากผู้เดินรถร่วมไม่ปฏิบัติตามสัญญายินยอมถูกปรับเป็นเงิน 10
        เท่าของยอดเงินที่ได้ชำระไปในทันที
      </span></p>
      <p>
        7.<span style="margin-left:7px">ผู้เดินรถร่วม/ผู้ประจำรถมิใช่เป็นตัวแทนหรือลูกจ้างของผู้รับเดินรถร่วมแต่อย่างใดหากนำรถคันดังกล่าวไปใช้ในกระทำความผิดตามกฎหมายไม่ว่ากรณีใดๆก็ตามหากมีผู้ใดกล่าวหาว่าบุคคลดังกล่าวและเรียกร้องค่าเสียหายใด
        ๆ ทั้งทางแพ่งและทางอาญาในนามของผู้เดินรถร่วมกับผู้รับเดินรถร่วม
        ผู้เดินรถร่วม/ผู้ประจำรถต้องรับผิดชอบแต่เพียงฝ่ายเดียวในทุกกรณีและผู้รับเดินรถร่วมมิได้มีค่าตอบแทนและผลประโยชน์ร่วมใด
        ๆ ในการกระทำและการประกอบอาชีพใด ๆ ของผู้เดินรถร่วม/ผู้ประจำรถ อนึ่ง
        ผู้เดินรถร่วมจะต้องจัดทำประกันภัยรถยนต์ตามที่กฎหมายบังคับรวมทั้งต้องจัดทำประกันภัยรถยนต์ประเภทหนึ่ง
        สอง หรือสาม ในระหว่างที่เดินรถร่วมกับทางห้างฯ
      </span></p>
      <p>
        8.<span style="margin-left:7px">สัญญานี้ผู้รับเดินรถร่วมมีสิทธิ์ที่จะบอกเลิกผู้เดินรถร่วมได้ทันทีโดยมิต้องบอกกล่าว
        โดยไม่ต้องทำหนังสือแจ้งการบอกเลิกสัญญาแก่ผู้เดินรถร่วม
        ในกรณีที่ผู้เดินรถร่วมกระทำการด้วยเจตนาในทางไม่ปฏิบัติตามเงื่อนไข
        และระเบียบหรือกระทำการอื่นใดจนเป็นเหตุให้น่าจะเกิดความเสียหายแก่ผู้รับเดินรถร่วม</span><br />
        <span style="margin-left:45px">สัญญาฉบับนี้ทำขึ้นเป็นสองฉบับมีข้อความตรงกันทั้งสองฝ่ายได้อ่านเข้าใจดีแล้วจึงลงลายมือชื่อเป็นสำคัญต่อหน้า
        พยานและคู่สัญญาได้ยึดถือสัญญาฉบับนี้ไว้ฝ่ายละฉบับ
      </span></p>
    
    <div class="row mt-3" style="text-align: center; font-size: 14px">
      <div class="col-sm-6">
        <span style="text-align: center"
          >ลงชื่อ...............................................................ผู้รับเดินรถร่วม</span
        >
        <br />
        <span v-if="datas.signone"
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{datas.signone}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
        <span v-else
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
      </div>
      <div class="col-sm-6">
        <span style="text-align: center"
          >ลงชื่อ..................................................................ผู้เดินรถร่วม</span
        >
        <br />
        <span v-if="datas.signtwo"
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{datas.signtwo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
        <span v-else
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
      </div>
      <div class="col-sm-6 mt-3">
        <span style="text-align: center"
          >ลงชื่อ.............................................................................พยาน</span
        >
        <br />
        <span v-if="datas.signthree"
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{datas.signthree}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
        <span v-else
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
      </div>
      <div class="col-sm-6 mt-3">
        <span style="text-align: center"
          >ลงชื่อ.......................................................................พยาน</span
        >
        <br />
       <span v-if="datas.signfour"
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{datas.signfour}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
        <span v-else
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
      </div>
    </div>
    <div style="text-align: center" class="mt-5">
      <label
        style="
          text-align: center;
          font-style: italic;
          font-weight: bold;
        "
        >408 หมู่ 9 ถ.ซุปเปอร์ไฮเวย์ ตำบลเมืองพราน อำเภอพาน จังหวัดเชียงราย
        57120 โทรศัพท์/โทรสาร. 0-5372-2010</label
      >
    </div>
    <section></section>
    <div class="mt-5">
      <div style="font-size:16px;" class="mb-3">รูปภาพคู่มือรถ</div>
          <div class="row">
            <div class="col-md-6" v-for="(d,i) in docs" :key="i">
              <img :src="d.image" width="100%"/>
            </div>
          </div>
    </div>
    <section></section>
    <div class="mt-5">
      <div style="font-size:16px;" class="mb-3">รูปทะเบียนคู่มือรถ</div>
         <img :src="datas.image" width="100%"/>
    </div>
  </div>
</template>

<script src="https://api-sbpacdb.ponnipa.in.th/picker_date.js"></script>

<script>
import TransportService from "../services/TransportService";
import CarService from "../services/CarService.js";
import ConvertService from "../services/ConvertService";
import CarSymbolService from "../services/CarSymbolService";
import TransportEdit from "./TransportEdit.vue";
import CarsOwnersService from "../services/CarsOwnersService";

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  components: {
    TransportEdit,
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: [],
      car_id: 0,
      image: "",
      itemPreviewImage: "",
      symbol: [],
      statusedit: false,
      docs:[]
    };
  },
  methods: {
    save() {
      if (this.datas.car_type == null) {
        alert("กรุณาเลือกประเภทรถ");
      } else if (this.datas.license == null) {
        alert("กรุณากรอกเลขทะเบียนรถ");
      } else if (this.datas.brand == null) {
        alert("กรุณาเลือกยี่ห้อรถ");
      } else if (this.datas.VIN == null) {
        alert("กรุณากรอกเลขตัวรถ");
      } else if (this.datas.engine_number == null) {
        alert("กรุณากรอกเลขเครื่องยนต์");
      } else if (document.getElementById("tax_expiration_date").value == "") {
        alert("กรุณากรอกวันที่สิ้นอายุภาษี");
      } else if (document.getElementById("approval_date").value == "") {
        alert("กรุณากรอกวันอนุมัติ");
      } else if (this.datas.characteristic == null) {
        alert("กรุณาเลือกลักษณะ/มาตรฐาน (ช่าง)");
      } else {
        var car = {
          no: this.datas.no,
          car_no: this.datas.car_no,
          car_type: this.datas.car_type_id,
          license: this.datas.license,
          brand: this.datas.brand_id,
          VIN: this.datas.VIN,
          engine_number: this.datas.engine_number,
          tax_expiration_date: ConvertService.createddate(
            document.getElementById("tax_expiration_date").value
          ),
          approval_date: ConvertService.createddate(
            document.getElementById("approval_date").value
          ),
          characteristic: this.datas.characteristic_id,
          status: this.datas.status,
          image: this.image,
        };
        if (this.car_id != 0) {
          CarService.updateCar(this.car_id, car).then((res) => {
            console.log(res.data);
            CarSymbolService.deleteCarSymbol(this.car_id).then(() => {});
            if (this.symbol.length > 0) {
              for (let s = 0; s < this.symbol.length; s++) {
                var sym = {
                  car_id: this.car_id,
                  symbol_id: this.symbol[s],
                };
                CarSymbolService.createCarSymbol(sym).then(() => {});
              }
            }
            alert("บันทึกสำเร็จ");
          });
        } else {
          CarService.createCar(car).then((res) => {
            console.log(res.data);
            this.car_id = res.data.id;
            for (let s = 0; s < this.symbol.length; s++) {
              var sym = {
                car_id: this.car_id,
                symbol_id: this.symbol[s],
              };
              CarSymbolService.createCarSymbol(sym).then(() => {});
            }
            this.$router.push("/cars/" + this.car_id);
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 1000);
            window.scrollTo(0, 0);
          });
        }
      }
    },
    getcar() {
      CarsOwnersService.getCarOwner(this.car_id).then((res) => {
        // console.log(res.data);
        this.datas = res.data;
      });
    },
    getdoc(){
TransportService.getTransportImage(this.car_id).then((res)=>{
  this.docs = res.data
  console.log(this.docs);
})
    },
  },
  mounted() {
    this.car_id = this.$route.params.id;
    // console.log(this.car_id);
    if (this.car_id != 0) {
      TransportService.getTransport(this.car_id).then((res) => {
        // console.log(res.data);
        if (res.data.length == 0) {
          this.tran_id = 0;
          this.getcar();
        } else {
          this.tran_id = res.data.id;
          this.datas = res.data;          
          this.datas.sub_district_name = res.data.subdistrict_id;
          this.datas.district_name = res.data.district_id;
          this.datas.province_name = res.data.province_id;
          CarService.getCar(this.datas.car_id).then((res)=>{
            this.datas.image = res.data.image
          })          
          this.getdoc()
        }
      });
    } else {
      this.statusedit = true;
      this.datas.status = 1;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
   font-size: 14px;
  font-weight: bold;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
   color: black;
}
span {
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
   color: black;
   text-align: justify;
}
body {
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  font-size: 14px;
   color: black;
}
div.aa {
  text-align: justify;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
   color: black;
}
p {
  text-indent: 25px;
  text-align: justify;
  margin-bottom: 0px;
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}
div{
  font-family: 'Niramit';
  font-size: 14px;
}
.title{
  border-left:none; border-right:none; border-top:none;border-bottom: none;
}
.content{
border-left:none; border-right:none; border-top:none;border-bottom-style:dotted;border-bottom: 1.6px dotted;
}
input{
  margin-bottom: 5px;
}
</style>
