import http from "../http-common";

class TaxInvoiceService {
  getTaxInvoices(status,license,type) {
    return http.get('/tax_invoice?status='+status+'&&license='+license+'&&type='+type);
  } 
  getLastTaxInvoices(year) {
    return http.get('/tax_invoice/lasttax?year='+year);
  } 
  createTaxInvoice(data) {
    return http.post('/tax_invoice', data);
  }
  getTaxInvoice(id) {
    return http.get(`/tax_invoice/` + id);
  }
  updateTaxInvoice(id, data) {
    return http.put(`/tax_invoice/${id}`, data);
  } 
  getListTaxs() {
    return http.get('/listtax');
  } 
  createListTax(data) {
    return http.post('/listtax', data);
  }
  getListTax(id) {
    return http.get(`/listtax/` + id);
  }
  updateListTax(id, data) {
    return http.put(`/listtax/${id}`, data);
  } 
  deleteListTax(id) {
    return http.delete(`/listtax/` + id);
  }
  getTaxs() {
    return http.get('/taxs');
  } 
  createTax(data) {
    return http.post('/taxs', data);
  }
  getTax(id) {
    return http.get(`/taxs/` + id);
  }
  getSubTaxs() {
    return http.get('/sub_taxs');
  } 
  createSubTax(data) {
    return http.post('/sub_taxs', data);
  }
  getSubTax(id) {
    return http.get(`/sub_taxs/` + id);
  }
}

export default new TaxInvoiceService();