<template>
  <div> 
    <div class="col text-right mb-3" style="text-align:right" v-if="tax_id == 0">
        <a href="/taxList">
          <button
            class="btn btn-success"            
          >
            <i class="fa fa-file"></i> รายการใบเสียภาษีทั้งหมด
          </button></a
        >
      </div>
    <div class="container mt-3 mb-3" v-if="tax_id == 0">      
       <div class="card-body">
        <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4"><div class="form-inline">      
    <!-- <div class="form-group mb-2">
    <label for="staticEmail2" class="sr-only">ค้นหา</label>
    <input type="text" readonly class="form-control-plaintext" id="staticEmail2" value="ค้นหา">
  </div> -->
  <div class="form-group mb-2">
    <label for="inputPassword2" class="sr-only"
            >เลขทะเบียนรถ</label
          >
    <input
            v-model="license"
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="ค้นหาเลขทะเบียนรถ"
          />
  </div>&nbsp;&nbsp;
  <button
            class="btn btn-primary mb-2"
            style="color: white; width: 80px"
            @click="search()"
          >
            ค้นหา
          </button>
</div></div>
        <div class="col-md-4"></div>
      </div>
        </div>
    </div>
    <div style="margin: 5px; padding: 5px">
      <div class="form-group col-md-4">
        <label for="exampleFormControlSelect1">ปีที่เสียภาษี</label>
        <select
          :disabled="tax_id != 0"
          class="form-control form-control-sm"
          id="exampleFormControlSelect1"
          v-model="year"
        >
          <option v-for="(s, i) in yearlist" v-bind:value="s.year" :key="i + 1">
            {{ s.year }}
          </option>
        </select>
      </div>
      <div class="col text-right mb-3" style="text-align:right" v-if="tax_id != 0">
        <a :href="'/printtaxation/'+tax_id">
          <button
            class="btn btn-success"            
          >
            <i class="fa fa-print"></i> ปริ้นท์ใบเสียภาษีทั้งหมด
          </button></a
        >
      </div>
      <table class="table table-bordered" v-if="pageOfItems.length > 0">
        <thead>
          <tr class="table-active">
            <th scope="col" v-if="tax_id == 0">
              <input
                @click="checktrue(statusinput)"
                type="checkbox"
                id="statusinput"
                :value="statusinput"
              />
            </th>
           <th scope="col">เลขที่ใบเสร็จ</th>
           <th scope="col">ชื่อ-นามสกุล</th>
            <th scope="col">ทะเบียนรถ</th>
           <th scope="col">ประเภทรถ</th>
           <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in pageOfItems" :key="i">
            <th scope="row" v-if="tax_id == 0">
              <input
                type="checkbox"
                v-model="d.statuscheck"
                :value="d.statuscheck"
                @click="uncheck()"
              />
            </th>
            <td>
              <a>{{ d.no }}</a>
            </td> 
             <td>
              <a>{{ d.name }}</a>
            </td>  
            <td>
              <a>{{ d.license }}</a>
            </td>  
            <td>
              <a>{{ d.car_type }}</a>
            </td>      
            <td :class="d.class">
              <a :href="'/taxationview/' + d.id">
                <button type="button" class="btn btn-info">
                  <i class="fa fa-print"></i></button
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else style="text-align: center">ไม่พบข้อมูล</div>
    </div>
    <div class="col text-right mb-3" style="text-align: right">
      <jw-pagination
        :items="list"
        @changePage="onChangePage"
        :labels="customLabels"
      ></jw-pagination>
    </div>
    <div class="row mt-3 mb-3" >
      <div class="col-md-12 text-center">
        <button
        @click="$router.go(-1)"
          class="btn btn-warning"
          style="width: 200px"
        >
          ย้อนกลับ
        </button>
        &nbsp;
        <button
        v-if="tax_id == 0 && pageOfItems.length > 0"
          class="btn btn-success"
          style="color: white; width: 200px"
          @click="save()"
        >
          บันทึก
        </button>
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import CarService from "../services/CarService.js";
import CarTypesService from "../services/CarTypesService";
import ReceiptService from "../services/ReceiptService";
import TaxInvoiceService from "../services/TaxInvoiceService";

export default {
  name: "Carlist",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      car_types: [],
      license: "",
      owner: "",
      licenses: [],
      customLabels,
      pageOfItems: [],
      statusinput: false,
      listcheck: [],
      year: "",
      yearlist: [],
      tax_id: 0,
      car_type:0
    };
  },
  methods: {
    save() {
      let year = String(this.year).substring(2, 4);
      // console.log(year);
      var data_filter = this.list.filter( element => element.statuscheck == true)
console.log(data_filter);
if (data_filter.length != 0) {
      if (this.tax_id == 0) {
        var t = {
          name: "",
          year: this.year,
        };
        TaxInvoiceService.createTax(t).then((res) => {
          this.tax_id = res.data.id;
          var count = 1;
          for (let r = 0; r < this.list.length; r++) {
            if (this.list[r].statuscheck == true) {
              let no = "";
              var car_no = String(count);
              if (car_no.length == 1) {
                no = year + "000" + car_no;
              } else if (car_no.length == 2) {
                no = year + "00" + car_no;
              } else if (car_no.length == 3) {
                no = year + "0" + car_no;
              } else if (car_no.length == 4) {
                no = year + car_no;
              }
              count = count + 1;
              // console.log(no);
              var d = {
                tax_id: this.tax_id,
                tax_invoice_id: this.list[r].id,
                no: no,
                year: this.year,
              };
                console.log(d);
              TaxInvoiceService.createSubTax(d).then((res) => {
                console.log(res.data);
              });
            }
          }
          this.$router.push("/taxation/" + this.tax_id);
          alert("บันทึกสำเร็จ");
          setTimeout(function () {
            location.reload();
          }, 1000);
          window.scrollTo(0, 0);
        });
      }
}else{
  alert('กรุณาเลือกใบเสร็จสำหรับการเสียภาษี')
}
    },
    checktrue(status) {
      // console.log(this.pageOfItems);
      this.list = [];
      // console.log(this.list);
      if (status == false) {
        for (let li = 0; li < this.pageOfItems.length; li++) {
          this.pageOfItems[li].statuscheck = true;
        }
        this.statusinput = true;
      } else {
        for (let li = 0; li < this.pageOfItems.length; li++) {
          this.pageOfItems[li].statuscheck = false;
        }
        this.statusinput = false;
      }
      this.list = this.listcheck;
      // console.log(this.list);
      // console.log(this.statusinput);
    },
    uncheck(){
var element = document.getElementById("statusinput");
if (element) {
          document.getElementById("statusinput").checked = false;
        }
    },
    onChangePage(pageOfItems) {
      this.statusinput = false;
      this.pageOfItems = pageOfItems;
      // console.log(this.pageOfItems);
      var c = 0;
      // listcheck
      for (let p = 0; p < this.pageOfItems.length; p++) {
        if (this.pageOfItems[p].statuscheck == true) {
          c = c + 1;
        }
      }
      var element = document.getElementById("statusinput");

      if (c == this.pageOfItems.length) {
        this.statusinput = true;
        if (element) {
          document.getElementById("statusinput").checked = true;
        }
      } else {
        this.statusinput = false;
        if (element) {
          document.getElementById("statusinput").checked = false;
        }
      }      
      // console.log(element);
    },
    getlicense(n) {
      this.license = n;
    },
    getLicense() {
      CarService.getLicense().then((res) => {
        // console.log(res.data);
        this.licenses = res.data;
      });
    },
    getList() {
      // console.log(this.year);
      TaxInvoiceService.getTaxInvoices(1,'','').then((res) => {
        // console.log(res.data);
        this.list = res.data;
        for (let l = 0; l < this.list.length; l++) {
          this.list[l].statuscheck = false;
        }
        this.statusinput = false;
        this.listcheck = this.list;
      });
    },
    getTax() {
      TaxInvoiceService.getSubTax(this.tax_id).then((res) => {
        this.list = res.data;
        console.log(res.data);
      });
    },
    createreceipt() {
      CarService.getCars("", "").then((res) => {
        console.log(res.data);
        var now = new Date();
        let year = now.getFullYear() + 543;
        year = String(year).substring(2, 4);
        // console.log(year);
        for (let r = 0; r < res.data.length; r++) {
          console.log(String(res.data[r].car_no).length);
          let no = "";
          var car_no = String(r);
          if (car_no.length == 1) {
            no = year + "000" + car_no;
          } else if (car_no.length == 2) {
            no = year + "00" + car_no;
          } else if (car_no.length == 3) {
            no = year + "0" + car_no;
          } else if (car_no.length == 4) {
            no = year + car_no;
          }
          console.log(no);
          var d = {
            car_id: res.data[r].car_id,
            no: no,
            year: this.year,
          };
          ReceiptService.createReceipt(d).then(() => {});
        }
      });
    },
    getcartypes() {
      CarTypesService.getCarTypesReceipt("").then((res) => {
        // console.log(res.data);
        this.car_types.push({
          car_type_id: 0,
          name: "ทั้งหมด",
        });
        for (let c = 0; c < res.data.length; c++) {
          this.car_types.push({
            car_type_id: res.data[c].car_type_id,
            name: res.data[c].code + " " + res.data[c].name,
          });
        }
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    search() { 
      // console.log(this.listcheck);
      // console.log(this.list);
      if (this.license || this.car_type) {
        console.log(this.license.toLowerCase());
var data_filter = this.list.filter( element => element.license.toLowerCase().includes(this.license.toLowerCase()))
console.log(data_filter);
if (data_filter.length != 0) {
this.list = data_filter  
}else{
  this.list = []
}
      }else{
        this.list = this.listcheck
      }
      

  // this.list = this.listcheck
      // for (let l = 0; l < this.list.length; l++) {
      //   if (this.list[l].license ) {
      //     const element = array[l];
      //   }
        
      // }
      // var type = ''
      // if (this.car_type == 0) {
      //   type = ''
      // }else{
      //   type = this.car_type
      // }
      // TaxInvoiceService.getTaxInvoices(1,this.license,type).then((res) => {
      //   // console.log(res.data);
      //   this.list = res.data;
      //   for (let l = 0; l < this.list.length; l++) {
      //     this.list[l].statuscheck = false;
      //   }
      //   this.statusinput = false;
      //   this.listcheck = this.list;
      // });
    },
    getlistbyyear() {
      this.getList();
    },
  },
  mounted() {
    this.year = new Date();
    this.year = this.year.getFullYear() + 544;
    this.yearlist.push({
      year: this.year,
    });
    this.yearlist.push({
      year: this.year + 1,
    });
    this.tax_id = this.$route.params.id;
    // console.log(this.tax_id);
    if (this.tax_id == 0) {
      this.getList();
      this.getcartypes();
    } else {
      this.getTax();
      TaxInvoiceService.getTax(this.tax_id).then((res) => {
        this.year = res.data.year;
        console.log(this.year);
      });

      // var year = String(this.year).substring(2, 4);
    }
    this.car_type = 0;
  },
  computed: {
    filteredList() {
      return this.licenses.filter((post) => {
        return post.license.toLowerCase().includes(this.license.toLowerCase());
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
  text-decoration: none;
}
#app {
  font-family: "Niramit";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
