<template>
  <div class="container mt-3">
    <div class="row mb-5">
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">ชื่อเจ้าของรถ</label>
        <input
                  v-model="owner.name"
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="ชื่อเจ้าของรถ"
                />
      </div>
      <div class="col-md-6"></div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">บ้านเลขที่</label>
        <input
                  v-model="owner.house_no"
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="บ้านเลขที่"
                />
      </div>
       <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">หมู่ที่</label>
        <input
                  v-model="owner.moo"
                  type="number"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="หมู่ที่"
                />
      </div>
      <div class="form-group col-md-4">
        <label for="exampleFormControlInput1">จังหวัด</label>
        <select
          v-model="owner.province"
          @change="onChangeDistricts"
          class="form-control"
          id="exampleFormControlSelect1"
        >
          <option
            v-for="(s, i) in provinces"
            v-bind:value="s.id"
            :key="i + 1"
          >
             {{ s.name_in_thai }}
          </option>
        </select>
      </div>
      
      <div class="form-group col-md-4">
        <label for="exampleFormControlInput1">อำเภอ</label>
        <select
          v-model="owner.district"
          @change="onChangeSubdistricts"
          class="form-control"
          id="exampleFormControlSelect1"
        >
          <option
            v-for="(s, i) in districts"
            v-bind:value="s.id"
            :key="i + 1"
          >
            {{ s.name_in_thai }}
          </option>
        </select>
      </div>
      
      <div class="form-group col-md-4">
        <label for="exampleFormControlInput1">ตำบล</label>
        <select
          v-model="owner.sub_district"
          @change="onChangeZipcode"
          class="form-control"
          id="exampleFormControlSelect1"
        >
          <option
            v-for="(s, i) in subdistricts"
            v-bind:value="s.id"
            :key="i + 1"
          >
            {{ s.name_in_thai }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-3 mb-5">
      <div class="col-md-12 text-center">
        <!-- <button
            class="btn btn-warning"
            style="color: white; width: 200px"
            @click="back()"
          >
            ย้อนกลับ
          </button> -->
        &nbsp;
        <button
          class="btn btn-success"
          style="color: white; width: 200px"
          @click="save()"
        >
          บันทึก
        </button>
        &nbsp;
        <!-- <button
              class="btn btn-danger"
              style="color: white; width: 200px"
              @click="next()"
            >
              ลบ
            </button> -->
      </div>
    </div>
    <div
      class="modal fade"
      id="adddata"
      tabindex="-1"
      role="dialog"
      aria-labelledby="adddata"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">เพิ่มข้อมูลเจ้าของรถ</h5>
            <button
              type="button"
              id="close"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="exampleFormControlInput1">ชื่อเจ้าของรถ</label>
                <input
                  v-model="owner.name"
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="ชื่อเจ้าของรถ"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="exampleFormControlInput1">บ้านเลขที่</label>
                <input
                  v-model="owner.house_no"
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="บ้านเลขที่"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="exampleFormControlInput1">หมู่ที่</label>
                <input
                  v-model="owner.moo"
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="หมู่ที่"
                />
              </div>
              <div class="form-group col-md-4"></div>
              <div class="form-group col-md-4">
                <label for="exampleFormControlInput1">จังหวัด</label>
                <select
          v-model="owner.province"
          @change="onChangeDistricts"
          class="form-control"
          id="exampleFormControlSelect1"
        >
          <option
            v-for="(s, i) in provinces"
            v-bind:value="s.id"
            :key="i + 1"
          >
             {{ s.name_in_thai }}
          </option>
        </select>
              </div>
              <div class="form-group col-md-4">
                <label for="exampleFormControlInput1">อำเภอ</label>
                <select
          v-model="owner.district"
          @change="onChangeSubdistricts"
          class="form-control"
          id="exampleFormControlSelect1"
        >
          <option
            v-for="(s, i) in districts"
            v-bind:value="s.id"
            :key="i + 1"
          >
            {{ s.name_in_thai }}
          </option>
        </select>
              </div>
              <div class="form-group col-md-4">
                <label for="exampleFormControlInput1">ตำบล</label>
                <select
          v-model="owner.sub_district"
          class="form-control"
          id="exampleFormControlSelect1"
        >
          <option
            v-for="(s, i) in subdistricts"
            v-bind:value="s.id"
            :key="i + 1"
          >
            {{ s.name_in_thai }}
          </option>
        </select>
              </div>
            </div>
          </div>
          <div class="row mt-3 mb-5">
            <div class="col-md-12 text-center">
              &nbsp;
              <button
                class="btn btn-success"
                style="color: white; width: 200px"
                @click="saveowner()"
              >
                บันทึก
              </button>
              &nbsp;
              <!-- <button
              class="btn btn-danger"
              style="color: white; width: 200px"
              @click="next()"
            >
              ลบ
            </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import OwnerService from "../services/OwnerService";
import AddressService from "../services/AddressService";

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: [],
      owner_id: 0,
      owners: [],
      license: [],
      owner: [],
      provinces:[],
      districts:[],
      subdistricts:[]
    };
  },
  methods: {
    onChangeDistricts(event){
var province_id = event.target.value;
this.getDistricts(province_id)
    },
onChangeSubdistricts(event){
var district_id = event.target.value;
this.getSubdistricts(district_id)
    },
onChangeZipcode(event){
var subdistrict_id = event.target.value;
this.getSubDistrict(subdistrict_id)
    },
    getProvinces() {
      AddressService.getProvinces().then((res) => {
        // console.log(res.data);
        this.provinces = res.data;
      });
    },
    getDistricts(id) {
      AddressService.getDistricts(id).then((res) => {
        console.log(res.data);
        this.districts = res.data;
        this.owner.sub_district = ''
        this.subdistricts = []
      });
    },
    getSubdistricts(id) {
      AddressService.getSubDistricts(id).then((res) => {
        // console.log(res.data);
        this.subdistricts = res.data;
      });
    },
    getSubDistrict(id) {
      AddressService.getSubDistrict(id).then((res) => {
        // console.log(res.data);
        this.owner.zipcode = res.data.zipcode;
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    save() {
      if (this.owner.name == null) {
        alert("กรุณากรอกชื่อเจ้าของรถ");
      } else if (this.owner.house_no == null) {
        alert("กรุณากรอกบ้านเลขที่");
      } else if (this.owner.moo == null) {
        alert("กรุณากรอกหมู่ที่");
      }else if (this.owner.sub_district == null) {
        alert("กรุณาเลือกตำบล");
      } else if (this.owner.district == null) {
        alert("กรุณาเลือกอำเภอ");
      } else if (this.owner.province == null) {
        alert("กรุณาเลือกจังหวัด");
      } else {
        var car = {
          name: this.owner.name,
          house_no: this.owner.house_no,
          moo: this.owner.moo,
          sub_district: this.owner.sub_district,
          district: this.owner.district,
          province: this.owner.province,
        };
        if (this.owner_id != 0) {
          OwnerService.updateOwner(this.owner_id, car).then(
            (res) => {
              console.log(res.data);
              this.$router.push("/owner/" + this.owner_id);
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 1000);
            window.scrollTo(0, 0);
            }
          );
        }else{
        OwnerService.createOwner(car).then((res) => {
          console.log(res.data);
          this.owner_id = res.data.id;
            this.$router.push("/owner/" + this.owner_id);
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 1000);
            window.scrollTo(0, 0);
        });
      }
      }
    },
    getcar() {
      OwnerService.getOwner(this.owner_id).then((res) => {
        console.log(res.data);
        this.owner = res.data;  
        console.log(this.owner);
        this.getProvinces()    
    this.getDistricts(this.owner.province)
    this.getSubdistricts(this.owner.district) 
      });
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.createImage(files[0]);
      // this.createBase64Image(selectedImage);
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.itemPreviewImage = evt.target.result;
        this.image = evt.target.result;
      };

      reader.readAsDataURL(file);
    },
  },
  mounted() {
    this.owner_id = this.$route.params.id;
    console.log(this.owner_id);
    if (this.owner_id != 0) {
      this.getcar();      
    }else{
    this.getProvinces()
    this.getDistricts('')
    this.getSubdistricts('')
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-weight: bold;
}
</style>
