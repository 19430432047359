<template>
  <div>
    <div class="card shadow mb-4">
      <!-- Card Header - Dropdown -->
      <!-- <div
          class="
            card-header
            py-3
            d-flex
            flex-row
            align-items-center
            justify-content-between
          "
        >
          <h6 class="m-0 font-weight-bold text-primary">ประเภทรถที่หมดภาษีใน 3 เดือน จำนวน {{all.toLocaleString()}} คัน</h6>
        </div> -->
      <!-- Card Body -->
      <div class="card-body">
        <!-- <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  /> -->
        <apexchart
          width="500"
          type="bar"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
// import { Bar } from "vue-chartjs/legacy";
import CarService from "../services/CarService.js";
import CarTypesService from "../services/CarTypesService";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: {
    // Bar
  },
  props: {
    chartId: {
      type: String,
      default: "Bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 513.5,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // options: {
      //     spanGaps: true,
      //     elements: {
      //       line: {
      //         fill: false,
      //         borderWidth: 4
      //       },
      //       point: {
      //         radius: 8,
      //         hoverRadius: 8
      //       }
      //     },
      //     legend: {
      //       display: true,
      //       position: 'bottom',
      //       labels: {
      //         fontSize: 20,
      //         padding: 40,
      //         fontStyle: 'semibold'
      //       }
      //     },
      //     tooltips: {
      //       titleFontSize: 20,
      //       titleSpacing: 40,
      //       titleFontStyle: 'semibold',
      //     },
      //     scales: {
      //       yAxes: [{
      //         gridLines: {
      //           tickMarkLength: 0
      //         },
      //         scaleLabel: {
      //           display: false,
      //           labelString: 'test'
      //         },
      //         ticks: {
      //           padding: 20,
      //           fontSize: 16,
      //           fontStyle: 'bold',
      //           max: 1,
      //           min: -1,
      //           maxTicksLimit: 5,
      //           callback: function(value, index) {
      //             if (index === 0) {
      //               return '⊕';
      //             } else if (index === 4) {
      //               return '⊖';
      //             } else if (index === 1 || 2 || 3) {
      //               return '';
      //             }
      //           }
      //         }
      //       }],
      //       xAxes: [{
      //         gridLines: {
      //           tickMarkLength: 10
      //         },
      //         ticks: {
      //           padding: 20,
      //           autoSkip: true,
      //           maxRotation: 0,
      //           fontSize: 16
      //         }
      //       }]
      //     },
      //     animation: {
      //       duration: 0
      //     },
      //     maintainAspectRatio: false
      //   },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            fontSize: 9,
            labels: {
              fontSize: 9,
              padding: 20,
              fontStyle: "semibold",
            },
          },
        },
        onClick: function (point, event) {
          console.log(event);
          console.log("event : ", event[0]["index"]);
          console.log("point : ", point);
          if (event[0]["index"] == 0) {
            // this.$router.push("cartype/1100");
            window.open("carexpire/1", "_blank");
          } else if (event[0]["index"] == 1) {
            // this.$router.push("carexpire/1100");
            window.open("carexpire/2", "_blank");
          } else if (event[0]["index"] == 2) {
            // this.$router.push("carexpire/1100");
            window.open("carexpire/3", "_blank");
          } else if (event[0]["index"] == 3) {
            // this.$router.push("carexpire/1100");
            window.open("carexpire/4", "_blank");
          } else if (event[0]["index"] == 4) {
            // this.$router.push("carexpire/1100");
            window.open("carexpire/5", "_blank");
          } else if (event[0]["index"] == 5) {
            // this.$router.push("carexpire/1100");
            window.open("carexpire/6", "_blank");
          } else if (event[0]["index"] == 6) {
            // this.$router.push("carexpire/1100");
            window.open("carexpire/7", "_blank");
          }
        },
      },
      chartData: {},
      name: [],
      count: [],
      colour: [],
      mycolor: "#" + ((Math.random() * 0xffffff) << 0).toString(16),
      all: 0,
      options: {},
      series: [],
    };
  },
  mounted() {
    this.getgrouptype();
    // this.getchart();
  },
  methods: {
    getgrouptype() {
      CarService.getCarGroupExpire("", "","").then((res) => {
        // console.log(res.data);
        for (let r = 0; r < res.data.length; r++) {
          this.name.push(res.data[r].name);
          this.count.push(res.data[r].count);
          this.colour.push(
            (this.mycolor =
              "#" + ((Math.random() * 0xffffff) << 0).toString(16))
          );
        }
        for (let c = 0; c < this.count.length; c++) {
          this.all = this.all + this.count[c];
        }
        this.getchartbar();
      });
    },
    getchartbar() {
      this.options = {
        chart: {
          id: "vuechart-example",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              // console.log(chartContext);
              // console.log(config.dataPointIndex);
              var id = parseInt(config.dataPointIndex) + 1;
              console.log(id);
              window.open("carexpire/" + id, "_blank");
            },
            click: function (event, chartContext, config) {
              console.log(
                config.config.xaxis.categories[config.dataPointIndex]
              );
              CarTypesService.getCarTypes(
                config.config.xaxis.categories[config.dataPointIndex]
              ).then((res) => {
                window.open("carexpire/" + res.data[0].car_type_id, "_blank");
              });
              // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
            },
          },
        },
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        xaxis: {
          categories: this.name,
        },
        colors: [
          "#42ea6a",
          "#e71d4b",
          "#f3a1b7",
          "#edfa1b",
          "#597194",
          "#782471",
          "#ae548e",
        ],
        title: {
          text:
            "กราฟแสดงประเภทรถที่หมดภาษีใน 3 เดือน จำนวน " +
            this.all.toLocaleString() +
            " คัน",
        },
        style: {
          fontSize: "9px",
          fontWeight: "bold",
          fontFamily: "Niramit",
          color: "#263238",
        },
      };

      this.series = [
        {
          name: "จำนวน",
          data: this.count,
        },
      ];
      console.log(this.series);
    },
    getchart() {
      this.chartData = {
        labels: this.name,
        datasets: [
          {
            label: "ทั้งหมด",
            backgroundColor: [
              "#42ea6a",
              "#e71d4b",
              "#f3a1b7",
              "#edfa1b",
              "#597194",
              "#782471",
              "#ae548e",
            ],
            data: this.count,
          },
        ],
      };
    },
  },
};
</script>
<style scoped>
</style>