<template>
  <div>
    <div class="row mt-5 mb-3">
      <h5 class="col-md-12 text-center">
          ใบเสร็จที่ดำเนินการแล้วเสร็จทั้งหมด
      </h5>
    </div>
    <div class="container mt-3 mb-3">
       <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="exampleFormControlInput1">เลขทะเบียนรถ</label>
              <input
                v-model="license"
                type="text"
                class="form-control form-control-sm"
                id="exampleFormControlInput1"
                placeholder="เลขทะเบียนรถ"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="exampleFormControlSelect1">ประเภทรถ</label>
              <select
                v-model="car_type"
                class="form-control form-control-sm"
                id="exampleFormControlSelect1"
              >
                <option
                  v-for="(s, i) in car_types"
                  v-bind:value="s.car_type_id"
                  :key="i + 1"
                >
                  {{ s.code }} {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              &nbsp;
              <button
                class="btn btn-primary mt-3 btn-sm"
                style="color: white; width: 200px"
                @click="search()"
              >
                ค้นหา
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 5px; padding: 5px">
      <table class="table table-bordered" v-if="pageOfItems.length > 0">
        <thead>
          <tr class="table-active">
             <th scope="col">เลขที่ใบเสร็จ</th>
           <th scope="col">ชื่อ-นามสกุล</th>
            <th scope="col">ทะเบียนรถ</th>
           <th scope="col">ประเภทรถ</th>
           <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in pageOfItems" :key="i">
             <td>
              <a>{{ d.no }}</a>
            </td> 
             <td>
              <a>{{ d.name }}</a>
            </td>  
            <td>
              <a>{{ d.license }}</a>
            </td>  
            <td>
              <a>{{ d.car_type }}</a>
            </td>          
             <td> <a :href="'taxinvoice/'+d.id+'/2'">
                <button type="button" class="btn btn-warning">
                  <i class="fa fa-edit"></i></button
              ></a>&nbsp;
              <a></a></td>
          </tr>
        </tbody>
      </table>
      <div v-else style="text-align: center">ไม่พบข้อมูล</div>
    </div>
    <div class="col text-right mb-3" style="text-align: right">
      <jw-pagination
        :items="list"
        @changePage="onChangePage"
        :labels="customLabels"
      ></jw-pagination>
    </div>

<div class="row mt-3 mb-3">
      <div class="col-md-12 text-center">
        <button
        @click="$router.go(-1)"
          class="btn btn-warning"
          style="width: 200px"
        >
          ย้อนกลับ
        </button>
        &nbsp;
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="createdata"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              เพิ่มข้อมูลใบเสร็จ
            </h5>
            <button
              id="closedcreate"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            ><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
            <div class="form-group col-md-6">
              <label for="exampleFormControlSelect1">ปีใบเสร็จ</label>
              <select
                class="form-control form-control-sm"
                id="exampleFormControlSelect1"
                v-model="year"
              >
                <option
                  v-for="(s, i) in receipt"
                  v-bind:value="s.year"
                  :key="i + 1"
                >
                  {{ s.year }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" @click="create()">
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="deletedata"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบ</h5>
            <button
              id="closed"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            ><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" @click="deletedata()">
              ยืนยัน
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import CarService from "../services/CarService.js";
import CarTypesService from "../services/CarTypesService";
import MainPOService from "../services/MainPOService";
import TaxInvoiceService from "../services/TaxInvoiceService";
import ReceiptService from "../services/ReceiptService";

export default {
  name: "Carlist",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      car_types: [],
      license: "",
      owner: "",
      licenses: [],
      customLabels,
      pageOfItems: [],
      receipt_id: "",
      receipt: [],
      year: "",
      car_type:0
    };
  },
  methods: {
    deletedata() {
      ReceiptService.deleteReceipt(this.receipt_id).then(() => {
        // console.log(res.data);
        document.getElementById("closed").click();
        this.getList();
      });
    },
    getid(id) {
      this.receipt_id = id;
    },
    updatestatusmain(id) {
      MainPOService.updatestatus(id).then(() => {
        alert("บันทึกเรียบร้อยแล้ว");
        this.getList();
      });
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getlicense(n) {
      this.license = n;
    },
    getLicense() {
      CarService.getLicense().then((res) => {
        // console.log(res.data);
        this.licenses = res.data;
      });
    },
    getList() {
      TaxInvoiceService.getTaxInvoices(1,'','').then((res) => {
        // console.log(res.data);
        this.list = res.data;
      });
    },
    create() {
      let year = String(this.year).substring(2, 4);
      // console.log(year);
      var t = {
        name: "",
        year: this.year,
      };
      ReceiptService.createReceipt(t).then((res) => {
        this.receipt_id = res.data.id;

        CarService.getCars("", "").then((res) => {
          console.log(res.data);
          for (let r = 0; r < res.data.length; r++) {
            let no = "";
            var car_no = String(r+1);
            if (car_no.length == 1) {
              no = year + "000" + car_no;
            } else if (car_no.length == 2) {
              no = year + "00" + car_no;
            } else if (car_no.length == 3) {
              no = year + "0" + car_no;
            } else if (car_no.length == 4) {
              no = year + car_no;
            }
            var d = {
              receipt_id: this.receipt_id,
              car_id: res.data[r].car_id,
              no: no,
              year: this.year,
            };
            ReceiptService.createsubreceipt(d).then(() => {});
          }
          alert("บันทึกสำเร็จ");
          document.getElementById("closedcreate").click();
          this.getList();
        });
      });
    },
    getcartypes() {
      CarTypesService.getCarTypesReceipt(1).then((res) => {
        // console.log(res.data);
        this.car_types.push({
          car_type_id: 0,
          name: "ทั้งหมด",
        });
        for (let c = 0; c < res.data.length; c++) {
          this.car_types.push({
            car_type_id: res.data[c].car_type_id,
            name: res.data[c].code + " " + res.data[c].name,
          });
        }
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    search() {
      var type = ''
      if (this.car_type == 0) {
        type = ''
      }else{
        type = this.car_type
      }
      TaxInvoiceService.getTaxInvoices(1,this.license,type).then((res) => {
        // console.log(res.data);
        this.list = res.data;
      });
    },
  },
  mounted() {
    this.getList();
    this.getcartypes();
    this.getLicense();
    this.car_type = 0;
    var y = new Date();
    this.receipt.push({
      year: y.getFullYear() + 543,
    });
    this.receipt.push({
      year: y.getFullYear() + 544,
    });
    this.year = y.getFullYear() + 543
  },
  computed: {
    filteredList() {
      return this.licenses.filter((post) => {
        return post.license.toLowerCase().includes(this.license.toLowerCase());
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
  text-decoration: none;
}
#app {
  font-family: "Niramit";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
