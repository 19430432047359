<template>
  <div class="container mt-3">
    <div class="col text-right mb-3">
      <a @click="edit()" v-if="!statusedit">
        <button
          class="btn btn-success"
          style="color: white; background-color: #09cc68"
        >
          <i class="fa fa-plus"></i> แก้ไขข้อมูล
        </button></a
      >
    </div>
    <div class="row mb-5" v-if="!statusedit">
      <div class="col-md-3">
        <label for="exampleFormControlInput1">ชื่อเจ้าของรถ</label>
      </div>
      <div class="col-md-9">
        <label>{{ datas.name }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">ที่อยู่</label>
      </div>
      <div class="col-md-9">
        <label>{{ datas.house_no }} <span v-if="datas.moo">{{ 'ม.' + datas.moo }}</span> {{ datas.sub_district_name }} {{ datas.district_name }} {{ datas.province_name }}</label>
      </div>
    </div>
    <div class="row mb-5" v-if="statusedit">
      <OwnerEdit />
    </div>
  </div>
</template>

<script src="https://api-sbpacdb.ponnipa.in.th/picker_date.js"></script>

<script>
import OwnerEdit from "./OwnerEdit.vue";
import CarsOwnersService from "../services/CarsOwnersService";
import OwnerService from "../services/OwnerService";

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  components: {
    OwnerEdit,
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: [],
      owner_id: 0,
      image: "",
      itemPreviewImage: "",
      symbol: [],
      statusedit: false,
    };
  },
  methods: {
    edit() {
      this.statusedit = true;
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    getcar() {
      OwnerService.getOwner(this.owner_id).then((res) => {
        console.log(res.data);
        this.datas = res.data;
      });
    },
  },
  mounted() {
    this.owner_id = this.$route.params.id;
    console.log(this.owner_id);
    if (this.owner_id != 0) {
      this.getcar();
    } else {
      this.statusedit = true;
      this.datas.status = 1;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-weight: bold;
}
</style>
