import http from "../http-common";

class CarSymbolService {
  getCarSymbols() {
    return http.get('/car_symbol/');
  }
  getCarSymbol(id) {
    return http.get(`/car_symbol/` + id);
  }
  createCarSymbol(data) {
    return http.post('/car_symbol', data);
  }
  deleteCarSymbol(id) {
    return http.delete(`/car_symbol/${id}`);
  }
}

export default new CarSymbolService();