<template>
  <div style="padding: 0px">
    <div style="width: 100%; margin: auto">
      <div class="row">
        <div class="col-sm-3">
          <p class="company">ห้างหุ้นส่วน</p>
          <p class="company">จ.โชคชัยหิรัญ</p>
          <p class="company">จำกัด</p>
          <p style="text-align: center">(สำนักงานใหญ่)</p>
        </div>
        <div class="col-sm-6">
          <p style="font-size: 15px; text-align: center">
            ใบกำกับภาษี/ใบเสร็จรับเงิน
          </p>
          <p style="font-size: 15px; text-align: center">TAX INVOICE/RECEIPT</p>
        </div>
        <div class="col-sm-3"><span id="text" style="font-size:16px;">เลขที่ {{datas.no}}</span></div>
      </div>
      <hr style="background-color: #1e1e1e; height: 2px; border: 2 #1e1e1e;margin:0px;width: 92%;margin-left: 20px;" />
      <div style="margin: 20px">
        <div class="row">
          <div class="col-sm-7">
            <div class="textstyle">
              <p>ที่อยู่</p>
              <div class="row">
                <div class="col-sm-6">
                  <p>เลขที่ 408 หมู่ 9</p>
                  <p>ตำบล เมืองพราน</p>
                  <p>จังหวัด เชียงราย</p>
                  <p>โทรศัพท์ 053-722010</p>
                </div>
                <div class="col-sm-6">
                  <p>ถนน ซุปเปอร์ไฮเวย์</p>
                  <p>อำเภอ พาน</p>
                  <p>รหัสไปรษณีย์ 57120</p>
                  <p>โทรสาร 053-722010</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-5" style="padding-left:0px">
            <div class="textstyle">
              <div class="row">
                <div class="col-sm-12">
                  <p style="text-align: center">เลขประจำตัวผู้เสียภาษีอากร</p>
                  <p style="text-align: center">{{datas.id_card}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12"><span>อัตราภาษี</span>
                 <div class="form-check" style="margin-left:5px;">
                    <input  class="form-check-input" type="checkbox" value="true" v-model="datas.taxseven" />
                    <label class="form-check-label">ร้อยละ 7</label>
                  </div>
                   <div class="form-check" style="margin-left:5px">
                    <input  class="form-check-input" type="checkbox" value="true" v-model="datas.taxzero"/>
                    <label class="form-check-label">อัตราศูนย์</label>
                  </div>
                  </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p style="text-indent: 0px;" v-if="datas.discount">ส่วนลด {{datas.discount}}</p>
                  <p style="text-indent: 0px;" v-else>ส่วนลด ...................</p>
                  <p style="text-indent: 0px;">วันที่ {{datas.taxdate}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-12">
            <table style="width: 100%">
              <tr>
                <td>นาม {{datas.name}}</td>
              </tr>
              <tr>
                <td>ที่อยู่ {{datas.address}}</td>
              </tr>
              <tr>
                <td>
                  <div class="row">
                    <div class="col-sm-6" style="font-size:12px;">
                      <span>เลขประจำตัวผู้เสียภาษีอากร {{datas.id_card_tax}}</span>
                      </div>
                      <div class="col-sm-6" style="text-align:right">
                      <div class="form-check" style="margin-left:25px">
                            <input  class="form-check-input" type="checkbox" value="true" v-model="datas.headquarter"/>
                            <label class="form-check-label">สำนักงานใหญ่</label>
                          </div>
                          <div class="form-check" style="margin-left:25px">
                            <input  class="form-check-input" type="checkbox" value="true" v-model="datas.branch"/>
                            <label class="form-check-label" v-if="datas.branchname"
                              >สาขา {{datas.branchname}}</label
                            >
                            <label class="form-check-label" v-else
                              >สาขา ......................</label
                            >
                          </div>
                      </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-12">
            <table style="width: 100%">
              <tr>
                <td style="width: 8%; text-align: center">ลำดับ</td>
                <td style="width: 55%;text-align: center">รายการ</td>
                <td style="width: 12%; text-align: center">จำนวน</td>
                <td style="width: 12%; text-align: center">ราคา</td>
                <td style="width: 15%; text-align: center">จำนวนเงิน</td>
              </tr>
              <tr v-for="(d,i) in description" :key="i">
                <td style="text-align: center">{{d.no}}</td>
                <td style="text-align: center">{{d.name}}</td>
                <td style="text-align: center">{{d.amount}}</td>
                <td style="text-align: center">{{d.price.toLocaleString()}}</td>
                <td style="text-align: center" v-if="(d.amount*d.price).toLocaleString() !=0">{{(d.amount*d.price).toLocaleString()}}</td>
                <td style="text-align: center" v-else></td>
              </tr>
              <tr>
                <td rowspan="2" colspan="2">
                  <p style="text-align: center;font-size:10px">
                    ใบเสร็จนี้จะสมบูรณ์ต่อเมื่อห้างฯ
                    ได้เรียกเก็บเงินตามเช็คได้เรียบร้อยแล้ว
                  </p>
                  <p style="text-align: center;font-size:10px">
                    และต้องมีลายเซ็นผู้รับเงิน
                    หรือผู้ได้รับมอบอำนาจของห้างฯเท่านั้น
                  </p>
                </td>
                <td colspan="2" style="text-align: right">รวมราคาสินค้า</td>
                <td></td>
              </tr>
              <tr>
                <td colspan="2" style="text-align: right">ภาษีมูลค่าเพิ่ม</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td colspan="2" style="text-align: center">
                  ({{datas.textsum}})
                </td>
                <td colspan="2" style="text-align: right">รวมทั้งสิ้น</td>
                <td style="text-align: center">{{sumdata().toLocaleString()}}</td>
              </tr>
              <tr>
                <td colspan="5" style="text-align: center">
                  <div class="row">
                    <div class="col-md-2" style="text-align: center">
                      <input  class="form-check-input" type="checkbox" value="true" v-model="datas.cash"/>
                      <label class="form-check-label">เงินสด</label>
                    </div>
                    <div class="col-md-2" style="text-align: left">
                      <input  class="form-check-input" type="checkbox" value="true" v-model="datas.check"/>
                      <label class="form-check-label" v-if="datas.checkno"
                        >เช็คเลขที่ {{datas.checkno}}</label
                      >
                      <label class="form-check-label" v-else
                        >เช็คเลขที่ .......</label
                      >
                    </div>
                    <div class="col-md-4" style="text-align: left">
                      <label class="form-check-label" v-if="datas.bank">ธนาคาร {{datas.bank}}</label>
                      <label class="form-check-label" v-else>ธนาคาร ..................</label>
                    </div>
                    <div class="col-md-4" style="text-align: left">
                      <label class="form-check-label" v-if="datas.signdate"
                        >ลงวันที่ {{datas.signdate}}</label
                      >
                      <label class="form-check-label" v-else
                        >ลงวันที่ ....................</label
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <table style="width: 100%">
              <tr style="height:60px">
                <td style="width: 35%">
                  <div style="text-align:center">ผู้จ่ายเงิน.................................</div>
                  <div style="text-align:center" v-if="datas.payer">({{datas.payer}})</div>
                  <div style="text-align:center" v-else>(..............................................................)</div>
                </td>
                <td style="width: 30%">
                  <div style="text-align:center">ผู้ตรวจ....................................</div>
                  <div style="text-align:center" v-if="datas.inspector">({{datas.inspector}})</div>
               <div style="text-align:center" v-else>(....................................................)</div>

                </td>
                <td style="width: 35%">
                  <div style="text-align:center">ผู้รับเงิน..................................</div>
                  <div style="text-align:center" v-if="datas.payee">({{datas.payee}})</div>
                  <div style="text-align:center" v-else>(..............................................................)</div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://api-sbpacdb.ponnipa.in.th/picker_date.js"></script>
<script>
import TaxInvoiceService from "../services/TaxInvoiceService";
import CarService from "../services/CarService.js";
import ConvertService from "../services/ConvertService";
import CarSymbolService from "../services/CarSymbolService";
import TransportEdit from "./TransportEdit.vue";

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  components: {
    TransportEdit,
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: [],
      car_id: 0,
      image: "",
      itemPreviewImage: "",
      symbol: [],
      statusedit: false,
      docs: [],
      invoiceid:'',
      description:[],
      statusid:''
    };
  },
  methods: {
    sumdata(){
var total = 0,  //set a variable that holds our total
    i;
for (i = 0; i < this.description.length; i++) {  //loop through the array
    total += (this.description[i].amount*this.description[i].price); 
}
return total;  //display the result
    },
    save() {
      if (this.datas.car_type == null) {
        alert("กรุณาเลือกประเภทรถ");
      } else if (this.datas.license == null) {
        alert("กรุณากรอกเลขทะเบียนรถ");
      } else if (this.datas.brand == null) {
        alert("กรุณาเลือกยี่ห้อรถ");
      } else if (this.datas.VIN == null) {
        alert("กรุณากรอกเลขตัวรถ");
      } else if (this.datas.engine_number == null) {
        alert("กรุณากรอกเลขเครื่องยนต์");
      } else if (document.getElementById("tax_expiration_date").value == "") {
        alert("กรุณากรอกวันที่สิ้นอายุภาษี");
      } else if (document.getElementById("approval_date").value == "") {
        alert("กรุณากรอกวันอนุมัติ");
      } else if (this.datas.characteristic == null) {
        alert("กรุณาเลือกลักษณะ/มาตรฐาน (ช่าง)");
      } else {
        var car = {
          no: this.datas.no,
          car_no: this.datas.car_no,
          car_type: this.datas.car_type_id,
          license: this.datas.license,
          brand: this.datas.brand_id,
          VIN: this.datas.VIN,
          engine_number: this.datas.engine_number,
          tax_expiration_date: ConvertService.createddate(
            document.getElementById("tax_expiration_date").value
          ),
          approval_date: ConvertService.createddate(
            document.getElementById("approval_date").value
          ),
          characteristic: this.datas.characteristic_id,
          status: this.datas.status,
          image: this.image,
        };
        if (this.car_id != 0) {
          CarService.updateCar(this.car_id, car).then((res) => {
            console.log(res.data);
            CarSymbolService.deleteCarSymbol(this.car_id).then(() => {});
            if (this.symbol.length > 0) {
              for (let s = 0; s < this.symbol.length; s++) {
                var sym = {
                  car_id: this.car_id,
                  symbol_id: this.symbol[s],
                };
                CarSymbolService.createCarSymbol(sym).then(() => {});
              }
            }
            alert("บันทึกสำเร็จ");
          });
        } else {
          CarService.createCar(car).then((res) => {
            console.log(res.data);
            this.car_id = res.data.id;
            for (let s = 0; s < this.symbol.length; s++) {
              var sym = {
                car_id: this.car_id,
                symbol_id: this.symbol[s],
              };
              CarSymbolService.createCarSymbol(sym).then(() => {});
            }
            this.$router.push("/cars/" + this.car_id);
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 1000);
            window.scrollTo(0, 0);
          });
        }
      }
    },
     getlist(){
      TaxInvoiceService.getListTax(this.invoiceid).then((res)=>{
        // console.log(res.data);
this.description= res.data
if (this.description.length != 7) {
  var count = 7-this.description.length
  var no = this.description.length
  for (let d = 0; d < count; d++) {
    no = no+1
    this.description.push({
      no:no,
      name:'',
      amount:'',
      price:'',
      sum:''
    })    
  }
}
      })
    },
     gettax() {
      TaxInvoiceService.getTaxInvoice(this.invoiceid).then((res)=>{
      console.log(res.data);
      this.datas = res.data
      this.getlist()
      });
    },
    getcar() {
      TaxInvoiceService.getTaxInvoice(this.invoiceid).then((res)=>{
        // console.log(res.data);
        this.datas = res.data;
        
      TaxInvoiceService.getLastTaxInvoices().then((res)=>{
          var no = res.data.number
          if (!no) {
            no = 0
          }
          let year = new Date()
        year = year.getFullYear()+543
        var y = String(year).substring(2, 4);
        var car_no = String(no+1);
        if (car_no.length == 1) {
              no = y + "000" + car_no;
            } else if (car_no.length == 2) {
              no = y + "00" + car_no;
            } else if (car_no.length == 3) {
              no = y + "0" + car_no;
            } else if (car_no.length == 4) {
              no = y + car_no;
            }
            // console.log(no);
            this.datas.no = no
             this.datas.car_no = car_no
             this.datas.year = year
             this.datas.address = this.datas.address
this.datas.name = this.datas.one_contracts
this.datas.taxseven = false
this.datas.taxzero = false
this.datas.headquarter = false
this.datas.branch = false
this.datas.branchname = null
this.datas.cash = false
this.datas.checkstatus = false
this.datas.checkno = null
this.datas.bank = null 
// console.log(this.datas);
this.getlist()
      });
        });
        
    },
  },
  mounted() {
    this.invoiceid = this.$route.params.id;
    this.statusid = this.$route.params.statusid
    // console.log(this.car_id);
    if (this.statusid == 0) {   
      this.getcar()
     }else{
      this.gettax();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-size: 12px;
  font-weight: bold;
  /* font-family:Angsana New; */
  font-family: "Niramit";
  color: black;
}
span {
  font-size: 12px;
  /* font-family:Angsana New; */
  font-family: "Niramit";
  color: black;
  text-align: justify;
}
body {
  /* font-family:Angsana New; */
  font-family: "Niramit";
  font-size: 12px;
  color: black;
}
div.aa {
  text-align: justify;
  /* font-family:Angsana New; */
  font-family: "Niramit";
  color: black;
}
p {
  text-indent: 5px;
  margin-bottom: 0px;
  font-size: 12px;
  /* font-family:Angsana New; */
  font-family: "Niramit";
  color: black;
}
div {
  font-family: "Niramit";
  font-size: 12px;
}
.title {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}
.content {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-style: dotted;
  border-bottom: 1.6px dotted;
}
input {
  margin-bottom: 5px;
}
.textstyle {
  border-radius: 25px;
  border: 2px solid;
  padding: 5px;
  width: 100%;
  height: 110px;
}
table,
th,
td {
  border: 0.5px solid black;
  border-collapse: collapse;
  font-size: 12px;
}
tr {
  height: 30px;
  font-size: 12px;
}
#text {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.company {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
label {
  font-weight: normal !important;
}
.form-check {
    display: inline!important;
}
</style>
