import http from "../http-common";

class OwnerService {
  createOwner(data) {
    return http.post('/owner', data);
  }
  getOwners(license,name) {
    return http.get(`/owner?name=`+name+'&&license='+license);
  }
  getOwner(id) {
    return http.get(`/owner/`+id);
  }
  updateOwner(id, data) {
    return http.put(`/owner/${id}`, data);
  }
  updateTaxStatus(id, data) {
    return http.put(`/owner/updatetaxstatus/${id}`, data);
  }
}

export default new OwnerService();