<template>
  <div class="container-xxl bd-gutter mt-3 my-md-4 bd-layout">
    <!-- <div class="row col" v-if="main_id == 0 && !statusform">
      <div class="col-md-6">
        <div class="form-group mb-2">
          <label for="inputPassword2">จำนวนรถ</label>
          <div class="input-group input-group-sm">
            <input
              v-model="carcount"
              type="number"
              class="form-control form-control-sm"
            />
            <button type="button" class="btn btn-success" @click="create()">
              สร้างฟอร์ม
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <div>
      <div style="text-align: right">
        <h5>เลขที่ใบเสนอราคา {{ header.po_name }}</h5>
      </div>
      <div v-if="statusedit">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="exampleFormControlInput1">ชื่อ</label>
            <input
              v-model="header.name"
              type="text"
              class="form-control form-control-sm"
              id="exampleFormControlInput1"
              placeholder="ชื่อ"
            />
          </div>
          <div class="col-md-6"></div>
          <div class="form-group col-md-6">
            <label for="exampleFormControlInput1">ที่อยู่</label>
            <textarea
              rows="3"
              v-model="header.address"
              type="text"
              class="form-control form-control-sm"
              id="exampleFormControlInput1"
              placeholder="ที่อยู่"
            />
          </div>
          <div class="col-md-6"></div>
          <div class="form-group col-md-6">
            <label for="exampleFormControlInput1">เบอร์โทรศัพท์</label>
            <input
              v-model="header.phone"
              type="number"
              class="form-control form-control-sm"
              id="exampleFormControlInput1"
              placeholder="เบอร์โทรศัพท์"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="exampleFormControlInput1">ไลน์ไอดี</label>
            <input
              v-model="header.line_id"
              type="text"
              class="form-control form-control-sm"
              id="exampleFormControlInput1"
              placeholder="ไลน์ไอดี"
            />
          </div>
        </div>
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th class="table-active"></th>
              <th class="table-active" v-for="(t, i) in licenses" :key="t.id">
                คันที่ {{ i + 1 }}&nbsp;
                <button
                  v-if="i + 1 == licenses.length"
                  class="btn btn-success btn-sm"
                  @click="createcolumn()"
                >
                  <i class="fa fa-plus" style="font-size: 12px"></i></button
                >&nbsp;
                <button
                  v-if="i + 1 == licenses.length && licenses.length > 1"
                  class="btn btn-danger btn-sm"
                  @click="deletecolumn(i)"
                >
                  <i class="fa fa-trash" style="font-size: 12px"></i>
                </button>
              </th>
            </tr>
            <tr>
              <th class="table-active">ทะเบียนรถเดิม</th>
              <th class="table-active" v-for="t in licenses" :key="t.id">
                <input v-model="t.name" />
              </th>
            </tr>
          </thead>
          <tbody is="draggable" :list="data" tag="tbody">
            <tr style="cursor: move" v-for="(item, k) in data" :key="k">
              <td v-if="item.no">{{ item.name }}</td>
              <td v-else>
                <input type="text" v-model="item.name" /> &nbsp;<button
                  class="btn btn-danger btn-sm"
                  type="button"
                  @click="deletelist(k)"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </td>
              <td v-if="car1"><input type="text" v-model="item.car1" /></td>
              <td v-if="car2"><input type="text" v-model="item.car2" /></td>
              <td v-if="car3"><input type="text" v-model="item.car3" /></td>
              <td v-if="car4"><input type="text" v-model="item.car4" /></td>
              <td v-if="car5"><input type="text" v-model="item.car5" /></td>
              <td v-if="car6"><input type="text" v-model="item.car6" /></td>
              <td v-if="car7"><input type="text" v-model="item.car7" /></td>
              <td v-if="car8"><input type="text" v-model="item.car8" /></td>
              <td v-if="car8"><input type="text" v-model="item.car9" /></td>
              <td v-if="car10"><input type="text" v-model="item.car10" /></td>
            </tr>
          </tbody>
        </table>
        <div class="row mt-3 mb-5">
          <div class="col-md-12 text-center">
            <button
              class="btn btn-secondary"
              style="color: white; width: 200px"
              @click="add()"
            >
              เพิ่มรายการ
            </button>
            &nbsp;
            <button
              class="btn btn-success"
              style="color: white; width: 200px"
              @click="save()"
            >
              บันทึก
            </button>
            &nbsp;
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-md-3">
            <label for="exampleFormControlInput1">ชื่อ</label>
          </div>
          <div class="col-md-9">
            <label class="content">{{ header.name || "-" }}</label>
          </div>
          <div class="col-md-3">
            <label for="exampleFormControlInput1">ที่อยู่</label>
          </div>
          <div class="col-md-9">
            <label class="content">{{ header.address || "-" }}</label>
          </div>
          <div class="col-md-3">
            <label for="exampleFormControlInput1">เบอร์โทรศัพท์</label>
          </div>
          <div class="col-md-9">
            <label class="content">{{ header.phone || "-" }}</label>
          </div>
          <div class="col-md-3">
            <label for="exampleFormControlInput1">ไอดีไลน์</label>
          </div>
          <div class="col-md-9">
            <label class="content">{{ header.line_id || "-" }}</label>
          </div>
        </div>
        <div ref="document">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="table-active"></th>
                <th class="table-active" v-for="(t, i) in licenses" :key="t.id">
                  คันที่ {{ i + 1 }}
                </th>
              </tr>
              <tr>
                <th class="table-active">ทะเบียนรถเดิม</th>
                <th class="table-active" v-for="t in licenses" :key="t.id">
                  {{ t.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data" :key="item.id">
                <td>{{ item.name }}</td>
                <td v-if="car1">{{ item.car1 || "-" }}</td>
                <td v-if="car2">{{ item.car2 || "-" }}</td>
                <td v-if="car3">{{ item.car3 || "-" }}</td>
                <td v-if="car4">{{ item.car4 || "-" }}</td>
                <td v-if="car5">{{ item.car5 || "-" }}</td>
                <td v-if="car6">{{ item.car6 || "-" }}</td>
                <td v-if="car7">{{ item.car7 || "-" }}</td>
                <td v-if="car8">{{ item.car8 || "-" }}</td>
                <td v-if="car8">{{ item.car9 || "-" }}</td>
                <td v-if="car10">{{ item.car10 || "-" }}</td>
              </tr>
              <tr>
                <th>รวม</th>
                <th v-if="car1">{{ sum1.toLocaleString() }}</th>
                <th v-if="car2">{{ sum2.toLocaleString() }}</th>
                <th v-if="car3">{{ sum3.toLocaleString() }}</th>
                <th v-if="car4">{{ sum4.toLocaleString() }}</th>
                <th v-if="car5">{{ sum5.toLocaleString() }}</th>
                <th v-if="car6">{{ sum6.toLocaleString() }}</th>
                <th v-if="car7">{{ sum7.toLocaleString() }}</th>
                <th v-if="car8">{{ sum8.toLocaleString() }}</th>
                <th v-if="car8">{{ sum9.toLocaleString() }}</th>
                <th v-if="car10">{{ sum10.toLocaleString() }}</th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row mt-3 mb-5">
          <div class="col-md-12 text-center">
            <!-- <button class="btn btn-success"
          style="color: white; width: 200px" @click="updatestatusmain()">ตรวจสอบเรียบร้อย</button>
            &nbsp; -->
            <button
              class="btn btn-warning"
              style="color: white; width: 200px"
              @click="edit()"
            >
              แก้ไข
            </button>
            &nbsp;
            <button
              class="btn btn-info"
              style="color: white; width: 200px"
              @click="exportToPDF()"
            >
              ดาวน์โหลดไฟล์
            </button>
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MasterFormService from "../services/MasterFormService.js";
import FormPOService from "../services/FormPOService";
import MainPOService from "../services/MainPOService";

import draggable from "vuedraggable";
import html2pdf from "html2pdf.js";

export default {
  name: "Nav",
  components: {
    draggable,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      data: [],
      enabled: true,
      loading: false,
      list: [
        { name: "John", id: 0 },
        { name: "Joao", id: 1 },
        { name: "Jean", id: 2 },
      ],
      dragging: false,
      licenses: [],
      user: [],
      tableItems: [],
      car1: false,
      car2: false,
      car3: false,
      car4: false,
      car5: false,
      car6: false,
      car7: false,
      car8: false,
      car9: false,
      car10: false,
      carcount: 0,
      id: "",
      lists: "",
      main_id: "",
      form_po_id: "",
      statusform: false,
      header: {},
      statusedit: true,
      runningnumber: 0,
      sum1: 0,
      sum2: 0,
      sum3: 0,
      sum4: 0,
      sum5: 0,
      sum6: 0,
      sum7: 0,
      sum8: 0,
      sum9: 0,
      sum10: 0,
    };
  },
  methods: {
    deletecolumn(index) {
      for (let l = 0; l < this.licenses.length; l++) {
        if (l == index) {
          this.licenses.splice(index, 1);
        }
      }
      this.carcount = this.carcount - 1;
      this.generatecar();
    },
    updatestatusmain() {
      MainPOService.updatestatus(this.main_id).then(() => {
        alert("บันทึกเรียบร้อยแล้ว");
        this.$router.push("/formlist");
        setTimeout(function () {
          location.reload();
        }, 1000);
        window.scrollTo(0, 0);
      });
    },
    deletelist(index) {
      for (let l = 0; l < this.data.length; l++) {
        if (l == index) {
          this.data.splice(index, 1);
        }
      }
    },
    exportToPDF() {
      this.downloadstatus = true;
      html2pdf(this.$refs.document, {
        margin: [0.5, 0.5, 0, 0.5],
        filename: "ใบเสนอราคา " + this.header.po_name + ".pdf",
        jsPDF: { unit: "cm", format: "a4", orientation: "l" },
      });
    },
    edit() {
      this.statusedit = true;
    },
    generatecar() {
      this.car1 = false;
      this.car2 = false;
      this.car3 = false;
      this.car4 = false;
      this.car5 = false;
      this.car6 = false;
      this.car7 = false;
      this.car8 = false;
      this.car9 = false;
      this.car10 = false;
      if (this.licenses.length == 1) {
        this.car1 = true;
      } else if (this.licenses.length == 2) {
        this.car1 = true;
        this.car2 = true;
      } else if (this.licenses.length == 3) {
        this.car1 = true;
        this.car2 = true;
        this.car3 = true;
      } else if (this.licenses.length == 4) {
        this.car1 = true;
        this.car2 = true;
        this.car3 = true;
        this.car4 = true;
      } else if (this.licenses.length == 5) {
        this.car1 = true;
        this.car2 = true;
        this.car3 = true;
        this.car4 = true;
        this.car5 = true;
      } else if (this.licenses.length == 6) {
        this.car1 = true;
        this.car2 = true;
        this.car3 = true;
        this.car4 = true;
        this.car5 = true;
        this.car6 = true;
      } else if (this.licenses.length == 7) {
        this.car1 = true;
        this.car2 = true;
        this.car3 = true;
        this.car4 = true;
        this.car5 = true;
        this.car6 = true;
        this.car7 = true;
      } else if (this.licenses.length == 8) {
        this.car1 = true;
        this.car2 = true;
        this.car3 = true;
        this.car4 = true;
        this.car5 = true;
        this.car6 = true;
        this.car7 = true;
        this.car8 = true;
      } else if (this.licenses.length == 9) {
        this.car1 = true;
        this.car2 = true;
        this.car3 = true;
        this.car4 = true;
        this.car5 = true;
        this.car6 = true;
        this.car8 = true;
        this.car9 = true;
      } else if (this.licenses.length == 10) {
        this.car1 = true;
        this.car2 = true;
        this.car3 = true;
        this.car4 = true;
        this.car5 = true;
        this.car6 = true;
        this.car7 = true;
        this.car8 = true;
        this.car9 = true;
        this.car10 = true;
      }
    },
    createcolumn() {
      this.carcount = this.carcount + 1;
      // console.log(this.carcount);
      for (let d = 0; d < this.data.length; d++) {
        if (this.carcount == 1) {
          this.data[d].car1 = "";
        } else if (this.carcount == 2) {
          this.data[d].car2 = "";
        } else if (this.carcount == 3) {
          this.data[d].car3 = "";
        } else if (this.carcount == 4) {
          this.data[d].car4 = "";
        } else if (this.carcount == 5) {
          this.data[d].car5 = "";
        } else if (this.carcount == 6) {
          this.data[d].car6 = "";
        } else if (this.carcount == 7) {
          this.data[d].car7 = "";
        } else if (this.carcount == 8) {
          this.data[d].car8 = "";
        } else if (this.carcount == 9) {
          this.data[d].car9 = "";
        } else if (this.carcount == 10) {
          this.data[d].car10 = "";
        }
      }
      // console.log(this.data);
      this.licenses.push({
        id: this.licenses.length + 1,
        name: "",
        form_po_id: 0,
      });

      this.generatecar();
      // console.log(this.runningnumber);
    },
    create() {
      // console.log(this.carcount);
      this.carcount = this.carcount + 1;
      this.car1 = false;
      this.car2 = false;
      this.car3 = false;
      this.car4 = false;
      this.car5 = false;
      this.car6 = false;
      this.car7 = false;
      this.car8 = false;
      this.car9 = false;
      this.car10 = false;
      for (let d = 0; d < this.data.length; d++) {
        if (this.carcount == 1) {
          this.data[d].car1 = "";
        } else if (this.carcount == 2) {
          this.data[d].car1 = "";
          this.data[d].car2 = "";
        } else if (this.carcount == 3) {
          this.data[d].car1 = "";
          this.data[d].car2 = "";
          this.data[d].car3 = "";
        } else if (this.carcount == 4) {
          this.data[d].car1 = "";
          this.data[d].car2 = "";
          this.data[d].car3 = "";
          this.data[d].car4 = "";
        } else if (this.carcount == 5) {
          this.data[d].car1 = "";
          this.data[d].car2 = "";
          this.data[d].car3 = "";
          this.data[d].car4 = "";
          this.data[d].car5 = "";
        } else if (this.carcount == 6) {
          this.data[d].car1 = "";
          this.data[d].car2 = "";
          this.data[d].car3 = "";
          this.data[d].car4 = "";
          this.data[d].car5 = "";
          this.data[d].car6 = "";
        } else if (this.carcount == 7) {
          this.data[d].car1 = "";
          this.data[d].car2 = "";
          this.data[d].car3 = "";
          this.data[d].car4 = "";
          this.data[d].car5 = "";
          this.data[d].car6 = "";
          this.data[d].car7 = "";
        } else if (this.carcount == 8) {
          this.data[d].car1 = "";
          this.data[d].car2 = "";
          this.data[d].car3 = "";
          this.data[d].car4 = "";
          this.data[d].car5 = "";
          this.data[d].car6 = "";
          this.data[d].car7 = "";
          this.data[d].car8 = "";
        } else if (this.carcount == 9) {
          this.data[d].car1 = "";
          this.data[d].car2 = "";
          this.data[d].car3 = "";
          this.data[d].car4 = "";
          this.data[d].car5 = "";
          this.data[d].car6 = "";
          this.data[d].car7 = "";
          this.data[d].car8 = "";
          this.data[d].car9 = "";
        } else if (this.carcount == 10) {
          this.data[d].car1 = "";
          this.data[d].car2 = "";
          this.data[d].car3 = "";
          this.data[d].car4 = "";
          this.data[d].car5 = "";
          this.data[d].car6 = "";
          this.data[d].car7 = "";
          this.data[d].car8 = "";
          this.data[d].car9 = "";
          this.data[d].car10 = "";
        }
      }
      for (let cc = 0; cc < this.carcount; cc++) {
        this.licenses.push({
          id: this.licenses.length + 1,
          name: "",
          form_po_id: 0,
        });
        console.log(this.licenses);
      }
      this.generatecar();
      // console.log(this.runningnumber);
    },
    saveform() {
      console.log(this.main_id);
      console.log(this.data);
      FormPOService.deleteFormPO(this.main_id).then(()=>{
      })
      for (let l = 0; l < this.licenses.length; l++) {
        this.lists = "";
        this.lists += "[{" + '"license":' + '"' + this.licenses[l].name + '",';
        this.lists += '"main_po_id":' + '"' + this.main_id + '",';
        this.form_po_id = 0;
        this.form_po_id = this.licenses[l].form_po_id;
        if (this.licenses.length == 1) {
          for (let d = 0; d < this.data.length; d++) {
            this.lists +=
              '"' +
              this.data[d].table_name +
              '"' +
              ":" +
              '"' +
              this.data[d].car1 +
              '",';
          }
          this.lists = this.lists.substring(0, this.lists.length - 1);
          this.lists += "}]";
        } else if (this.licenses.length > 1) {
          if (l == 0) {
            for (let d = 0; d < this.data.length; d++) {
              if (typeof this.data[d].car1 === "undefined") {
                this.data[d].car1 = "";
              }
              this.lists +=
                '"' +
                this.data[d].table_name +
                '"' +
                ":" +
                '"' +
                this.data[d].car1 +
                '",';
            }
          } else if (l == 1) {
            for (let d = 0; d < this.data.length; d++) {
              if (typeof this.data[d].car2 === "undefined") {
                this.data[d].car2 = "";
              }
              this.lists +=
                '"' +
                this.data[d].table_name +
                '"' +
                ":" +
                '"' +
                this.data[d].car2 +
                '",';
            }
          } else if (l == 2) {
            for (let d = 0; d < this.data.length; d++) {
              if (typeof this.data[d].car3 === "undefined") {
                this.data[d].car3 = "";
              }
              this.lists +=
                '"' +
                this.data[d].table_name +
                '"' +
                ":" +
                '"' +
                this.data[d].car3 +
                '",';
            }
          } else if (l == 3) {
            for (let d = 0; d < this.data.length; d++) {
              if (typeof this.data[d].car4 === "undefined") {
                this.data[d].car4 = "";
              }
              this.lists +=
                '"' +
                this.data[d].table_name +
                '"' +
                ":" +
                '"' +
                this.data[d].car4 +
                '",';
            }
          } else if (l == 4) {
            for (let d = 0; d < this.data.length; d++) {
              if (typeof this.data[d].car5 === "undefined") {
                this.data[d].car5 = "";
              }
              this.lists +=
                '"' +
                this.data[d].table_name +
                '"' +
                ":" +
                '"' +
                this.data[d].car5 +
                '",';
            }
          } else if (l == 5) {
            for (let d = 0; d < this.data.length; d++) {
              if (typeof this.data[d].car6 === "undefined") {
                this.data[d].car6 = "";
              }
              this.lists +=
                '"' +
                this.data[d].table_name +
                '"' +
                ":" +
                '"' +
                this.data[d].car6 +
                '",';
            }
          } else if (l == 6) {
            for (let d = 0; d < this.data.length; d++) {
              if (typeof this.data[d].car7 === "undefined") {
                this.data[d].car7 = "";
              }
              this.lists +=
                '"' +
                this.data[d].table_name +
                '"' +
                ":" +
                '"' +
                this.data[d].car7 +
                '",';
            }
          } else if (l == 7) {
            for (let d = 0; d < this.data.length; d++) {
              if (typeof this.data[d].car8 === "undefined") {
                this.data[d].car8 = "";
              }
              this.lists +=
                '"' +
                this.data[d].table_name +
                '"' +
                ":" +
                '"' +
                this.data[d].car8 +
                '",';
            }
          } else if (l == 8) {
            for (let d = 0; d < this.data.length; d++) {
              if (typeof this.data[d].car9 === "undefined") {
                this.data[d].car9 = "";
              }
              this.lists +=
                '"' +
                this.data[d].table_name +
                '"' +
                ":" +
                '"' +
                this.data[d].car9 +
                '",';
            }
          } else if (l == 9) {
            for (let d = 0; d < this.data.length; d++) {
              if (typeof this.data[d].car10 === "undefined") {
                this.data[d].car10 = "";
              }
              this.lists +=
                '"' +
                this.data[d].table_name +
                '"' +
                ":" +
                '"' +
                this.data[d].car10 +
                '",';
            }
          }
          this.lists = this.lists.substring(0, this.lists.length - 1);
          this.lists += "}]";
        }
        // var f = "{license:'=i',data1:'1',data2:'23',data3:'132',data4:'132',data5:'132',data6:'1',data7:'1',data8:'313',data9:'212',data10:'13',data11:'21',data12:'12',data13:'31',data14:'2131',data15:'23',data16:'12313'}"
        // console.log(this.lists);
        var data = JSON.parse(this.lists);
        // console.log(data);
        console.log(this.main_id);
        console.log(this.form_po_id);
        // if (this.form_po_id == 0) {
          FormPOService.createFormPO(data).then((res) => {
            console.log(res.data);
          });
        // } 
        // else {
        //   // this.updateform()
        //   FormPOService.updateFormPO(this.form_po_id, data).then(() => {
        //     // console.log(res.data);
        //   });
        // }
      }
      alert("บันทึกสำเร็จ");
      this.$router.push("/form/" + this.main_id);
      setTimeout(function () {
        location.reload();
      }, 1000);
      window.scrollTo(0, 0);
    },
    save() {
      for (let d = 0; d < this.data.length; d++) {
        if (this.data[d].no == "") {
          // console.log(this.data[d].name);
          var create = {
            table_name: this.data[d].table_name,
            name: this.data[d].name,
            no: d + 1,
          };
          MasterFormService.createMasterForm(create).then(() => {
            // console.log(res.data);
          });
          var column = {
            table_name: "data" + this.data.length,
          };
          MasterFormService.createcolumnMasterForm(column).then(() => {
            // console.log(res.data);
          });
        } else {
          var master = {
            no: d + 1,
          };
          MasterFormService.updateMasterForm(this.data[d].id, master).then(
            () => {
              // console.log(res.data);
            }
          );
        }
      }
      this.saveformall();
    },
    saveformall() {
      var main = {
        po_name: this.header.po_name,
        name: this.header.name,
        address: this.header.address,
        phone: this.header.phone,
        line_id: this.header.line_id,
        status: true,
      };
      if (this.main_id == 0) {
        MainPOService.createMainFormPO(main).then((res) => {
          this.main_id = res.data.id;
          this.saveform();
        });
      } else {
        MainPOService.updategetMainPO(this.main_id, main).then(() => {
          this.saveform();
        });
      }
    },
    add() {
      this.data.push({
        name: "",
        id: this.id++,
        no: 0,
        table_name: "data" + parseInt(this.data.length + 1),
        car1: "",
        car2: "",
        car3: "",
        car4: "",
        car5: "",
        car6: "",
        car7: "",
        car8: "",
        car9: "",
        car10: "",
      });
      console.log(this.data);
    },
    checkMove(e) {
      console.log(e.draggedContext.element.id);
      console.log("Future index: " + e.draggedContext.futureIndex);
    },
    getmaster() {
      MasterFormService.getMasterForms().then((res) => {
        console.log(res.data);
        this.data = res.data;
        this.id = this.data.length + 1;
        if (this.main_id != 0) {
          this.getform();
          this.statusform = true;
          this.statusedit = false;
        } else {
          var date = new Date();
          this.header.po_name =
            "PO" +
            date.getFullYear() +
            (date.getUTCMonth() + 1) +
            date.getDate() +
            parseInt(this.runningnumber + 1);
          // console.log(this.header);
          this.create();
        }
      });
    },
    getform() {
      FormPOService.getFormPOs(this.main_id).then((res) => {
        // console.log(res.data.length);
        // console.log(this.data);
        for (let m = 0; m < res.data.length; m++) {
          this.licenses.push({
            id: this.licenses.length + 1,
            name: res.data[m].license,
            form_po_id: res.data[m].id,
          });
          for (let d = 0; d < this.data.length; d++) {
            if (res.data.length > 1) {
              if (m == 0) {
                if (this.data[d].no == d + 1) {
                  this.data[d].car1 = res.data[m][this.data[d].table_name];
                  var chk1 = parseInt(res.data[m][this.data[d].table_name]);
                  if (isNaN(chk1)) {
                    chk1 = 0;
                  }
                  this.sum1 = this.sum1 + parseInt(chk1);
                }
              } else if (m == 1) {
                if (this.data[d].no == d + 1) {
                  this.data[d].car2 = res.data[m][this.data[d].table_name];
                  var chk2 = parseInt(res.data[m][this.data[d].table_name]);
                  if (isNaN(chk2)) {
                    chk2 = 0;
                  }
                  this.sum2 = this.sum2 + parseInt(chk2);
                }
              } else if (m == 2) {
                if (this.data[d].no == d + 1) {
                  this.data[d].car3 = res.data[m][this.data[d].table_name];
                  var chk3 = parseInt(res.data[m][this.data[d].table_name]);
                  if (isNaN(chk3)) {
                    chk3 = 0;
                  }
                  this.sum3 = this.sum3 + parseInt(chk3);
                }
              } else if (m == 3) {
                if (this.data[d].no == d + 1) {
                  this.data[d].car4 = res.data[m][this.data[d].table_name];
                  var chk4 = parseInt(res.data[m][this.data[d].table_name]);
                  if (isNaN(chk4)) {
                    chk4 = 0;
                  }
                  this.sum4 = this.sum4 + parseInt(chk4);
                }
              } else if (m == 4) {
                if (this.data[d].no == d + 1) {
                  this.data[d].car5 = res.data[m][this.data[d].table_name];
                  var chk5 = parseInt(res.data[m][this.data[d].table_name]);
                  if (isNaN(chk5)) {
                    chk5 = 0;
                  }
                  this.sum5 = this.sum5 + parseInt(chk5);
                }
              } else if (m == 5) {
                if (this.data[d].no == d + 1) {
                  this.data[d].car6 = res.data[m][this.data[d].table_name];
                  var chk6 = parseInt(res.data[m][this.data[d].table_name]);
                  if (isNaN(chk6)) {
                    chk6 = 0;
                  }
                  this.sum6 = this.sum6 + parseInt(chk6);
                }
              } else if (m == 6) {
                if (this.data[d].no == d + 1) {
                  this.data[d].car7 = res.data[m][this.data[d].table_name];
                  var chk7 = parseInt(res.data[m][this.data[d].table_name]);
                  if (isNaN(chk7)) {
                    chk7 = 0;
                  }
                  this.sum7 = this.sum7 + parseInt(chk7);
                }
              } else if (m == 7) {
                if (this.data[d].no == d + 1) {
                  this.data[d].car8 = res.data[m][this.data[d].table_name];
                  var chk8 = parseInt(res.data[m][this.data[d].table_name]);
                  if (isNaN(chk8)) {
                    chk8 = 0;
                  }
                  this.sum8 = this.sum8 + parseInt(chk8);
                }
              } else if (m == 8) {
                if (this.data[d].no == d + 1) {
                  this.data[d].car9 = res.data[m][this.data[d].table_name];
                  var chk9 = parseInt(res.data[m][this.data[d].table_name]);
                  if (isNaN(chk9)) {
                    chk9 = 0;
                  }
                  this.sum9 = this.sum9 + parseInt(chk9);
                }
              } else if (m == 9) {
                if (this.data[d].no == d + 1) {
                  this.data[d].car10 = res.data[m][this.data[d].table_name];
                  var chk10 = parseInt(res.data[m][this.data[d].table_name]);
                  if (isNaN(chk10)) {
                    chk10 = 0;
                  }
                  this.sum10 = this.sum10 + parseInt(chk10);
                }
              }
            } else {
              if (this.data[d].no == d + 1) {
                this.data[d].car1 = res.data[m][this.data[d].table_name];
                var chk = parseInt(res.data[m][this.data[d].table_name]);
                if (isNaN(chk)) {
                  chk = 0;
                }
                this.sum1 = this.sum1 + parseInt(chk);
              }
            }
          }
        }
        // console.log(this.data);
        this.generatecar();
      });
      MainPOService.getMainPO(this.main_id).then((res) => {
        this.header = res.data;
      });
    },
  },
  mounted() {
    this.main_id = this.$route.params.id;
    this.getmaster();
    MainPOService.getMainPOs().then((res) => {
      // console.log(res.data);
      this.runningnumber = res.data.length;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div {
  font-size: 14px;
}
td {
  padding: 8px;
}
th {
  padding: 8px;
}
.content {
  font-weight: normal !important;
}
</style>
