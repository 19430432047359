import http from "../http-common";

class sub_receiptservice {
  getsubreceipts() {
    return http.get('/sub_receipts');
  } 
  createsubreceipt(data) {
    return http.post('/sub_receipts', data);
  }
  getsubreceipt(id) {
    return http.get(`/sub_receipts/` + id);
  }
  updatesubreceipt(id, data) {
    return http.put(`/sub_receipts/${id}`, data);
  } 
  getsubtaxs(owner,license) {
    return http.get('/sub_taxation?owner='+owner+'&&license='+license);
  } 
  createsubtax(data) {
    return http.post('/sub_taxation', data);
  }
  getsubtax(id) {
    return http.get(`/sub_taxation/` + id);
  }
  updatesubtax(id, data) {
    return http.put(`/sub_taxation/${id}`, data);
  } 
  getTaxations(owner,license) {
    return http.get('/taxation?owner='+owner+'&&license='+license);
  } 
  createTaxation(data) {
    return http.post('/taxation', data);
  }
  getTaxation(id) {
    return http.get(`/taxation/` + id);
  }
  updateTaxation(id, data) {
    return http.put(`/taxation/${id}`, data);
  }  
  deleteTaxation(id) {
    return http.delete(`/taxation/` + id);
  }
  createReceipt(data) {
    return http.post('/receipts', data);
  }
  getReceipts(year) {
    return http.get(`/receipts?name=`+year);
  }
  getReceiptByYear(year) {
    return http.get(`/receipts/findallbyyear?name=` + year);
  }
  getReceipt(id) {
    return http.get(`/receipts/` + id);
  }
  updateReceipt(id, data) {
    return http.put(`/receipts/${id}`, data);
  }  
  deleteReceipt(id) {
    return http.delete(`/receipts/` + id);
  }
}

export default new sub_receiptservice();