<template>
  <div>
    <div class="container mt-3 mb-3">
      <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="exampleFormControlInput1">เลขทะเบียนรถ</label>
              <input
                v-model="license"
                type="text"
                class="form-control form-control-sm"
                id="exampleFormControlInput1"
                placeholder="เลขทะเบียนรถ" 
              />
              <div v-if="license != '' && filteredList.length > 1">
                <ul
                  class="list-group"
                  v-for="(s, i) in filteredList"
                  v-bind:value="s.car_id"
                  :key="i + 1"
                >
                  <div
                    class="
                      form-control form-control-sm
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    style="border-color: blue"
                  >
                    <a href="#search" @click="getlicense(s.license)">
                      {{ s.license }}</a
                    >
                  </div>
                </ul>
              </div>
            </div>

            <div class="form-group col-md-6">
              <label for="exampleFormControlInput1">เจ้าของรถ</label>
              <input
                v-model="owner"
                type="text"
                class="form-control form-control-sm"
                id="exampleFormControlInput1"
                placeholder="เจ้าของรถ"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <!-- <button
            class="btn btn-warning"
            style="color: white; width: 200px"
            @click="back()"
          >
            ย้อนกลับ
          </button> -->
              &nbsp;
              <button
                class="btn btn-primary mt-3"
                style="color: white; width: 200px"
                @click="search()"
              >
                ค้นหา
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 5px; padding: 5px">
      <div class="col text-right mb-3" style="text-align:right">
        <a href="ownercars/0">
          <button
            class="btn btn-success"
            style="color: white; background-color: #09cc68"
          >
            <i class="fa fa-plus"></i> เพิ่มข้อมูล
          </button></a
        >
      </div>
      <!-- <div v-for="(l, i) in list" :key="i">
      {{ l.code }} {{ l.name }} -->
      <table class="table table-bordered" v-if="pageOfItems.length > 0">
        <thead>
          <tr class="table-active">
            <th scope="col">เลขทะเบียนรถ</th>
            <th scope="col">เจ้าของรถ</th>
            <th scope="col">วันที่สิ้นอายุภาษี</th>
             <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in pageOfItems" :key="i">
            <td scope="row">
              <a> {{ d.license }}</a>
            </td>
            <td scope="row">
              <a
                >{{ d.name }} {{ d.house_no }}
                <span v-if="d.moo"> {{ "ม." + d.moo }}</span>
                {{ d.sub_district_name }} {{ d.district_name }}
                {{ d.province_name }}</a
              >
            </td>
            <td>
              <a>{{
                convertdatetothai(d.tax_expiration_date)
              }}</a>
            </td>
            <td> <a :href="'ownercars/' + d.owner_id">
                <button type="button" class="btn btn-warning">
                  <i class="fa fa-edit"></i></button
              ></a>&nbsp;
              <a>
                <button
                @click="getid(d.car_owner_id)"
                  data-toggle="modal" data-target="#deletedata"
                  type="button"
                  class="btn btn-danger"
                >
                  <i class="fa fa-trash"></i></button
              ></a></td>
            <!-- <td><a :href="'transport/' + d.car_owner_id">
          <button
            class="btn btn-info"
            style="color: white;"
          >
            <i class="fa fa-file-text" aria-hidden="true"></i>
          </button></a
        ></td> -->
          </tr>
        </tbody>
      </table>
      <div v-else style="text-align: center">ไม่พบข้อมูล</div>
    </div>
    <div class="col text-right mb-3" style="text-align:right">
       <jw-pagination
          :items="list"
          @changePage="onChangePage"
          :labels="customLabels"
        ></jw-pagination>
      </div>

        <!-- Modal -->
<div class="modal fade" id="deletedata" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบ</h5>
            <button 
            id="closed" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" @click="deletedata()">
              ยืนยัน
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};


import CarService from "../services/CarService";
import CarsOwnersService from "../services/CarsOwnersService";

export default {
  name: "Carlist",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      car_types: [],
      license: "",
      car_type: "",
      owner: "",
      licenses: [],
      customLabels,
      pageOfItems: [],
      car_owner_id:''
    };
  },
  methods: {
    deletedata() {
       CarsOwnersService.deleteCaOwner(this.car_owner_id).then(()=>{
        // console.log(res.data);
        document.getElementById("closed").click();
        this.getList()
       })
    },
    getid(id) {
      this.car_owner_id = id
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getlicense(n) {
      this.license = n;
    },
    getList() {
      CarsOwnersService.getCarsOwners("", "").then((res) => {
        // console.log(res.data);
        this.list = res.data;
      });
      // OwnerService.getOwners("","").then((res) => {
      //   console.log(res.data);
      //   this.list = res.data;
      // });
    },
    // getcartypes() {
    //   CarTypesService.getCarTypes().then((res) => {
    //     console.log(res.data);
    //     this.car_types.push({
    //       car_type_id: 0,
    //       name: "ทั้งหมด",
    //     })
    //     for (let c = 0; c < res.data.length; c++) {
    //       this.car_types.push({
    //       car_type_id: res.data[c].car_type_id,
    //       name: res.data[c].code +" "+ res.data[c].name,
    //     })
    //     }
    //   });
    // },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    getLicense() {
      CarService.getLicense().then((res) => {
        // console.log(res.data);
        this.licenses = res.data;
      });
    },
    search() {
      CarsOwnersService.getCarsOwners(this.license, this.owner).then((res) => {
        console.log(res.data);
        this.list = [];
        this.list = res.data;
      });
    },
  },
  mounted() {
    this.getList();
    this.getLicense();
    this.car_type = 0;
  },
  computed: {
    filteredList() {
      return this.licenses.filter((post) => {
        return post.license.toLowerCase().includes(this.license.toLowerCase());
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
  text-decoration: none;
}
</style>
