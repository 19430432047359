import http from "../http-common";

class FormPOService {
  getFormPOs(id) {
    return http.get('/form_po?id='+id);
  } 
  createFormPO(data) {
    return http.post('/form_po', data);
  }
  getFormPO(id) {
    return http.get(`/form_po/` + id);
  }
  updateFormPO(id, data) {
    return http.put(`/form_po/${id}`, data);
  }  
  deleteFormPO(id, data) {
    return http.delete(`/form_po/${id}`, data);
  } 
}

export default new FormPOService();