import http from "../http-common";

class TransportService {
  getCarsTransports(owner,license) {
    return http.get('/transport_contracts?owner='+owner+'&&license='+license);
  } 
  createTransport(data) {
    return http.post('/transport_contracts', data);
  }
  getTransport(id) {
    return http.get(`/transport_contracts/` + id);
  }
  updateTransport(id, data) {
    return http.put(`/transport_contracts/${id}`, data);
  }  
  createTransportImage(data) {
    return http.post('/transport_images', data);
  }
  getTransportImage(id) {
    return http.get(`/transport_images/` + id);
  }
  deleteTransportImage(id) {
    return http.delete(`/transport_images/` + id);
  }
}

export default new TransportService();