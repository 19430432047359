<template>
  <div>
    <div class="container mt-3 mb-3">
      <div class="card mt-5" v-if="false">
        <div class="card-body">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="exampleFormControlInput1"> ผู้เดินรถร่วม</label>
              <input
                v-model="owner"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="ผู้เดินรถร่วม"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="exampleFormControlSelect1">เลขทะเบียนรถ</label>
              <input
                v-model="license"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="เลขทะเบียนรถ"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <!-- <button
            class="btn btn-warning"
            style="color: white; width: 200px"
            @click="back()"
          >
            ย้อนกลับ
          </button> -->
              &nbsp;
              <button
                class="btn btn-primary"
                style="color: white; width: 200px"
                @click="search()"
              >
                ค้นหา
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 5px; padding: 5px">      
      <!-- <div v-for="(l, i) in list" :key="i">
      {{ l.code }} {{ l.name }} -->
      <table class="table table-bordered" v-if="pageOfItems.length > 0">
        <thead>
          <tr class="table-active">
            <!-- <th scope="col">ลำดับที่</th> -->
            <th scope="col">ปีที่เสียภาษี</th>
           <!-- <th scope="col">ชื่อ-นามสกุล</th> -->
           <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in pageOfItems" :key="i">
            <!-- <th scope="row" :class="d.class">
              <a :href="'cars/' + d.car_id"> {{ d.no }}</a>
            </th> -->
            <!-- <td :class="d.class">
              <a :href="'form/' + d.id"
              >
            </td> -->
            <td>
              <a>{{ d.year }}</a>
            </td> 
             <!-- <td>
              <a>{{ d.name }}</a>
            </td>             -->
             <td> <a :href="'taxation/' + d.id">
                <button type="button" class="btn btn-info">
                  <i class="fa fa-eye"></i></button
              ></a>&nbsp;
              <a></a></td>
          </tr>
        </tbody>
      </table>
      <div v-else style="text-align: center">ไม่พบข้อมูล</div>
    </div>
    <div class="col text-right mb-3" style="text-align:right">
      <jw-pagination
        :items="list"
        @changePage="onChangePage"
        :labels="customLabels"
      ></jw-pagination>
    </div>
    <div class="row mt-3 mb-3" >
      <div class="col-md-12 text-center">
        <button
        @click="$router.go(-1)"
          class="btn btn-warning"
          style="width: 200px"
        >
          ย้อนกลับ
        </button>
      </div>
    </div>
        <!-- Modal -->
<div class="modal fade" id="deletedata" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบ</h5>
            <button 
            id="closed" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" @click="deletedata()">
              ยืนยัน
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import CarService from "../services/CarService.js";
import CarTypesService from "../services/CarTypesService";
import TransportService from "../services/TransportService";
import MainPOService from "../services/MainPOService";
import ReceiptService from "../services/ReceiptService";
import TaxInvoiceService from "../services/TaxInvoiceService";

export default {
  name: "Carlist",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      car_types: [],
      license: "",
      owner: "",
      licenses: [],
      customLabels,
      pageOfItems: [],
      tax_id:''
    };
  },
  methods: {
    deletedata() {
       ReceiptService.deleteTaxation(this.tax_id).then(()=>{
        // console.log(res.data);
        document.getElementById("closed").click();
        this.getList()
       })
    },
    getid(id) {
      this.tax_id = id
    },
    updatestatusmain(id){
MainPOService.updatestatus(id).then(()=>{
  alert('บันทึกเรียบร้อยแล้ว')
  this.getList()
})
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getlicense(n) {
      this.license = n;
    },
    getLicense() {
      CarService.getLicense().then((res) => {
        // console.log(res.data);
        this.licenses = res.data;
      });
    },
    getList() {
      TaxInvoiceService.getTaxs().then((res) => {
        console.log(res.data);
        this.list = res.data;
        
      });
     
    },
    createreceipt(){ CarService.getCars('','').then((res)=>{
          console.log(res.data);
      var now = new Date()
            let year = now.getFullYear()+543
            year = String(year).substring(2, 4);
            // console.log(year);
          for (let r = 0; r < res.data.length; r++) {
            console.log((String(res.data[r].car_no)).length);
            let no = ''
            var car_no = String(r)
            if (car_no.length == 1) {
              no = year + '000'+car_no
            }else if (car_no.length == 2) {
              no = year + '00'+car_no
            }else if (car_no.length == 3) {
              no = year + '0'+car_no
            }else if (car_no.length == 4) {
              no = year+car_no
            }
            console.log(no);
            var d = {
              car_id:res.data[r].car_id,
              no:no,
              year:now.getFullYear()
            }
            ReceiptService.createReceipt(d).then(()=>{              
            })
          }        
        })
    },
    getcartypes() {
      CarTypesService.getCarTypes("").then((res) => {
        // console.log(res.data);
        this.car_types.push({
          car_type_id: 0,
          name: "ทั้งหมด",
        });
        for (let c = 0; c < res.data.length; c++) {
          this.car_types.push({
            car_type_id: res.data[c].car_type_id,
            name: res.data[c].code + " " + res.data[c].name,
          });
        }
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    search() {
      TransportService.getCarsTransports(this.owner, this.license).then(
        (res) => {
          console.log(res.data);
          this.list = [];
          this.list = res.data;
        }
      );
    },
  },
  mounted() {
    this.getList();
    this.getcartypes();
    this.getLicense();
    this.car_type = 0;
  },
  computed: {
    filteredList() {
      return this.licenses.filter((post) => {
        return post.license.toLowerCase().includes(this.license.toLowerCase());
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
  text-decoration: none;
}
#app {
  font-family: "Niramit";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
