<template>
  <div>
    <div class="container mt-3 mb-3">
      <div class="card mt-5" v-if="false">
        <div class="card-body">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="exampleFormControlInput1">
ผู้เดินรถร่วม</label>
              <input
                v-model="owner"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="ผู้เดินรถร่วม"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="exampleFormControlSelect1">เลขทะเบียนรถ</label>
              <input
                v-model="license"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="เลขทะเบียนรถ"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <!-- <button
            class="btn btn-warning"
            style="color: white; width: 200px"
            @click="back()"
          >
            ย้อนกลับ
          </button> -->
              &nbsp;
              <button
                class="btn btn-primary"
                style="color: white; width: 200px"
                @click="search()"
              >
                ค้นหา
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 5px; padding: 5px" >
      <div class="col text-right mb-3" style="text-align:right">
        <a href="transport/0">
          <button
            class="btn btn-success"
            style="color: white; background-color: #09cc68"
          >
            <i class="fa fa-plus"></i> เพิ่มข้อมูล
          </button></a
        >
      </div>
      <!-- <div v-for="(l, i) in list" :key="i">
      {{ l.code }} {{ l.name }} -->
      <table class="table table-bordered" v-if="pageOfItems.length > 0">
        <thead>
          <tr class="table-active">
            <!-- <th scope="col">ลำดับที่</th> -->
            <th scope="col">ผู้เดินรถร่วม</th>
            <th scope="col">เลขทะเบียนรถ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in pageOfItems" :key="i">
            <!-- <th scope="row" :class="d.class">
              <a :href="'cars/' + d.car_id"> {{ d.no }}</a>
            </th> -->            
            <td :class="d.class">
              <a :href="'transport/' + d.id">{{ d.one_contracts }} {{ d.car_type }}</a>
            </td>
            <td :class="d.class">
              <a :href="'transport/' + d.id">{{ d.license }}</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else style="text-align: center">ไม่พบข้อมูล</div>
    </div>
    <div class="col mb-3" style="text-align: right;">
       <jw-pagination
          :items="list"
          @changePage="onChangePage"
          :labels="customLabels"
        ></jw-pagination>
      </div>
  </div>
</template>

<script>

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import CarService from "../services/CarService.js";
import CarTypesService from "../services/CarTypesService";
import TransportService from "../services/TransportService";

export default {
  name: "Carlist",
  components: {
  },
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      car_types: [],
      license: "",
      owner: "",
      licenses: [],
      customLabels,
      pageOfItems: [],
    };
  },
  methods: {
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getlicense(n) {
      this.license = n;
    },
    getLicense() {
      CarService.getLicense().then((res) => {
        // console.log(res.data);
        this.licenses = res.data;
      });
    },
    getList() {
      TransportService.getCarsTransports('','').then((res) => {
        console.log(res.data);
        this.list = res.data;
      });
    },
    getcartypes() {
      CarTypesService.getCarTypes("").then((res) => {
        // console.log(res.data);
        this.car_types.push({
          car_type_id: 0,
          name: "ทั้งหมด",
        });
        for (let c = 0; c < res.data.length; c++) {
          this.car_types.push({
            car_type_id: res.data[c].car_type_id,
            name: res.data[c].code + " " + res.data[c].name,
          });
        }
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    search() {
      TransportService.getCarsTransports(this.owner,this.license).then((res) => {
        console.log(res.data);
        this.list = [];
        this.list = res.data;
      })
    },
  },
  mounted() {
    this.getList();
    this.getcartypes();
    this.getLicense();
    this.car_type = 0;
  },
  computed: {
    filteredList() {
      return this.licenses.filter((post) => {
        return post.license.toLowerCase().includes(this.license.toLowerCase());
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
  text-decoration: none;
}
#app {
  font-family: "Niramit";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
