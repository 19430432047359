import http from "../http-common";

class MainPOService {
  getMainPOs() {
    return http.get('/main_po');
  } 
  createMainFormPO(data) {
    return http.post('/main_po', data);
  }
  getMainPO(id) {
    return http.get(`/main_po/` + id);
  }
  updategetMainPO(id, data) {
    return http.put(`/main_po/${id}`, data);
  }  
  updatestatus(id) {
    return http.put(`/main_po/updatestatus/${id}`);
  }
}

export default new MainPOService();