<template>
  <div class="container mt-3">
    <div class="col text-right mb-3" style="text-align:right">
      <a @click="edit()" v-if="!statusedit">
        <button
          class="btn btn-warning"
        >
          <i class="fa fa-edit"></i> แก้ไขข้อมูล
        </button></a
      >
    </div>
    <div class="row mb-5" v-if="!statusedit">
      <!-- <div class="col-md-3">
        <label for="exampleFormControlInput1">ลำดับที่</label>
      </div>
      <div class="col-md-9">
        <label>{{ datas.no }}</label>
      </div> -->
      <div class="col-md-3">
        <label for="exampleFormControlInput1">ลำดับรถ</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.car_no }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">เงื่อนไข</label>
      </div>
      <div class="col-md-9">
        <label class="content" v-html="datas.symbols || '-'"></label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">ประเภทรถ</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.car_type || "-" }}</label>
      </div>      
      <div class="col-md-3">
        <label for="exampleFormControlInput1">ประเภทการวิ่งรถ</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.driving_type || "-" }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">เลขทะเบียนรถ</label>
      </div>
       <div class="col-md-9">
        <label class="content">{{ datas.license || "-" }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">ยี่ห้อรถ</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.brand || "-" }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">เลขตัวรถ</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.VIN || "-" }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">เลขเครื่องยนต์</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.engine_number || "-" }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">วันที่สิ้นอายุภาษี</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ convertdatetothai(datas.tax_expiration_date) }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">วันที่อนุมัติ</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ convertdatetothai(datas.approval_date) }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">ลักษณะ/มาตรฐาน (ช่าง)</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.characteristic || "-" }}</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">สถานะ</label>
      </div>
      <div class="col-md-9" v-if="datas.status == 1">
        <label class="content">ใช้งานอยู่</label>
        <label v-if="datas.status != 1">ไม่ใช้งาน</label>
      </div>
      <div class="col-md-3">
        <label for="exampleFormControlInput1">สถานะการต่อภาษี</label>
      </div>
      <div class="col-md-9">
        <label class="content">{{ datas.tax_status || "-" }}</label>
      </div>
 <div class="col-md-3">
        <label for="exampleFormControlInput1">รูปทะเบียนคู่มือรถ</label>
      </div>
      <div class="col-md-9">
        <img v-if="datas.image" :src="datas.image" width="50%">
        <label v-else class="content">ไม่พบข้อมูลรูปภาพ</label>
      </div>
      <div class="col-md-12" v-if="docs.length > 0">
        <table class="table mt-3">
          <thead>
            <tr>
              <th scope="col">ชื่อรูปภาพ</th>
               <th scope="col">รูปภาพ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(dd, i) in docs" :key="i + 1">
              <td>{{ dd.name }}</td>
              <td><img :src="dd.image" width="50%"></td>
              <!-- <td>
                <button
                  class="btn btn-warning"
                  data-toggle="modal"
                  data-target="#viewDocData"  
                  data-backdrop="static" data-keyboard="false"                
                  @click="getdocid(dd.image)"
                >
                  <i class="fa fa-eye" style="font-size: 12px"></i>
                </button>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-3 mt-3" v-if="docs.length == 0">
        <label for="exampleFormControlInput1">รูปภาพ</label>
      </div>
      <div class="col-md-9 mt-3" v-if="docs.length == 0">
        <label class="content">ไม่พบข้อมูลรูปภาพ</label>
      </div>
    </div>
    <div class="row mb-5" v-if="statusedit">
      <CarEdit />
    </div>
      <div
          class="modal fade"
          id="viewDocData"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
              <div><img :src="image" width="100%"></div>
            </div>
          </div>
        </div>
  </div>
</template>

<script src="https://api-sbpacdb.ponnipa.in.th/picker_date.js"></script>

<script>
import BrandService from "../services/BrandService.js";
import CharacteristicsService from "../services/CharacteristicsService";
import SymbolsService from "../services/SymbolsService";
import CarTypesService from "../services/CarTypesService";
import CarService from "../services/CarService.js";
import ConvertService from "../services/ConvertService";
import CarSymbolService from "../services/CarSymbolService";
import CarEdit from "./CarEdit.vue";

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  components: {
    CarEdit,
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: [],
      car_id: 0,
      image: "",
      itemPreviewImage: "",
      symbol: [],
      statusedit: false,
      docs:[]
    };
  },
  methods: {
    getdocid(image){
this.image = image
    },
    edit() {
      this.statusedit = true;
    },
    getbrands() {
      BrandService.getBrands().then((res) => {
        // console.log(res.data);
        this.brands = res.data;
      });
    },
    getcharacteristics() {
      CharacteristicsService.getCharacteristics().then((res) => {
        // console.log(res.data);
        this.characteristics = res.data;
      });
    },
    getsymbols() {
      SymbolsService.getSymbols().then((res) => {
        // console.log(res.data);
        this.symbols = res.data;
      });
    },
    getcartypes() {
      CarTypesService.getCarTypes('').then((res) => {
        // console.log(res.data);
        this.car_types = res.data;
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    save() {
      if (this.datas.car_type == null) {
        alert("กรุณาเลือกประเภทรถ");
      } else if (this.datas.license == null) {
        alert("กรุณากรอกเลขทะเบียนรถ");
      } else if (this.datas.brand == null) {
        alert("กรุณาเลือกยี่ห้อรถ");
      } else if (this.datas.VIN == null) {
        alert("กรุณากรอกเลขตัวรถ");
      } else if (this.datas.engine_number == null) {
        alert("กรุณากรอกเลขเครื่องยนต์");
      } else if (document.getElementById("tax_expiration_date").value == "") {
        alert("กรุณากรอกวันที่สิ้นอายุภาษี");
      } else if (document.getElementById("approval_date").value == "") {
        alert("กรุณากรอกวันอนุมัติ");
      } else if (this.datas.characteristic == null) {
        alert("กรุณาเลือกลักษณะ/มาตรฐาน (ช่าง)");
      } else {
        var car = {
          no: this.datas.no,
          car_no: this.datas.car_no,
          car_type: this.datas.car_type_id,
          license: this.datas.license,
          brand: this.datas.brand_id,
          VIN: this.datas.VIN,
          engine_number: this.datas.engine_number,
          tax_expiration_date: ConvertService.createddate(
            document.getElementById("tax_expiration_date").value
          ),
          approval_date: ConvertService.createddate(
            document.getElementById("approval_date").value
          ),
          characteristic: this.datas.characteristic_id,
          status: this.datas.status,
          image: this.image,
        };
        if (this.car_id != 0) {
          CarService.updateCar(this.car_id, car).then((res) => {
            console.log(res.data);
            CarSymbolService.deleteCarSymbol(this.car_id).then(() => {});
            if (this.symbol.length > 0) {
              for (let s = 0; s < this.symbol.length; s++) {
                var sym = {
                  car_id: this.car_id,
                  symbol_id: this.symbol[s],
                };
                CarSymbolService.createCarSymbol(sym).then(() => {});
              }
            }
            alert("บันทึกสำเร็จ");
          });
        } else {
          CarService.createCar(car).then((res) => {
            console.log(res.data);
            this.car_id = res.data.id;
            for (let s = 0; s < this.symbol.length; s++) {
              var sym = {
                car_id: this.car_id,
                symbol_id: this.symbol[s],
              };
              CarSymbolService.createCarSymbol(sym).then(() => {});
            }
            this.$router.push("/cars/" + this.car_id);
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 1000);
            window.scrollTo(0, 0);
          });
        }
      }
    },
    getcar() {
      CarSymbolService.getCarSymbol(this.car_id).then((res) => {
        if (res.data.symbol == null) {
          this.symbol = [];
        } else {
          this.symbol = JSON.parse(res.data.symbol);
        }
      });
      CarService.getCar(this.car_id).then((res) => {
        // console.log(res.data);
        this.datas = res.data;
        this.itemPreviewImage = this.datas.image;
      });
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.createImage(files[0]);
      // this.createBase64Image(selectedImage);
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.itemPreviewImage = evt.target.result;
        this.image = evt.target.result;
        this.docs.push({
          id: this.docs.length + 1,
          name : evt.target.name,
          image : this.image
        })
      };

      reader.readAsDataURL(file);
    },
    getDocs(){
      CarService.getImagesCar(this.car_id).then((res)=>{
        this.docs = res.data
        // console.log(this.docs);
      })
    }
  },
  mounted() {
    this.car_id = this.$route.params.id;
    // console.log(this.car_id);
    if (this.car_id != 0) {
      this.getcar();
      this.getDocs()
    } else {
      this.statusedit = true;
      this.datas.status = 1;
    }
    this.getbrands();
    this.getcharacteristics();
    this.getsymbols();
    this.getcartypes();
    picker_date(document.getElementById("tax_expiration_date"), {
      year_range: "-100:+0",
    });
    picker_date(document.getElementById("approval_date"), {
      year_range: "-100:+0",
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-weight: bold;
}
.content{
  font-weight: normal !important;
}
</style>
