<template>
  <div class="container mt-3">
    <div class="row">
    <div class="col-xl-6 col-lg-5">
     <CarTypeGraph />
    </div>
    <div class="col-xl-6 col-lg-5">
     <ExpireDateGraph />
    </div>
    <div class="col-xl-6 col-lg-5">
      <ExpireGraph />
    </div>   
    
    <div class="col-xl-6 col-lg-5">
      <NotYetTaxGraph />
    </div>
    
    <div class="col-xl-6 col-lg-5" v-if="false">
          <NormalGraph />
    </div>
    <div class="col-xl-6 col-lg-5">
          <DrivingTypeGraph />
    </div>
     <div class="col-xl-6 col-lg-5">
          <CarNullGraph />
    </div>
  </div>
  </div>
</template>

<script>
import CarTypeGraph from "./CarTypeGraph.vue";
import ExpireDateGraph from "./ExpireDateGraph.vue";
import ExpireGraph from "./ExpireGraph.vue";
import NormalGraph from "./NormalGraph.vue";
import NotYetTaxGraph from "./NotYetTaxGraph.vue";
import DrivingTypeGraph from "./DrivingTypeGraph.vue";
import CarNullGraph from "./CarNullGraph.vue";

export default {
  name: "Dashboard",
  components: {
    CarTypeGraph,
    ExpireDateGraph,
    ExpireGraph,
    NormalGraph,
    NotYetTaxGraph,
    DrivingTypeGraph,
    CarNullGraph,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div{
  font-size: 9px
}
</style>
