<template>
  <div class="container mt-3">  
    <div class="row col text-right" v-if="car_id == 0 && invoiceid == 0">
    <div class="col-md-6"></div>
     <div class="col-md-6">
      <div>
            <input
              v-model="license"
              type="text"
              class="form-control form-control-sm"
              id="exampleFormControlInput1"
              placeholder="เลขทะเบียนรถ"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            /><button
                class="btn btn-success btn-sm"
                type="button"
                @click="getLicense()"
              >
                ค้นหา
              </button>
            </div>
  </div>
  </div>
    <div
      v-if="editstatus"
      class="card mt-3 mb-5"
      style="padding-left: 1.5cm; background-color: white; padding-right: 1.5cm"
    >  
  <div style="width: 70%; margin: auto">
      <div class="row mt-5">
        <div class="col-sm-3">
          <p class="company">ห้างหุ้นส่วน</p>
          <p class="company">จ.โชคชัยหิรัญ</p>
          <p class="company">จำกัด</p>
          <p style="text-align: center">(สำนักงานใหญ่)</p>
        </div>
        <div class="col-sm-6">
          <p style="font-size: 15px; text-align: center">
            ใบกำกับภาษี/ใบเสร็จรับเงิน
          </p>
          <p style="font-size: 15px; text-align: center">TAX INVOICE/RECEIPT</p>
        </div>
        <div class="col-sm-3"><span id="text" style="font-size:16px;">เลขที่ {{datas.no}}</span></div>
      </div>
      <hr style="background-color: #1e1e1e; height: 2px; border: 2 #1e1e1e;margin:0px;width: 92%;margin-left: 20px;" />
      <div style="margin: 20px">
        <div class="row">
          <div class="col-sm-7">
            <div class="textstyle">
              <p>ที่อยู่</p>
              <div class="row">
                <div class="col-sm-6">
                  <p>เลขที่ 408 หมู่ 9</p>
                  <p>ตำบล เมืองพราน</p>
                  <p>จังหวัด เชียงราย</p>
                  <p>โทรศัพท์ 053-722010</p>
                </div>
                <div class="col-sm-6">
                  <p>ถนน ซุปเปอร์ไฮเวย์</p>
                  <p>อำเภอ พาน</p>
                  <p>รหัสไปรษณีย์ 57120</p>
                  <p>โทรสาร 053-722010</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-5" style="padding-left:0px">
            <div class="textstyle">
              <div class="row">
                <div class="col-sm-12">
                  <p style="text-align: center">เลขประจำตัวผู้เสียภาษี</p>
                  <p style="text-align: center"><input
              v-model="datas.id_card"
               @keypress="onlyNumberIDCard"
              type="text"
            /></p>
                </div>
              </div>
              <div class="row"> 
                <div class="col-sm-12" style="margin-left:25px;"><span>อัตราภาษี</span>
                 <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="true" v-model="datas.taxseven" />
                    <label class="form-check-label" style="margin-left:25px">ร้อยละ 7</label>
                  </div>
                   <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="true" v-model="datas.taxzero"/>
                    <label class="form-check-label" style="margin-left:25px">อัตราศูนย์</label>
                  </div>
                  </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p>ส่วนลด <input
              v-model="datas.discount"
              type="text"
            /></p>
                  <p>วันที่ <input
              v-model="datas.taxdate"
              type="text"
            /></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-12">
            <table style="width: 100%">
              <tr>
                <td>นาม <input style="width:70%"
              v-model="datas.name"
              type="text"
            /></td>
              </tr>
              <tr>
                <td>ที่อยู่ <input style="width:90%"
              v-model="datas.address"
              type="text"
            /></td>
              </tr>
              <tr>
                <td>
                  <div class="row">
                    <div class="col-sm-6" style="font-size:12px;">
                      <span>เลขประจำตัวผู้เสียภาษีอากร <input style="width:90%"
                       @keypress="onlyNumberIDCardTax"
              v-model="datas.id_card_tax"
              type="text"
            /></span>
                      </div>
                      <div class="col-sm-6" style="text-align:right">
                      <div class="form-check" style="margin-left:5px">
                            <input class="form-check-input" type="checkbox" value="true" v-model="datas.headquarter"/>
                            <label class="form-check-label" style="margin-left:25px">สำนักงานใหญ่</label>
                          </div><br/>
                          <div class="form-check" style="margin-left:5px">
                            <input class="form-check-input" type="checkbox"  value="true" v-model="datas.branch"/>
                            <label class="form-check-label" style="margin-left:25px"
                              >สาขา </label
                            ><input style="width:70%"
              v-model="datas.branchname"
              type="text"
            />
                          </div>
                      </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-12">
            <table style="width: 100%">
              <tr>
                <td style="width: 8%; text-align: center">ลำดับ</td>
                <td style="width: 55%;text-align: center">รายการ</td>
                <td style="width: 12%; text-align: center">จำนวน</td>
                <td style="width: 12%; text-align: center">ราคา</td>
                <td style="width: 15%; text-align: center">จำนวนเงิน</td>
              </tr>
              <tr v-for="(d,i) in description" :key="i">
                <td style="text-align: center">{{d.no}}</td>
                <td style="text-align: left"><input style="width:90%"
              v-model="d.name"
              type="text"
            /></td>
                <td style="text-align: center"><input 
              v-model="d.amount" style="width:70%"
              type="text"
            /></td>
               <td style="text-align: center"><input 
              v-model="d.price" style="width:70%"
              type="text"
            /></td><td style="text-align: center" v-if="(d.amount*d.price).toLocaleString() != 0">{{(d.amount*d.price).toLocaleString()}}</td>
              <td style="text-align: center" v-else></td>

              </tr>
              <tr>
                <td rowspan="2" colspan="2">
                  <p style="text-align: center;font-size:11px">
                    ใบเสร็จนี้จะสมบูรณ์ต่อเมื่อห้างฯ
                    ได้เรียกเก็บเงินตามเช็คได้เรียบร้อยแล้ว
                  </p>
                  <p style="text-align: center;font-size:11px">
                    และต้องมีลายเซ็นผู้รับเงิน
                    หรือผู้ได้รับมอบอำนาจของห้างฯเท่านั้น
                  </p>
                </td>
                <td colspan="2" style="text-align: right">รวมราคาสินค้า</td>
                <td></td>
              </tr>
              <tr>
                <td colspan="2" style="text-align: right">ภาษีมูลค่าเพิ่ม</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td colspan="2" style="text-align: center">
                  (<label class="form-check-label">{{convertnumbertotext(sumdata())}}</label>)
                </td>
                <td colspan="2" style="text-align: center">รวมทั้งสิ้น</td>
                <td style="text-align: right">{{sumdata().toLocaleString()}}</td>
              </tr>
              <tr>
                <td colspan="5" style="text-align: center">
                  <div class="row">
                    <div class="col-md-2" style="text-align: left;margin-left:5px">
                      <input class="form-check-input" type="checkbox" value="true" v-model="datas.cash"/>
                      <label class="form-check-label" style="margin-left: 25px;">เงินสด</label>
                    </div>
                    <div class="col-md-3" style="text-align: left;margin-left:5px">
                      <input class="form-check-input" type="checkbox" value="true" v-model="datas.checkstatus"/>
                      <label class="form-check-label" style="margin-left: 25px;"
                        >เช็คเลขที่ <input 
              v-model="datas.checkno" style="width:100%"
              type="text"
            /></label
                      ></div
                      ><div class="col-md-3" style="text-align: left;margin-left:5px">
                      <label class="form-check-label">ธนาคาร <input 
              v-model="datas.bank" style="width:100%"
              type="text"
            /></label></div>
            <div class="col-md-3" style="text-align: left;margin-left:5px">
                      <label class="form-check-label"
                        >ลงวันที่ <input 
              v-model="datas.signdate" style="width:100%"
              type="text"
            /></label
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <table style="width: 100%">
              <tr style="height:60px">
                <td style="width: 35%">
                  <div style="margin-top:20px">ผู้จ่ายเงิน..........</div>
                  <div style="text-align:center">(<input 
              v-model="datas.payer" style="width:80%"
              type="text"
            />)</div>
                </td>
                <td style="width: 30%">
                  <div style="margin-top:20px">ผู้ตรวจ..........</div>
                  <div style="text-align:center">(<input 
              v-model="datas.inspector" style="width:80%"
              type="text"
            />)</div>
                </td>
                <td style="width: 35%">
                  <div style="margin-top:20px"> ผู้รับเงิน..........</div>
                  <div style="text-align:center">(<input 
              v-model="datas.payee" style="width:80%"
              type="text"
            />)</div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div id="app" ref="document" v-else>
      <TaxInvoiceEdit />
	</div>

    <div class="row mt-3 mb-5">
      <div class="col-md-12 text-center">
        &nbsp;
        <button
        v-if="editstatus"
          class="btn btn-warning"
          style="color: white; width: 200px"
          @click="save()"
        >
          ออกใบเสร็จ
        </button>        
         <!-- <button
         v-else
          class="btn btn-warning"
          style="color: white; width: 200px"
          @click="edit()"          
        >
          แก้ไข
        </button> -->
        <button
         v-if="statusid == 1"
          class="btn btn-success"
          style="color: white; width: 200px"
          @click="successtax()"          
        >
          ดำเนินการเแล้วเสร็จ
        </button>
        &nbsp;
        <button
         v-if="statusid == 2 && !editstatus"
          class="btn btn-warning"
          style="color: white; width: 200px"
          @click="edit()"          
        >
          แก้ไข
        </button>
        &nbsp;
        <button
        v-if="statusid != 0 && !editstatus"
          class="btn btn-info"
          style="color: white; width: 200px"
          @click="exportToPDF()"
        >
          ปริ้นท์ไฟล์
        </button>
      </div>
    </div>
         <!-- Modal Delete-->
        <div
          class="modal fade"
          id="deleteDocData"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="editDataLabel">
                  ยืนยันการลบรูปภาพ
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="deleteDoc()"
                >
                  ยืนยัน
                </button>
                <button
                  type="button"
                  id="closedeletedoc"
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
  </div>
</template>

<script src="https://api-sbpacdb.ponnipa.in.th/picker_date.js"></script>

<script>
import VueHtml2pdf from "vue-html2pdf";
import ContentToPrint from "./ContentToPrint";

import CarService from "../services/CarService";
// import CarService from "../services/CarService.js";
// import ConvertService from "../services/ConvertService";
import CarsOwnersService from "../services/CarsOwnersService";
import TaxInvoiceEdit from "./TaxInvoiceEdit.vue";
import TransportService from "../services/TransportService";
import html2pdf from 'html2pdf.js'
import TaxInvoiceService from "../services/TaxInvoiceService";
import THBText from 'thai-baht-text' 

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  components: {
    TaxInvoiceEdit,
    VueHtml2pdf,
    ContentToPrint,
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: {},
      car_id: 0,
      image: "",
      itemPreviewImage: "",
      symbol: [],
    editstatus:false,
      tran_id: 0,
      filteredList:[],
      licenses:[],
      license:'',
      docs:[],
      docid:'',
      taxseven:'',
      taxzero:'',
      headquarter:'',
      branch:'',
      description:[],
      invoiceid:'',
      statusid:''
    };
  },
  methods: {
     getLicense() {
      if (this.license != '') {
        CarService.getLicenseOwner(this.license).then((res) => {
        console.log(res.data);
        if (res.data == '') {
          this.datas = {}
          this.car_id = 0
          alert('ไม่พบข้อมูลทะเบียนนี้ในระบบ')
        }else{
        this.car_id = res.data.car_id;
          this.getcar()
        }
      });
      }
      
    },
    sumdata(){
var total = 0,  //set a variable that holds our total
    i;
for (i = 0; i < this.description.length; i++) {  //loop through the array
    total += (this.description[i].amount*this.description[i].price); 
}
return total;  //display the result
    },
    convertnumbertotext(money){

    return THBText(money)
    },
    getdocid(id){
this.docid = id
    },
    deleteDoc() {
      // console.log(this.docid);
      for (let t = 0; t < this.docs.length; t++) {
        if (this.docs[t].id == this.docid) {
          this.docs.splice(t, 1);
          document.getElementById("closedeletedoc").click();
        }
      }
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.createImage(files[0]);
      // this.createBase64Image(selectedImage);
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.itemPreviewImage = evt.target.result;
        this.image = evt.target.result;
        this.docs.push({
          id: this.docs.length + 1,
          name : file.name,
          image : evt.target.result,
          width:0,
          height:0
        })
        console.log(this.docs);
      };

      reader.readAsDataURL(file);
    },
    edit(){
      this.editstatus = true;
      window.scrollTo(0, 0);
    },
    exportToPDF () {
      this.downloadstatus = true
				html2pdf(this.$refs.document, {
					margin: [0.5,0,0,0],
					filename: 'ใบเสร็จ'+this.datas.license+'.pdf',
					jsPDF: { unit: 'cm', format: 'a5', orientation: 'p' },
          pagebreak: {after: 'section'}
				})
			},
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    successtax(){
      console.log(this.datas);
       var taxstatus = {
        tax_status_id: 1,
      };
      CarService.updateTaxStatus(this.car_id, taxstatus).then(() => {
        this.$router.push('/receiptList/') 
      });
    },
    save() {
      console.log(this.datas);
      if (this.datas.id_card == null) {
        alert("กรุณากรอกเลขประจำตัวผู้เสียภาษี");
      } else if (this.datas.id_card.length != 13) {
        alert("กรุณากรอกเลขประจำตัวผู้เสียภาษีให้ครบ 13 หลัก");
      }else if (this.datas.name == null) {
        alert("กรุณากรอกนาม");
      }else if (this.datas.taxdate == null) {
        alert("กรุณากรอกวันที่");
      } else if (this.datas.address == null) {
        alert("กรุณากรอกที่อยู่");
      }  
      // else if (this.datas.id_card_tax == null) {
      //   alert("กรุณากรอกเลขประจำตัวผู้เสียภาษีอากร");
      // }
      // else if (this.datas.id_card_tax.length != 13) {
      //   alert("กรุณากรอกเลขประจำตัวผู้เสียภาษีอากรให้ครบ 13 หลัก");
      // }
      // else if (this.datas.headquarter == false && this.datas.branch == false) {
      //   alert("กรุณาเลือกสำนักใหญ่หรือสาขา");
      // } else if (this.datas.branch == true && this.datas.branchname == '') {
      //     alert("กรุณากรอกชื่อสาขา");
      // }else if (this.datas.cash == false && this.datas.checkstatus == false) {
      //   alert("กรุณาเลือกเงินสดหรือเช็ค");
      // } else if (this.datas.checkstatus == true && this.datas.checkno == '') {
      //    alert("กรุณากรอกเลขที่เช็ค");         
      // }else if (this.datas.checkstatus == true && this.datas.bank == '') {
      //    alert("กรุณากรอกธนาคารของเช็ค");         
      // }else if (this.datas.checkstatus == true && this.datas.signdate == '') {
      //    alert("กรุณากรอกวันที่ของเช็ค");         
      // } 
      else {
        var tax = {
          // car_owner_id: this.car_id,
          car_id:this.datas.car_id,
          license:this.datas.license,
          no: this.datas.no,
          number: this.datas.car_no,
          id_card: this.datas.id_card,
          taxseven: this.datas.taxseven,
          taxzero: this.datas.taxzero,
          discount: this.datas.discount,
          taxdate: this.datas.taxdate,
          name: this.datas.name,
          address: this.datas.address,
          id_card_tax:this.datas.id_card_tax,
          headquarter: this.datas.headquarter,
          branch: this.datas.branch,
          branchname: this.datas.branchname,
          textsum:this.convertnumbertotext(this.sumdata()),
          cash: this.datas.cash,
          checkstatus: this.datas.checkstatus,
          checkno: this.datas.checkno,
          bank: this.datas.bank,
          signdate: this.datas.signdate,
          payer: this.datas.payer,
          inspector: this.datas.inspector,
          payee: this.datas.payee,
          year: this.datas.year,
        };
        console.log(tax);
        if (this.invoiceid != 0) {
          TaxInvoiceService.updateTaxInvoice(this.invoiceid, tax).then((res) => {
            console.log(res.data);
            TaxInvoiceService.deleteListTax(this.invoiceid).then(()=>{              
            if (this.description.length > 0){
              for (let d = 0; d < this.description.length; d++) {
                if (this.description[d].name != '') {
                  var des ={
                  tax_id:this.invoiceid,
                  no:this.description[d].no,
                  name:this.description[d].name,
                  amount:this.description[d].amount,
                  price:this.description[d].price,
                }
                TaxInvoiceService.createListTax(des).then(()=>{

                })
                }                
              }
            }
           
            this.$router.push("/taxinvoice/" +this.invoiceid + '/1');
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 300);
            window.scrollTo(0, 0);
            })
            });
        } else {
          TaxInvoiceService.createTaxInvoice(tax).then((res) => {
            this.invoiceid = res.data.id
            if (this.description.length > 0){
              for (let d = 0; d < this.description.length; d++) {
                if (this.description[d].name != '') {
                  var des ={
                  tax_id:this.invoiceid,
                  no:this.description[d].no,
                  name:this.description[d].name,
                  amount:this.description[d].amount,
                  price:this.description[d].price,
                }
                TaxInvoiceService.createListTax(des).then(()=>{

                })
                } 
              }
            }
            this.$router.push("/taxinvoice/" +this.invoiceid + '/1');
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 300);
            window.scrollTo(0, 0);
          });
        }    
      }
    },
    getcar() {
      TaxInvoiceService.getTaxInvoice(this.invoiceid).then((res)=>{
        // console.log(res.data);
        this.datas = res.data;
        let year = new Date()
        year = year.getFullYear()+543
      TaxInvoiceService.getLastTaxInvoices(year).then((res)=>{
          var no = res.data.number
          if (!no) {
            no = 0
          }
          // console.log(res.data);
          
        var y = String(year).substring(2, 4);
        var car_no = String(no+1);
        if (car_no.length == 1) {
              no = y + "000" + car_no;
            } else if (car_no.length == 2) {
              no = y + "00" + car_no;
            } else if (car_no.length == 3) {
              no = y + "0" + car_no;
            } else if (car_no.length == 4) {
              no = y + car_no;
            }
            // console.log(no);
            this.datas.no = no
             this.datas.car_no = car_no
             this.datas.year = year
this.datas.taxseven = false
this.datas.taxzero = false
this.datas.headquarter = false
this.datas.branch = false
this.datas.branchname = null
this.datas.cash = false
this.datas.checkstatus = false
this.datas.checkno = null
this.datas.bank = null 
// console.log(this.datas);
this.getlist()
      });
        });
        
    },
    getdesc(){

this.description = []
console.log(this.datas);
if (this.datas.length == 0) {
  this.datas.license = ''
}
    for (let l = 0; l < 7; l++) {
      if (l+1 == 1) {
        this.description.push({
      no:l+1,
      name:'ค่าเข้าร่วมประกอบการ,ทะเบียน ' + this.datas.license,
      amount:1,
      price:1500,
      sum:1500
    })
      }else{
        this.description.push({
      no:l+1,
      name:'',
      amount:'',
      price:'',
      sum:''
    })
      }      
    }
        console.log(this.datas);
    },
    onlyNumberIDCard($event){
let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57 || this.datas.id_card.length == 13) {
        // 46 is dot
        $event.preventDefault();
      }
    },
     onlyNumberIDCardTax($event){
let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57 || this.datas.id_card_tax.length == 13) {
        // 46 is dot
        $event.preventDefault();
      }
    },
     getlist(){
      TaxInvoiceService.getListTax(this.invoiceid).then((res)=>{
        // console.log(res.data);
this.description= res.data
if (this.description.length != 7) {
  var count = 7-this.description.length
  var no = this.description.length
  for (let d = 0; d < count; d++) {
    no = no+1
    this.description.push({
      no:no,
      name:'',
      amount:'',
      price:'',
      sum:''
    })    
  }
}
      })
    },
    gettax() {
      TaxInvoiceService.getTaxInvoice(this.invoiceid).then((res)=>{
      // console.log(res.data);
      this.datas = res.data
      this.car_id = this.datas.car_id
      this.getlist()
      });
    },
    searchlicense() {
      this.filteredList = []
      this.filteredList = this.licenses.filter((post) => {
        return post.license.toLowerCase().includes(this.datas.license.toLowerCase());
      });
    },
  },
  mounted() {
    this.invoiceid = this.$route.params.id;
    this.statusid = this.$route.params.statusid
    // console.log(this.invoiceid);
    // console.log(this.statusid);
    if (this.statusid == 0) {      
      this.editstatus = true;
      this.getcar()
     }else{
      this.editstatus = false;
      this.gettax()
    }
    // if (this.invoiceid != 0) {
    //   this.gettax();
    //   this.getlist()
    //   // console.log(this.datas);
    //   // TransportService.getTransport(this.car_id).then((res) => {
    //   //   // console.log(res.data);
    //   //   if (res.data.length == 0) {
    //   //     this.tran_id = 0;
    //   //     this.getcar();
    //   //   } else {
    //   //     this.tran_id = res.data.id;
    //   //     this.datas = res.data;
    //   //      this.datas.sub_district_name = res.data.subdistrict_id
    //   //      this.datas.district_name = res.data.district_id
    //   //      this.datas.province_name = res.data.province_id
    //   //   }
    //   // });
    // } else {
    //   this.getcar()
        
    //   this.editstatus = true;
    //   this.datas.status = 1;
    // }    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input {
  margin: 5px;
  font-size: 14px;
  /* font-family:Angsana New !important; */
  font-family: 'Niramit';
}
label {
   font-size: 14px;
  font-weight: bold;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
   color: black;
}
span {
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
   color: black;
}
body {
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  font-size: 14px; 
   color: black;
}
div.aa {
  text-align: justify;
  /* font-family:Angsana New; */
   color: black;
   font-family: 'Niramit';
}
p {
  text-indent: 25px;
  text-align: justify;
  margin-bottom: 0px;
  font-size: 14px;
  /* font-family:Angsana New; */
  color: black;
  font-family: 'Niramit';
}
.textstyle {
  border-radius: 25px;
  border: 2px solid;
  padding: 5px;
  width: 100%;
  height: 170px;
}
table,
th,
td {
  border: 0.5px solid black;
  border-collapse: collapse;
  font-size: 14px;
}
tr {
  height: 30px;
  font-size: 14px;
}
#text {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.company {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
label {
  font-weight: normal !important;
}
.form-check {
    display: inline!important;
    padding-left: 5px;
}
</style>
