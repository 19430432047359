<template>
  <div>
    <div style="margin: 5px; padding: 5px">
      <table class="table table-bordered" style="width:200px;" v-if="pageOfItems.length > 0">
        <thead>
          <tr class="table-active">
            <!-- <th scope="col">ลำดับที่</th> -->
           <th scope="col">ลำดับรถ</th>           
            <th scope="col">ประเภทรถ</th>
             <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in pageOfItems" :key="i">
            <th scope="row">
              <a
                data-toggle="modal"
                style="color: black"
                >{{ d.car_no }}</a
              >
            </th>
             <th scope="row">
              <a
                data-toggle="modal"
                style="color: black"
                >{{ d.code }} {{ d.car_type }}</a
              >
            </th>
            <td> <a :href="'/caradd/' + d.car_id">
                <button type="button" class="btn btn-success">
                  <i class="fa fa-plus"></i></button
              ></a></td>
          </tr>
        </tbody>
      </table>
      <div v-else style="text-align: center">ไม่พบข้อมูล</div>
    </div>
    <div class="col text-right mb-3" style="text-align:right">
       <jw-pagination
          :items="list"
          @changePage="onChangePage"
          :labels="customLabels"
        ></jw-pagination>
      </div>

    <div
      class="modal fade"
      id="editdata"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editdata"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ข้อมูลการต่อทะเบียน</h5>
            <button
              type="button"
              id="close"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 mt-2">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >สถานะการต่อทะเบียน
                    <span style="color: red"> *</span></label
                  >
                  <select
                    v-model="tax_status_id"
                    class="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option
                      v-for="(s, i) in tax_status"
                      v-bind:value="s.tax_status_id"
                      :key="i + 1"
                    >
                      {{ s.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-8"></div>
            </div>
          </div>
          <div class="row mt-3 mb-5">
            <div class="col-md-12 text-center">
              &nbsp;
              <button
                class="btn btn-success"
                style="color: white; width: 200px"
                @click="save()"
              >
                บันทึก
              </button>
              &nbsp;
              <!-- <button
              class="btn btn-danger"
              style="color: white; width: 200px"
              @click="next()"
            >
              ลบ
            </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import CarService from "../services/CarService.js";
import CarTypesService from "../services/CarTypesService";

export default {
  name: "Carlist",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      car_types: [],
      car_type: "",
      name: {},
      count: "",
      car_id: "",
      tax_status_id: "",
      tax_status: [],
      year:'',
      customLabels,
      pageOfItems: [],
    };
  },
  methods: {
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getTaxStatus() {
      CarTypesService.getTaxStatus().then((res) => {
        this.tax_status = res.data;
      });
    },
    getList() {
      CarService.getCarsNull("", "").then((res) => {
        // console.log(res.data);
        this.list = res.data;
      });
    },
    getCount() {
      CarService.getCarsNull("", "").then((res) => {
        // console.log(res.data);
        this.count = res.data[0].count;
      });
    },
    getType() {
      CarTypesService.getCarType(this.car_type).then((res) => {
        this.name = res.data;
      });
    },
    getcartypes() {
      CarTypesService.getCarTypes("").then((res) => {
        console.log(res.data);
        this.car_types.push({
          car_type_id: 0,
          name: "ทั้งหมด",
        });
        for (let c = 0; c < res.data.length; c++) {
          this.car_types.push({
            car_type_id: res.data[c].car_type_id,
            name: res.data[c].code + " " + res.data[c].name,
          });
        }
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
  },
  mounted() {
    this.getList();
    this.getType();
    this.getCount();
    this.getTaxStatus();
    var date = new Date()
    this.year = date.getFullYear() + 543
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
  text-decoration: none;
}
</style>
