<template>
  <div>
    <div style="margin: 5px; padding: 5px">
      <p
        class="mt-3 mb-5"
        style="text-align: left; font-size: 20px; font-weight: bold"
      >
        {{ name.code }} {{ name.name }}
        <span style="float: right"> จำนวน {{ count }} คัน </span>
      </p>
      <!-- <div v-for="(l, i) in list" :key="i">
      {{ l.code }} {{ l.name }} -->
      <table class="table table-bordered" v-if="pageOfItems.length > 0">
        <thead>
          <tr class="table-active">
            <!-- <th scope="col">ลำดับที่</th> -->
            <th scope="col">ลำดับรถ</th>
            <th scope="col">เงื่อนไข</th>
            <!-- <th scope="col">ประเภทรถ</th> -->
            <th scope="col">เลขทะเบียนรถ</th>
            <th scope="col">ยี่ห้อรถ</th>
            <th scope="col">เลขตัวรถ</th>
            <th scope="col">เลขเครื่องยนต์</th>
            <th scope="col">วันที่สิ้นอายุภาษี</th>
            <th scope="col">วันอนุมัติ</th>
            <th scope="col">ลักษณะ/มาตรฐาน(ช่าง)</th>
            <th scope="col">สถานะ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in pageOfItems" :key="i">
            <!-- <th scope="row">
              <a :href="'/cars/' + d.car_id">{{ d.no }}</a>
            </th> -->
            <th scope="row">
              <a :href="'/cars/' + d.car_id">{{ d.car_no }}</a>
            </th>
            <td>
              <a :href="'/cars/' + d.car_id"
                ><span v-html="d.symbols"></span
              ></a>
            </td>
            <!-- <td>
              <a :href="'/cars/' + d.car_id">{{ d.code }} {{ d.car_type }}</a>
            </td> -->
            <td>
              <a :href="'/cars/' + d.car_id">{{ d.license }}</a>
            </td>
            <td>
              <a :href="'/cars/' + d.car_id">{{ d.brand }}</a>
            </td>
            <td>
              <a :href="'/cars/' + d.car_id">{{ d.VIN }}</a>
            </td>
            <td>
              <a :href="'/cars/' + d.car_id">{{ d.engine_number }}</a>
            </td>
            <td>
              <a :href="'/cars/' + d.car_id">{{
                convertdatetothai(d.tax_expiration_date)
              }}</a>
            </td>
            <td>
              <a :href="'/cars/' + d.car_id">{{
                convertdatetothai(d.approval_date)
              }}</a>
            </td>
            <td>
              <a :href="'/cars/' + d.car_id">{{ d.characteristic }}</a>
            </td>
            <td>
              <a v-if="d.status == 1" :href="'/cars/' + d.car_id">ใช้งานอยู่</a>
              <a v-else :href="'/cars/' + d.car_id">ไม่ใช้งาน</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else style="text-align: center">ไม่พบข้อมูล</div>
    </div>
    <div class="col text-right mb-3" style="text-align:right">
       <jw-pagination
          :items="list"
          @changePage="onChangePage"
          :labels="customLabels"
        ></jw-pagination>
      </div>
  </div>
</template>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};
import CarService from "../services/CarService.js";
import CarTypesService from "../services/CarTypesService";

export default {
  name: "Carlist",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      car_types: [],
      car_type: "",
      name: {},
      count: "",
      customLabels,
      pageOfItems: [],
    };
  },
  methods: {
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getList() {
      CarService.getCars("", this.car_type).then((res) => {
        console.log(res.data);
        this.list = res.data;
      });      
    },
    getCount() {
      CarService.getCarGroupType(this.car_type).then((res) => {
        console.log(res.data);
        this.count = res.data[0].count;
      });
    },
    getType() {
      CarTypesService.getCarType(this.car_type).then((res) => {
        this.name = res.data;
      });
    },
    getcartypes() {
      CarTypesService.getCarTypes("").then((res) => {
        console.log(res.data);
        this.car_types.push({
          car_type_id: 0,
          name: "ทั้งหมด",
        });
        for (let c = 0; c < res.data.length; c++) {
          this.car_types.push({
            car_type_id: res.data[c].car_type_id,
            name: res.data[c].code + " " + res.data[c].name,
          });
        }
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
  },
  mounted() {
    this.car_type = this.$route.params.id;
    console.log(this.car_type);
    this.getList();
    this.getType();
    this.getCount();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
  text-decoration: none;
}
</style>
