<template>
  <div>
    <div class="container mt-3 mb-3">
<!-- <div @click="generatePDF()" class="np-btn">Generate PDF</div>
<vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="nightprogrammerpdf"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="10"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <ContentToPrint />
      </section>
    </vue-html2pdf>
    <div>
      <ContentToPrint />
    </div> -->

 
      <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="exampleFormControlInput1">เลขทะเบียนรถ</label>
              <input
                v-model="license"
                type="text"
                class="form-control form-control-sm"
                id="exampleFormControlInput1"
                placeholder="เลขทะเบียนรถ"
              />
              <div v-if="license != '' && filteredList.length > 1">
                <ul
                  class="list-group"
                  v-for="(s, i) in filteredList"
                  v-bind:value="s.car_id"
                  :key="i + 1"
                >
                  <div
                    class="
                      form-control form-control-sm
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    style="border-color: blue"
                  >
                    <a href="#search" @click="getlicense(s.license)">
                      {{ s.license }}</a
                    >
                  </div>
                </ul>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="exampleFormControlSelect1">ประเภทรถ</label>
              <select
                v-model="car_type"
                class="form-control form-control-sm"
                id="exampleFormControlSelect1"
              >
                <option
                  v-for="(s, i) in car_types"
                  v-bind:value="s.car_type_id"
                  :key="i + 1"
                >
                  {{ s.code }} {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <!-- <button
            class="btn btn-warning"
            style="color: white; width: 200px"
            @click="back()"
          >
            ย้อนกลับ
          </button> -->
              &nbsp;
              <button
                class="btn btn-primary mt-3 btn-sm"
                style="color: white; width: 200px"
                @click="search()"
              >
                ค้นหา
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 5px; padding: 5px">
      <div class="col mb-3" style="text-align:right">
        <a href="cars/0">
          <button
            class="btn btn-success"
            style="color: white; background-color: #09cc68"
          >
            <i class="fa fa-plus"></i> เพิ่มข้อมูล
          </button></a
        >
      </div>
      <!-- <div v-for="(l, i) in list" :key="i">
      {{ l.code }} {{ l.name }} -->
      <table class="table table-bordered" v-if="pageOfItems.length > 0">
        <thead>
          <tr class="table-active">
            <!-- <th scope="col">ลำดับที่</th> -->
            <th scope="col">ลำดับรถ</th>
            <th scope="col">เงื่อนไข</th>
            <th scope="col">ประเภทรถ</th>
            <th scope="col">เลขทะเบียนรถ</th>
            <th scope="col">ยี่ห้อรถ</th>
            <th scope="col">เลขตัวรถ</th>
            <th scope="col">เลขเครื่องยนต์</th>
            <th scope="col">วันที่สิ้นอายุภาษี</th>
            <th scope="col">วันอนุมัติ</th>
            <th scope="col">ลักษณะ/มาตรฐาน(ช่าง)</th>
            <th scope="col">สถานะ</th>
             <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in pageOfItems" :key="i">
            <!-- <th scope="row" :class="d.class">
              <a :href="'cars/' + d.car_id"> {{ d.no }}</a>
            </th> -->
            <th scope="row" :class="d.class">
              <a :href="'cars/' + d.car_id">{{ d.car_no }}</a>
            </th>
            <td :class="d.class">
              <a :href="'cars/' + d.car_id"><span v-html="d.symbols"></span></a>
            </td>
            <td :class="d.class">
              <a :href="'cars/' + d.car_id">{{ d.code }} {{ d.car_type }}</a>
            </td>
            <td :class="d.class">
              <a :href="'cars/' + d.car_id">{{ d.license }}</a>
            </td>
            <td :class="d.class">
              <a :href="'cars/' + d.car_id">{{ d.brand }}</a>
            </td>
            <td :class="d.class">
              <a :href="'cars/' + d.car_id">{{ d.VIN }}</a>
            </td>
            <td :class="d.class">
              <a :href="'cars/' + d.car_id">{{ d.engine_number }}</a>
            </td>
            <td :class="d.class">
              <a :href="'cars/' + d.car_id">{{
                convertdatetothai(d.tax_expiration_date)
              }}</a>
            </td>
            <td :class="d.class">
              <a :href="'cars/' + d.car_id">{{
                convertdatetothai(d.approval_date)
              }}</a>
            </td>
            <td :class="d.class">
              <a :href="'cars/' + d.car_id">{{ d.characteristic }}</a>
            </td>
            <td :class="d.class">
              <a v-if="d.status == 1" :href="'cars/' + d.car_id">ใช้งานอยู่</a>
              <a v-else :href="'cars/' + d.car_id">ไม่ใช้งาน</a>
            </td>
            <td>
              <a> 
                <button
                @click="getid(d.car_id)"
                  data-toggle="modal" 
                  data-target="#updatestatus"
                  type="button"
                  class="btn btn-danger"
                >
                  <i class="fa fa-trash"></i></button
              ></a></td>
          </tr>
        </tbody>
      </table>
      <div v-else style="text-align: center">ไม่พบข้อมูล</div>
    </div>
    <div class="col mb-3" style="text-align: right;">
       <jw-pagination
          :items="list"
          @changePage="onChangePage"
          :labels="customLabels"
        ></jw-pagination>
      </div>
      
       <!-- Modal -->
<div class="modal fade" id="updatestatus" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการยกเลิก</h5>
             <button id="updatestatus" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button></div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" @click="deletedata()">
              ยืนยัน
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import VueHtml2pdf from "vue-html2pdf";
// import ContentToPrint from "./ContentToPrint";

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import CarService from "../services/CarService.js";
import CarTypesService from "../services/CarTypesService";

export default {
  name: "Carlist",
  components: {
    // VueHtml2pdf,
    // ContentToPrint,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      car_types: [],
      license: "",
      car_type: "",
      licenses: [],
      customLabels,
      pageOfItems: [],
      car_id:''
    };
  },
  methods: {
    deletedata() {
       CarService.updateCarStatus(this.car_id).then(()=>{
        // console.log(res.data);
        document.getElementById("updatestatus").click();
        this.search()
       })
    },
    getid(id) {
      this.car_id = id
    },
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getlicense(n) {
      this.license = n;
    },
    getLicense() {
      CarService.getLicense().then((res) => {
        // console.log(res.data);
        this.licenses = res.data;
      });
    },
    getList() {
      CarService.getCars("", "").then((res) => {
        console.log(res.data);
        this.list = res.data;
        for (let l = 0; l < this.list.length; l++) {
          if (this.list[l].status_symbol == 1) {
            var date = new Date();
            var year = date.getFullYear();
            console.log(this.list[l].year);
            if (this.list[l].year == year || this.list[l].year == year - 1) {
              this.list[l].class = "table-warning";
            } else {
              if (this.list[l].year <= year - 2) {
                this.list[l].class = "table-danger";
              }
            }
          }
        }
        console.log(this.list);
      });
      // CarTypesService.getCarTypes().then((res) => {
      //   console.log(res.data);
      //   for (let ct = 0; ct < res.data.length; ct++) {
      //     this.list.push({
      //       car_type_id: res.data[ct].car_type_id,
      //       name: res.data[ct].name,
      //       code: res.data[ct].code,
      //     });
      //   }

      //   var detail = [];
      //   for (let l = 0; l < this.list.length; l++) {
      //     CarService.getCars(this.list[l].car_type_id).then((res) => {
      //       // console.log(res.data);
      //       detail = [];
      //       for (let re = 0; re < res.data.length; re++) {
      //         detail.push(res.data[re]);
      //       }
      //       this.list[l].detail = detail;
      //     });
      //   }
      //   // console.log(detail);
      //   // console.log(this.list);
      // });
    },
    getcartypes() {
      CarTypesService.getCarTypes("").then((res) => {
        console.log(res.data);
        this.car_types.push({
          car_type_id: 0,
          name: "ทั้งหมด",
        });
        for (let c = 0; c < res.data.length; c++) {
          this.car_types.push({
            car_type_id: res.data[c].car_type_id,
            name: res.data[c].code + " " + res.data[c].name,
          });
        }
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    search() {
      var type = "";
      if (this.car_type != 0) {
        type = this.car_type;
      }
      CarService.getCars(this.license, type).then((res) => {
        console.log(res.data);
        this.list = [];
        this.list = res.data;
        for (let l = 0; l < this.list.length; l++) {
          if (this.list[l].status_symbol == 1) {
            var date = new Date();
            var year = date.getFullYear();
            // console.log(this.list[l].year);
            if (this.list[l].year == year || this.list[l].year == year - 1) {
              this.list[l].class = "table-warning";
            } else {
              if (this.list[l].year <= year - 2) {
                this.list[l].class = "table-danger";
              }
            }
          }
        }
      });
    },
  },
  mounted() {
    this.getList();
    this.getcartypes();
    this.getLicense();
    this.car_type = 0;
  },
  computed: {
    filteredList() {
      return this.licenses.filter((post) => {
        return post.license.toLowerCase().includes(this.license.toLowerCase());
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
  text-decoration: none;
}
#app {
  font-family: "Niramit";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
