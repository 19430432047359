import http from "../http-common";

class CasrsOwnersService {
  getCarsOwners(license,owner) {
    return http.get('/cars_owners/getlist?license='+license+"&&owner="+owner);
  } 
  createCarOwner(data) {
    return http.post('/cars_owners', data);
  }
  getownerbycarid(id) {
    return http.get(`/cars_owners/getownerbycarid/` + id);
  }
  getCarOwner(id) {
    return http.get(`/cars_owners/` + id);
  }
  updateCarOwner(id, data) {
    return http.put(`/cars_owners/${id}`, data);
  }
  getCarAllByOwner(id) {
    return http.get(`/cars_owners/getcar/` + id);
  }
  deleteCarAllByOwner(id) {
    return http.delete(`/cars_owners/` + id);
  }
  deleteCaOwner(id) {
    return http.delete(`/cars_owners/delete/` + id);
  }
}

export default new CasrsOwnersService();