<template>
  <div>
    <div class="container mt-3 mb-3">
      <div class="card mt-5" v-if="false">
        <div class="card-body">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="exampleFormControlInput1"> ผู้เดินรถร่วม</label>
              <input
                v-model="owner"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="ผู้เดินรถร่วม"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="exampleFormControlSelect1">เลขทะเบียนรถ</label>
              <input
                v-model="license"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="เลขทะเบียนรถ"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <!-- <button
            class="btn btn-warning"
            style="color: white; width: 200px"
            @click="back()"
          >
            ย้อนกลับ
          </button> -->
              &nbsp;
              <button
                class="btn btn-primary"
                style="color: white; width: 200px"
                @click="search()"
              >
                ค้นหา
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 5px; padding: 5px">
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">ปีใบเสร็จ</label>
        <input
        :disabled="!receipt_id == 0"
          v-model="year"
          type="number"
          class="form-control form-control-sm"
          id="exampleFormControlInput1"
          placeholder="เลขเครื่องยนต์"
        />
      </div>
      <table class="table table-bordered" v-if="pageOfItems.length > 0">
        <thead>
          <tr class="table-active">
            <th scope="col" v-if="receipt_id == 0">
              <input
                @click="checktrue(statusinput)"
                type="checkbox"
                id="exampleCheck1"
              />
            </th>
            <th>เลขที่ใบเสร็จ</th>
            <th>เลขทะเบียนรถ</th>
            <th>ประเภทรถ</th>
            <th>วันที่สิ้นอายุภาษี</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in pageOfItems" :key="i">
            <th scope="row" v-if="receipt_id == 0">
              <input
                type="checkbox"
                v-model="d.statuscheck"
                :value="d.statuscheck"
                id="exampleCheck1"
              />
            </th>
            <td :class="d.class">
              <a>{{ d.no }}</a>
            </td>
            <td :class="d.class">
              <a>{{ d.license }}</a>
            </td>
            <td :class="d.class">
              <a>{{ d.car_type }}</a>
            </td>
            <td :class="d.class">
              {{ convertdatetothai(d.tax_expiration_date) }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else style="text-align: center">ไม่พบข้อมูล</div>
    </div>
    <div class="col text-right mb-3" style="text-align: right">
      <jw-pagination
        :items="list"
        @changePage="onChangePage"
        :labels="customLabels"
      ></jw-pagination>
    </div>
    <div class="row mt-3 mb-3" v-if="receipt_id == 0">
      <div class="col-md-12 text-center">
        <button
          class="btn btn-success"
          style="color: white; width: 200px"
          @click="save()"
        >
          บันทึก
        </button>
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import CarService from "../services/CarService.js";
import CarTypesService from "../services/CarTypesService";
import TransportService from "../services/TransportService";
import MainPOService from "../services/MainPOService";
import ReceiptService from "../services/ReceiptService";

export default {
  name: "Carlist",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      car_types: [],
      license: "",
      owner: "",
      licenses: [],
      customLabels,
      pageOfItems: [],
      statusinput: false,
      listcheck: [],
      year: "",
      receipt_id: "",
    };
  },
  methods: {
    save() {
      var now = new Date();
      let year = now.getFullYear() + 543;
      year = String(year).substring(2, 4);
      // console.log(year);
      if (this.receipt_id == 0) {
        var t = {
          name: "",
          year: this.year,
        };
        ReceiptService.createReceipt(t).then((res) => {
          this.receipt_id = res.data.id;
          var count = 1;
          for (let r = 0; r < this.list.length; r++) {
            if (this.list[r].statuscheck == true) {
              let no = "";
              console.log(count);
              var car_no = String(count);
              if (car_no.length == 1) {
                no = year + "000" + car_no;
              } else if (car_no.length == 2) {
                no = year + "00" + car_no;
              } else if (car_no.length == 3) {
                no = year + "0" + car_no;
              } else if (car_no.length == 4) {
                no = year + car_no;
              }
              count = count + 1;
              // console.log(no);
              var d = {
                receipt_id: this.receipt_id,
                car_id: this.list[r].car_id,
                no: no,
                year: now.getFullYear(),
              };
              ReceiptService.createReceipt(d).then(() => {});
            }
          }
          this.$router.push("/receipt/" + this.receipt_id);
          alert("บันทึกสำเร็จ");
          setTimeout(function () {
            location.reload();
          }, 1000);
          window.scrollTo(0, 0);
        });
      }
    },
    checktrue(status) {
      console.log(this.pageOfItems);
      this.list = [];
      console.log(this.list);
      if (status == false) {
        for (let li = 0; li < this.pageOfItems.length; li++) {
          this.pageOfItems[li].statuscheck = true;
        }
        this.statusinput = true;
      } else {
        for (let li = 0; li < this.pageOfItems.length; li++) {
          this.pageOfItems[li].statuscheck = false;
        }
        this.statusinput = false;
      }
      this.list = this.listcheck;
      // console.log(this.list);
    },
    updatestatusmain(id) {
      MainPOService.updatestatus(id).then(() => {
        alert("บันทึกเรียบร้อยแล้ว");
        this.getList();
      });
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getlicense(n) {
      this.license = n;
    },
    getLicense() {
      CarService.getLicense().then((res) => {
        // console.log(res.data);
        this.licenses = res.data;
      });
    },
    getList() {
      ReceiptService.getReceipts('').then((res) => {
        console.log(res.data);
        this.list = res.data;
        for (let l = 0; l < this.list.length; l++) {
          this.list[l].statuscheck = false;
        }
        this.statusinput = false;
        this.listcheck = this.list;
      });
    },
    getTax() {
      ReceiptService.getsubreceipt(this.receipt_id).then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
    createreceipt() {
      CarService.getCars("", "").then((res) => {
        console.log(res.data);
        var now = new Date();
        let year = now.getFullYear() + 543;
        year = String(year).substring(2, 4);
        // console.log(year);
        for (let r = 0; r < res.data.length; r++) {
          console.log(String(res.data[r].car_no).length);
          let no = "";
          var car_no = String(r);
          if (car_no.length == 1) {
            no = year + "000" + car_no;
          } else if (car_no.length == 2) {
            no = year + "00" + car_no;
          } else if (car_no.length == 3) {
            no = year + "0" + car_no;
          } else if (car_no.length == 4) {
            no = year + car_no;
          }
          console.log(no);
          var d = {
            car_id: res.data[r].car_id,
            no: no,
            year: this.year,
          };
          ReceiptService.createReceipt(d).then(() => {});
        }
      });
    },
    getcartypes() {
      CarTypesService.getCarTypes("").then((res) => {
        // console.log(res.data);
        this.car_types.push({
          car_type_id: 0,
          name: "ทั้งหมด",
        });
        for (let c = 0; c < res.data.length; c++) {
          this.car_types.push({
            car_type_id: res.data[c].car_type_id,
            name: res.data[c].code + " " + res.data[c].name,
          });
        }
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    search() {
      TransportService.getCarsTransports(this.owner, this.license).then(
        (res) => {
          console.log(res.data);
          this.list = [];
          this.list = res.data;
        }
      );
    },
  },
  mounted() {
    this.receipt_id = this.$route.params.id;
    console.log(this.receipt_id);
    if (this.receipt_id == 0) {
      this.getList();
    } else {
      this.getTax();
      ReceiptService.getReceipt(this.receipt_id).then((res)=>{
        this.year = res.data.year
      })
      // var year = String(this.year).substring(2, 4);
    }
    this.car_type = 0;
    this.year = new Date();
    this.year = this.year.getFullYear() + 543;
  },
  computed: {
    filteredList() {
      return this.licenses.filter((post) => {
        return post.license.toLowerCase().includes(this.license.toLowerCase());
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
  text-decoration: none;
}
#app {
  font-family: "Niramit";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
