import axios from "axios";

export default axios.create({
  // baseURL: "http://localhost:8080/api",

  baseURL : "https://api-jcrchiangrai.ponnipa.in.th/api",

  
  headers: {
    "Content-type": "application/json",
  }
});