import http from "../http-common";

class CarTypesService {
  getCarTypes(name) {
    return http.get('/car_types?name='+name);
  }
  getCarTypesReceipt(status) {
    return http.get('/car_types/getcartypebyreceipt?status='+status);
  }
  getCarType(id) {
    return http.get('/car_types/'+id);
  }
  getTaxStatus() {
    return http.get('/tax_status/');
  }
  getDrivingTypes(name) {
    return http.get('/driving_types?name='+name);
  }
  getDrivingType(id) {
    return http.get('/driving_types/'+id);
  }
}

export default new CarTypesService();