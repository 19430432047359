<template>
  <div>
    <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div
          class="
            card-header
            py-3
            d-flex
            flex-row
            align-items-center
            justify-content-between
          "
        >
          <h6 class="m-0 font-weight-bold text-primary">จำนวนปกติทั้งหมด {{all.toLocaleString()}} คัน</h6>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <Pie
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
        </div>
      </div>
    
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
import CarService from '../services/CarService.js'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: "BarChart",
  components: { Pie },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: function(point, event){
          console.log(event);
          console.log('event : ', event[0]['index']);
          console.log('point : ', point);
          if (event[0]['index'] == 0) {
            // this.$router.push("cartype/1100");
            window.open("carnormal/1", '_blank');
          }else if (event[0]['index'] == 1) {
            // this.$router.push("carnormal/1100");
            window.open("carnormal/2", '_blank');
          }else if (event[0]['index'] == 2) {
            // this.$router.push("carnormal/1100");
            window.open("carnormal/3", '_blank');
          }else if (event[0]['index'] == 3) {
            // this.$router.push("carnormal/1100");
            window.open("carnormal/4", '_blank');
          }else if (event[0]['index'] == 4) {
            // this.$router.push("carnormal/1100");
            window.open("carnormal/5", '_blank');
          }else if (event[0]['index'] == 5) {
            // this.$router.push("carnormal/1100");
            window.open("carnormal/6", '_blank');
          }else if (event[0]['index'] == 6) {
            // this.$router.push("carnormal/1100");
            window.open("carnormal/7", '_blank');
          }
        },
      },
      chartData: {},
      name: [],
      count: [],
      colour:[],
      mycolor: '#'+(Math.random()*0xFFFFFF<<0).toString(16),
      all:0
    };
  },
  mounted() {
    this.getgrouptype();
    this.getchart();
  },
  methods: {
    getgrouptype() {
      CarService.getCarGroupNormalAll("","").then((res) => {
        // console.log(res.data);
        for (let r = 0; r < res.data.length; r++) {
          this.name.push(res.data[r].name + ' '+res.data[r].count+ " คัน");
          this.count.push(res.data[r].count);
          this.colour.push(this.mycolor = '#'+(Math.random()*0xFFFFFF<<0).toString(16))
        }
        for (let c = 0; c < this.count.length; c++) {
          this.all = this.all + this.count[c]
          
        }
      });
    },
    getchart() {
      this.chartData = {
        labels: this.name,
        datasets: [
          {
            backgroundColor: ["#42ea6a","#e71d4b","#f3a1b7","#edfa1b","#597194","#782471","#ae548e"],
            data: this.count,
          },
        ],
      };
    },
  },
};
</script>