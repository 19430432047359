<template>
  <div id="app">
    <Nav msg="Welcome to Your Vue.js App"/>
    <router-view />
  </div>
</template>

<script>
import Nav from './components/Nav.vue'

export default {
  name: 'App',
  components: {
    Nav
  }
}
</script>

<style>
div{
  font-family: 'Niramit', sans-serif;
  font-size: 14px;
}
</style>