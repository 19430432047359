import http from "../http-common";

class AddressService {
  getProvinces() {
    return http.get(`/provinces`);
  }
  getDistricts(id) {
    return http.get(`/districts?id=` + id);
  }
  getSubDistricts(id) {
    return http.get(`/subdistricts?id=` + id);
  }
  getSubDistrict(id) {
    return http.get(`/subdistricts/` + id);
  }
}

export default new AddressService();