<template>
  <div class="container mt-3">
    <div class="row mb-5">
      <!-- <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">ลำดับที่</label>
        <input
          v-model="datas.no"
          type="number"
          class="form-control form-control-sm"
          id="exampleFormControlInput1"
          placeholder="ลำดับที่"
        />
      </div> -->
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">ลำดับรถ</label>
        <input
          v-model="datas.car_no"
          type="number"
          class="form-control form-control-sm"
          id="exampleFormControlInput1"
          placeholder="ลำดับรถ"
        />
      </div>
      <div class="col-md-6"></div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">เงื่อนไข</label>
        <div v-for="m in symbols" :key="m.symbol_id">
          <div class="form-group" style="text-align: left">
            <label>
              <input
                type="checkbox"
                v-model="symbol"
                :id="m.symbol_id"
                :value="m.symbol_id"
              />
              <span style="font-weight: normal">
                {{ m.symbol }} {{ m.name }}</span
              >
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-6"></div>
      
      <div class="form-group col-md-6">
        <label for="exampleFormControlSelect1">ประเภทรถ</label>
        <select
          v-model="datas.car_type_id"
          class="form-control form-control-sm"
          id="exampleFormControlSelect1"
        >
          <option
            v-for="(s, i) in car_types"
            v-bind:value="s.car_type_id"
            :key="i + 1"
          >
            {{ s.code }} {{ s.name }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlSelect1">ประเภทการวิ่งรถ</label>
        <select
          v-model="datas.driving_type_id"
          class="form-control form-control-sm"
          id="exampleFormControlSelect1"
        >
          <option
            v-for="(s, i) in driving_types"
            v-bind:value="s.driving_type_id"
            :key="i + 1"
          >
             {{ s.name }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">เลขทะเบียนรถ</label>
        <input
          v-model="datas.license"
          type="text"
          class="form-control form-control-sm"
          id="exampleFormControlInput1"
          placeholder="เลขทะเบียนรถ"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlSelect1">ยี่ห้อรถ</label>
        <select
          v-model="datas.brand_id"
          class="form-control form-control-sm"
          id="exampleFormControlSelect1"
        >
          <option
            v-for="(s, i) in brands"
            v-bind:value="s.brand_id"
            :key="i + 1"
          >
            {{ s.name }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">เลขตัวรถ</label>
        <input
          v-model="datas.VIN"
          type="text"
          class="form-control form-control-sm"
          id="exampleFormControlInput1"
          placeholder="เลขตัวรถ"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">เลขเครื่องยนต์</label>
        <input
          v-model="datas.engine_number"
          type="text"
          class="form-control form-control-sm"
          id="exampleFormControlInput1"
          placeholder="เลขเครื่องยนต์"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">วันที่สิ้นอายุภาษี</label>
        <input id="tax_expiration_date" class="form-control form-control-sm" />
      </div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">วันที่อนุมัติ</label>
        <input id="approval_date" class="form-control form-control-sm" />
      </div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlSelect1">ลักษณะ/มาตรฐาน (ช่าง)</label>
        <select
          v-model="datas.characteristic_id"
          class="form-control form-control-sm"
          id="exampleFormControlSelect1"
        >
          <option
            v-for="(s, i) in characteristics"
            v-bind:value="s.characteristic_id"
            :key="i + 1"
          >
            {{ s.name }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlSelect1">สถานะ</label>
        <select
          v-model="datas.status"
          class="form-control form-control-sm"
          id="exampleFormControlSelect1"
        >
          <option value="1">ใช้งานอยู่</option>
          <option value="0">ไม่ใช้งาน</option>
        </select>
      </div>
       <div class="form-group col-md-6">
        <label for="exampleFormControlSelect1">รูปทะเบียนคู่มือรถ</label>
         <input
          id="file"
          class="form-control form-control-sm"
          type="file"
          accept="image/*"
          @change="onFileChangeManual"
        />
      <img class="mt-3" v-if="datas.image" :src="datas.image" width="100%">
      </div>
         <div class="form-group col-md-12"></div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">รูปภาพ</label>
        <input
          id="file"
          class="form-control form-control-sm"
          type="file"
          accept="image/*"
          @change="onFileChange"
        />
         <table class="table mt-3">
          <thead>
            <tr>
              <th scope="col">ชื่อไฟล์</th>
              <th scope="col">รูปภาพ</th>
              <th scope="col">จัดการ</th>
            </tr>
          </thead>
          <tbody v-if="docs.length > 0">
            <tr v-for="(dd, i) in docs" :key="i + 1">
              <td><a :href="dd.path" target="_blank">{{ dd.name }}</a></td>
              <td><img :src="dd.image" width="50%"></td>
              <td>
                <button
                  class="btn btn-outline-danger"
                  data-toggle="modal"
                  data-target="#deleteDocData"  
                  data-backdrop="static" data-keyboard="false"                
                  @click="getdocid(dd.id)"
                >
                  <i class="fa fa-trash" style="font-size: 12px"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
     <!-- Modal Delete-->
        <div
          class="modal fade"
          id="deleteDocData"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="editDataLabel">
                  ยืนยันการลบรูปภาพ
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="deleteDoc()"
                >
                  ยืนยัน
                </button>
                <button
                  type="button"
                  id="closedeletedoc"
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>

       <div class="row mt-3 mb-5">
        <div class="col-md-12 text-center">
          <!-- <button
            class="btn btn-warning"
            style="color: white; width: 200px"
            @click="back()"
          >
            ย้อนกลับ
          </button> -->
          &nbsp;
          <button
            class="btn btn-success"
            style="color: white; width: 200px"
            @click="save()"
          >
            บันทึก
          </button>
          &nbsp;
        </div>
      </div>
  </div>
</template>

<script src="https://api-sbpacdb.ponnipa.in.th/picker_date.js"></script>

<script>
import BrandService from "../services/BrandService.js";
import CharacteristicsService from "../services/CharacteristicsService";
import SymbolsService from "../services/SymbolsService";
import CarTypesService from "../services/CarTypesService";
import CarService from "../services/CarService.js";
import ConvertService from "../services/ConvertService";
import CarSymbolService from "../services/CarSymbolService";

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: [],
      car_id: 0,
      image: "",
      itemPreviewImage: "",
      symbol: [],
      driving_types:[],
       docs:[],
       docid:''
    };
  },
  methods: {
    
    getdocid(id){
this.docid = id
    },
    deleteDoc() {
      // console.log(this.docid);
      for (let t = 0; t < this.docs.length; t++) {
        if (this.docs[t].id == this.docid) {
          this.docs.splice(t, 1);
          document.getElementById("closedeletedoc").click();
        }
      }
    },
    getbrands() {
      BrandService.getBrands().then((res) => {
        // console.log(res.data);
        this.brands = res.data;
      });
    },
    getcharacteristics() {
      CharacteristicsService.getCharacteristics().then((res) => {
        // console.log(res.data);
        this.characteristics = res.data;
      });
    },
    getsymbols() {
      SymbolsService.getSymbols().then((res) => {
        // console.log(res.data);
        this.symbols = res.data;
      });
    },
    getcartypes() {
      CarTypesService.getCarTypes('').then((res) => {
        // console.log(res.data);
        this.car_types = res.data;
      });
    },
    getdriving_type() {
      CarTypesService.getDrivingTypes('').then((res) => {
        // console.log(res.data);
        this.driving_types = res.data;
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    save() {
      if (this.datas.car_no == null) {
        alert("กรุณากรอกลำดับรถ");
      } 
      else if (this.datas.car_type_id == null) {
        alert("กรุณาเลือกประเภทรถ");
      } else if (this.datas.license == null) {
        alert("กรุณากรอกเลขทะเบียนรถ");
      } else if (this.datas.brand_id == null) {
        alert("กรุณาเลือกยี่ห้อรถ");
      } else if (this.datas.VIN == null) {
        alert("กรุณากรอกเลขตัวรถ");
      } else if (this.datas.engine_number == null) {
        alert("กรุณากรอกเลขเครื่องยนต์");
      } else if (document.getElementById("tax_expiration_date").value == "") {
        alert("กรุณากรอกวันที่สิ้นอายุภาษี");
      } else if (document.getElementById("approval_date").value == "") {
        alert("กรุณากรอกวันอนุมัติ");
      } else if (this.datas.characteristic_id == null) {
        alert("กรุณาเลือกลักษณะ/มาตรฐาน (ช่าง)");
      } else {
        var car = {
          no: this.datas.no,
          car_no:this.datas.car_no,
          car_type: this.datas.car_type_id,
          driving_type_id:this.datas.driving_type_id,
          license: this.datas.license,
          brand: this.datas.brand_id,
          VIN: this.datas.VIN,
          engine_number: this.datas.engine_number,
          tax_expiration_date: ConvertService.createddate(
            document.getElementById("tax_expiration_date").value
          ),
          approval_date: ConvertService.createddate(
            document.getElementById("approval_date").value
          ),
          characteristic: this.datas.characteristic_id,
          status: this.datas.status,
          image: this.image,
        };
        if (this.car_id != 0) {
          CarService.updateCar(this.car_id, car).then((res) => {
            console.log(res.data);
            CarSymbolService.deleteCarSymbol(this.car_id).then(() => {});
            if (this.symbol.length > 0) {
              for (let s = 0; s < this.symbol.length; s++) {
                var sym = {
                  car_id: this.car_id,
                  symbol_id: this.symbol[s],
                };
                CarSymbolService.createCarSymbol(sym).then(() => {});
              }
            }
            CarService.deleteImagesCar(this.car_id).then(()=>{})
            for (let im = 0; im < this.docs.length; im++) {
              var image = {
                car_id: this.car_id,
                name:this.docs[im].name,
                image:this.docs[im].image
              }               
            CarService.createImageCar(image).then(()=>{
            })             
            }
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
                    location.reload();
                  }, 1000);
                  window.scrollTo(0, 0);
          });
        } else {
          CarService.createCar(car).then((res) => {
            console.log(res.data);
            this.car_id = res.data.id
            for (let s = 0; s < this.symbol.length; s++) {
              var sym = {
                car_id: this.car_id,
                symbol_id: this.symbol[s],
              };
              CarSymbolService.createCarSymbol(sym).then(() => {});
            }
            for (let im = 0; im < this.docs.length; im++) {
              var image = {
                car_id: this.car_id,
                name:this.docs[im].name,
                image:this.docs[im].image
              }               
            CarService.createImageCar(image).then(()=>{
            })}         
            this.$router.push("/cars/" + this.car_id);
                  alert("บันทึกสำเร็จ");
                  setTimeout(function () {
                    location.reload();
                  }, 1000);
                  window.scrollTo(0, 0);
          });
        }
      }
    },
    getcar() {
      CarSymbolService.getCarSymbol(this.car_id).then((res) => {
        if (res.data.symbol == null) {
          this.symbol = [];
        } else {
          this.symbol = JSON.parse(res.data.symbol);
        }
      });
      CarService.getCar(this.car_id).then((res) => {
        // console.log(res.data);
        this.datas = res.data;
        this.itemPreviewImage = this.datas.image;
        if (this.datas.tax_expiration_date != null) {
          this.datas.tax_expiration_date = ConvertService.getdate(
            new Date(this.datas.tax_expiration_date)
          );
          document.getElementById("tax_expiration_date").value =
            this.datas.tax_expiration_date;
        }
        if (this.datas.approval_date != null) {
          this.datas.approval_date = ConvertService.getdate(
            new Date(this.datas.approval_date)
          );
          document.getElementById("approval_date").value =
            this.datas.approval_date;
        }
      });
    },
    onFileChangeManual(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.createImageManual(files[0]);
      // this.createBase64Image(selectedImage);
    },
     createImageManual(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.itemPreviewImage = evt.target.result;
        this.image = evt.target.result;
        this.datas.image= evt.target.result
      };

      reader.readAsDataURL(file);
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.createImage(files[0]);
      // this.createBase64Image(selectedImage);
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.itemPreviewImage = evt.target.result;
        this.image = evt.target.result;
        console.log(file);
        this.docs.push({
          id: this.docs.length + 1,
          name : file.name,
          image : evt.target.result
        })
      };

      reader.readAsDataURL(file);
    },
    getDocs(){
      CarService.getImagesCar(this.car_id).then((res)=>{
        this.docs = res.data
      })
    }
  },
  mounted() {
    this.car_id = this.$route.params.id;
    // console.log(this.car_id);
    if (this.car_id != 0) {
      this.getcar();
      this.getDocs()
    }else{
      this.datas.status = 1
    }
    this.getbrands();
    this.getcharacteristics();
    this.getsymbols();
    this.getcartypes();
    this.getdriving_type()
    picker_date(document.getElementById("tax_expiration_date"), {
      year_range: "-100:+0",
    });
    picker_date(document.getElementById("approval_date"), {
      year_range: "-100:+0",
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label{
  font-weight: bold;
}
.form-group{
margin-bottom: 1rem!important;
}
</style>
