<template>
  <div>
    <div style="margin: 5px; padding: 5px">
      <p
        class="mt-3 mb-5"
        style="text-align: left; font-size: 20px; font-weight: bold"
      >
        {{ name.code }} {{ name.name }} รถที่หมดอายุภาษีใน 3 เดือน
        <span style="float: right"> จำนวนทั้งหมด {{ count }} คัน </span>
      </p>
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4"><div class="form-inline">      
    <!-- <div class="form-group mb-2">
    <label for="staticEmail2" class="sr-only">ค้นหา</label>
    <input type="text" readonly class="form-control-plaintext" id="staticEmail2" value="ค้นหา">
  </div> -->
  <div class="form-group mb-2">
    <label for="inputPassword2" class="sr-only"
            >เลขทะเบียนรถ</label
          >
    <input
            v-model="license"
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="ค้นหาเลขทะเบียนรถ"
          />
  </div>&nbsp;&nbsp;
  <button
            class="btn btn-primary mb-2"
            style="color: white; width: 80px"
            @click="search()"
          >
            ค้นหา
          </button>
</div></div>
        <div class="col-md-4"></div>
      </div>
      <table class="table table-bordered mt-3" v-if="pageOfItems.length > 0">
        <thead>
          <tr class="table-active">
            <!-- <th scope="col">ลำดับที่</th> -->
            <th scope="col">ลำดับรถ</th>
            <th scope="col">เงื่อนไข</th>
            <!-- <th scope="col">ประเภทรถ</th> -->
            <th scope="col">เลขทะเบียนรถ</th>
            <th scope="col">ยี่ห้อรถ</th>
            <th scope="col">เลขตัวรถ</th>
            <th scope="col">เลขเครื่องยนต์</th>
            <th scope="col">วันที่สิ้นอายุภาษี</th>
            <!-- <th scope="col">วันอนุมัติ</th> -->
            <th scope="col">ลักษณะ/มาตรฐาน(ช่าง)</th>
            <!-- <th scope="col">สถานะ</th> -->
            <th scope="col">สถานะการต่อภาษี {{ year }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in pageOfItems" :key="i">
            <!-- <th scope="row">
              <a
                style="color: black"
                href="#"
                
                >{{ d.no }}</a
              >
            </th> -->
            <th scope="row">
              <a style="color: black" href="#">{{ d.car_no }}</a>
            </th>
            <td>
              <a style="color: black" href="#"
                ><span v-html="d.symbols"></span
              ></a>
            </td>
            <!-- <td>
              <a :href="'cars/' + d.car_id">{{ d.code }} {{ d.car_type }}</a>
            </td> -->
            <td>
              <a style="color: black" href="#">{{ d.license }}</a>
            </td>
            <td>
              <a style="color: black" href="#">{{ d.brand }}</a>
            </td>
            <td>
              <a style="color: black" href="#">{{ d.VIN }}</a>
            </td>
            <td>
              <a style="color: black" href="#">{{ d.engine_number }}</a>
            </td>
            <td>
              <a style="color: black" href="#">{{
                convertdatetothai(d.tax_expiration_date)
              }}</a>
            </td>
            <!-- <td>
              <a
                style="color: black"
                href="#"
                
                >{{ convertdatetothai(d.approval_date) }}</a
              >
            </td> -->
            <td>
              <a style="color: black" href="#">{{ d.characteristic }}</a>
            </td>
            <!-- <td>
              <a
                v-if="d.status == 1"
                style="color: black"
                href="#"
                
                >ใช้งานอยู่</a
              >
              <a
                v-else
                style="color: black"
                href="#"
                
                >ไม่ใช้งาน</a
              >
            </td> -->
            <td>
              <a style="color: black" href="#">{{ d.tax_status }}</a>
            </td>
            <td :class="d.class">
              <button
                class="btn btn-success"
                style="color: white; width: 200px"
                @click="updatestatusmain(d)"
              >
                ดำเนินการเแล้วเสร็จ
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else style="text-align: center">ไม่พบข้อมูล</div>
    </div>
    <div class="col text-right mb-3" style="text-align: right">
      <jw-pagination
        :items="list"
        @changePage="onChangePage"
        :labels="customLabels"
      ></jw-pagination>
    </div>

    <div
      class="modal fade"
      id="editdata"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editdata"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ข้อมูลการต่อทะเบียน</h5>
            <button
              type="button"
              id="close"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 mt-2">
                <div class="form-group" style="text-align: left">
                  <label for="username"
                    >สถานะการต่อทะเบียน
                    <span style="color: red"> *</span></label
                  >
                  <select
                    v-model="tax_status_id"
                    class="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option
                      v-for="(s, i) in tax_status"
                      v-bind:value="s.tax_status_id"
                      :key="i + 1"
                    >
                      {{ s.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-8"></div>
            </div>
          </div>
          <div class="row mt-3 mb-5">
            <div class="col-md-12 text-center">
              &nbsp;
              <button
                class="btn btn-success"
                style="color: white; width: 200px"
                @click="save()"
              >
                บันทึก
              </button>
              &nbsp;
              <!-- <button
              class="btn btn-danger"
              style="color: white; width: 200px"
              @click="next()"
            >
              ลบ
            </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import CarService from "../services/CarService.js";
import CarTypesService from "../services/CarTypesService";
import TaxInvoiceService from "../services/TaxInvoiceService";
import CarsOwnersService from "../services/CarsOwnersService";

export default {
  name: "Carlist",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      car_types: [],
      car_type: "",
      name: {},
      count: "",
      car_id: "",
      tax_status_id: "",
      tax_status: [],
      year: "",
      customLabels,
      pageOfItems: [],
      license: "",
    };
  },
  methods: {
    updatestatusmain(data) {
      console.log(data);
      var expiredate = new Date(data.tax_expiration_date);
      var year = expiredate.getFullYear();
      var month = expiredate.getMonth();
      var day = expiredate.getDate();
      var c = year + 1 + "-" + (month + 1) + "-" + day;
      console.log(c);
      var expire = {
        tax_expiration_date: c,
      };
      console.log(data);
      CarService.updateExpireCar(data.car_id, expire).then(() => {
        CarsOwnersService.getownerbycarid(data.car_id).then((res) => {
          console.log(res.data);
          var name = "";
          var address = "";
          if (res.data.one_contracts) {
            name = res.data.one_contracts;
          } else {
            name = "";
          }
          if (res.data.house_no) {
            address += res.data.house_no;
          } else {
            address += "";
          }
          if (res.data.moo) {
            address += " หมู่ " + res.data.moo;
          } else {
            address += "";
          }
          if (res.data.subdistrict_id) {
            address += " ต." + res.data.subdistrict_id;
          } else {
            address += "";
          }
          if (res.data.district_id) {
            address += " อ." + res.data.district_id;
          } else {
            address += "";
          }
          if (res.data.province_id) {
            address += " จ." + res.data.province_id;
          } else {
            address += "";
          }
          var tax = {
            car_id: data.car_id,
            license: data.license,
            name: name,
            address: address,
          };
          console.log(tax);
          TaxInvoiceService.createTaxInvoice(tax).then((res) => {
            console.log(res.data);
            var tax_id = res.data.id;
            var des = {
              tax_id: tax_id,
              no: 1,
              name: "ค่าเข้าร่วมประกอบการ,ทะเบียน " + data.license,
              amount:1,
              price:1500
            };
            TaxInvoiceService.createListTax(des).then(() => {
              this.$router.push("/taxinvoice/" + tax_id + "/0");
            });
          });
        });
      });
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    edit(id, status) {
      this.car_id = id;
      this.tax_status_id = status;
    },
    save() {
      var status = {
        tax_status_id: this.tax_status_id,
      };
      CarService.updateTaxStatus(this.car_id, status).then(() => {
        alert("บันทึกสำเร็จ");
        document.getElementById("close").click();
        setTimeout(function () {
          location.reload();
        }, 300);
      });
    },
    getTaxStatus() {
      CarTypesService.getTaxStatus().then((res) => {
        this.tax_status = res.data;
      });
    },
    getList() {
      CarService.getCarGroupExpire(this.car_type, "", "").then((res) => {
        // console.log(res.data);
        this.list = res.data;
      });
    },
    search() {
      CarService.getCarGroupExpire(this.car_type, "", this.license).then(
        (res) => {
          // console.log(res.data);
          this.list = res.data;
        }
      );
    },
    getCount() {
      CarService.getCarGroupExpire("", this.car_type, "").then((res) => {
        // console.log(res.data);
        this.count = res.data[0].count;
      });
    },
    getType() {
      CarTypesService.getCarType(this.car_type).then((res) => {
        this.name = res.data;
      });
    },
    getcartypes() {
      CarTypesService.getCarTypes("").then((res) => {
        console.log(res.data);
        this.car_types.push({
          car_type_id: 0,
          name: "ทั้งหมด",
        });
        for (let c = 0; c < res.data.length; c++) {
          this.car_types.push({
            car_type_id: res.data[c].car_type_id,
            name: res.data[c].code + " " + res.data[c].name,
          });
        }
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
  },
  mounted() {
    this.car_type = this.$route.params.id;
    // console.log(this.car_type);
    this.getList();
    this.getType();
    this.getCount();
    this.getTaxStatus();
    var date = new Date();
    this.year = date.getFullYear() + 543;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
  text-decoration: none;
}
</style>
