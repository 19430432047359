import Vue from "vue";
import Router from "vue-router";
import Cars from './components/Cars.vue';
import Dashboard from './components/Dashboard.vue';
import CarLists from './components/CarLists.vue';
import CarTypes from './components/CarTypes.vue';
import CarExpire from './components/CarExpire.vue';
import CarExpireAll from './components/CarExpireAll.vue';
import CarNormalAll from './components/CarNormalAll.vue';
import CarNotYetTax from './components/CarNotYetTax.vue';
import OwnerCarLists from './components/OwnerCarLists.vue';
import OwnerCar from './components/OwnerCar.vue';
import OwnerLists from './components/OwnerLists.vue';
import Owner from './components/Owner.vue';
import DrivingTypeAll from './components/DrivingTypeAll.vue';
import TransportList from './components/TransportList.vue';
import Transport from './components/Transport.vue';
import CheckCarNotYetTax from './components/CheckCarNotYetTax.vue';
import Form from './components/Form.vue';
import FormtList from './components/FormtList.vue';
import Login from './components/Login.vue';
import CarNull from './components/CarNull.vue';
import Car from './components/Car.vue';
import CarNullList from './components/CarNullList.vue';
import CarAdd from './components/CarAdd.vue';
import ReceiptList from './components/ReceiptList.vue';
import Taxation from './components/Taxation.vue';
import TaxList from './components/TaxList.vue';
import Receipt from './components/Receipt.vue';
import TaxInvoice from './components/TaxInvoice.vue';
import TaxationView from './components/TaxationView.vue';
import PrintTaxation from './components/PrintTaxation.vue';
import ReceiptListSuccess from './components/ReceiptListSuccess.vue';

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/cars/:id",
      alias: "/cars",
      name: "cars",
      component: Cars
    },
    {
      path: "/dashboard",
      alias: "/dashboard",
      name: "dashboard",
      component: Dashboard
    },
    {
      path: "/home",
      alias: "/home",
      name: "home",
      component: Dashboard
    },
    {
      path: "/carlists",
      alias: "/carlists",
      name: "carlists",
      component: CarLists
    },
    {
      path: "/cartype/:id",
      alias: "/cartype",
      name: "cartype",
      component: CarTypes
    },
    {
      path: "/carexpire/:id",
      alias: "/carexpire",
      name: "carexpire",
      component: CarExpire
    },
    {
      path: "/carexpireall/:id",
      alias: "/carexpireall",
      name: "carexpireall",
      component: CarExpireAll
    },
    {
      path: "/carnormal/:id",
      alias: "/carnormal",
      name: "carnormal",
      component: CarNormalAll
    },
    {
      path: "/carnotyettax/:id",
      alias: "/carnotyettax",
      name: "carnotyettax",
      component: CarNotYetTax
    },
    {
      path: "/checkcarnotyettax/:id",
      alias: "/checkcarnotyettax",
      name: "checkcarnotyettax",
      component: CheckCarNotYetTax
    },
    {
      path: "/ownercarlists",
      alias: "/ownercarlists",
      name: "ownercarlists",
      component: OwnerCarLists
    },
    {
      path: "/ownercars/:id",
      alias: "/ownercars",
      name: "ownercars",
      component: OwnerCar
    },
    {
      path: "/ownerlists",
      alias: "/ownerlists",
      name: "ownerlists",
      component: OwnerLists
    },
    {
      path: "/owner/:id",
      alias: "/owner",
      name: "owner",
      component: Owner
    },
    {
      path: "/drivingtype/:id",
      alias: "/drivingtype",
      name: "drivingtype",
      component: DrivingTypeAll
    },
    {
      path: "/transportlist",
      alias: "/transportlist",
      name: "transportlist",
      component: TransportList
    },
    {
      path: "/transport/:id",
      alias: "/transport",
      name: "transport",
      component: Transport
    },    
    {
      path: "/form/:id",
      alias: "/form",
      name: "form",
      component: Form
    },
    {
      path: "/formlist",
      alias: "/formlist",
      name: "formlist",
      component: FormtList
    },
    {
      path: "/",
      alias: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/carnull/:id",
      alias: "/carnull",
      name: "carnull",
      component: CarNull
    },
    {
      path: "/car/:id",
      alias: "/car",
      name: "car",
      component: Car
    },
    {
      path: "/carnulllist",
      alias: "/carnulllist",
      name: "carnulllist",
      component: CarNullList
    },
    {
      path: "/caradd/:id",
      alias: "/caradd",
      name: "caradd",
      component: CarAdd
    },
    {
      path: "/taxList",
      alias: "/taxList",
      name: "taxList",
      component: TaxList
    },
    {
      path: "/taxation/:id",
      alias: "/taxation",
      name: "taxation",
      component: Taxation
      
    },
    {
    path: "/receiptList",
    alias: "/receiptList",
    name: "receiptList",
    component: ReceiptList 
  },
  {
    path: "/receipt/:id",
    alias: "/receipt",
    name: "receipt",
    component: Receipt       
  },
  {
    path: "/taxinvoice/:id/:statusid",
    alias: "/taxinvoice",
    name: "taxinvoice",
    component: TaxInvoice       
  },
  {
  path: "/taxationview/:id",
  alias: "/taxationview",
  name: "taxationview",
  component: TaxationView       
},
{
  path: "/printtaxation/:id",
  alias: "/printtaxation",
  name: "printtaxation",
  component: PrintTaxation       
},
{
  path: "/receiptlistsuccess",
  alias: "/receiptlistsuccess",
  name: "receiptlistsuccess",
  component: ReceiptListSuccess       
},
  ]
});

Vue.use(Router);

router.beforeEach((to, from, next) => {
  const publicPages = ['/','/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('userjrc');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) { 
    next('/dashboard');
  } else {
    next();
  }

});

export default router;
