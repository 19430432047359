<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">      
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav" v-if="currentUser">
          <li class="nav-item">
            <a class="nav-link" href="/dashboard">แดชบอร์ด</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/carlists">รถทั้งหมด</a>
          </li>
           <li class="nav-item">
            <a class="nav-link" href="/carnulllist">ลำดับรถว่าง</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/ownercarlists">เจ้าของรถ</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/transportlist">หนังสือสัญญาเดินรถ</a>
          </li>
           <li class="nav-item">
            <a class="nav-link" href="/formlist">แบบฟอร์มเสนอราคา</a>
          </li>
           <li class="nav-item">
            <a class="nav-link" href="/receiptList">ใบเสร็จ</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/taxation/0">ใบเสร็จสำหรับการเสียภาษี</a>
          </li>
           <li class="nav-item">
            <a class="nav-link" href="#" @click.prevent="logOut">ออกจากระบบ</a>
          </li>
        </ul>
        <ul class="navbar-nav" v-else>
          <li class="nav-item">
            <a class="nav-link" href="/">เข้าสู่ระบบ</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Nav",
  props: {
    msg: String,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },    
  },
  mounted() {
      if (!this.currentUser) {
        this.$router.push("/login");
      }
    },
    methods: {
      logOut() {
      this.$store.dispatch("auth/logout");
      setTimeout(function () {
              location.reload();
            }, 500);
      this.$router.push("/");
    },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
