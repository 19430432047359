<template>
  <div class="container mt-3">
    <div class="row mb-5">
      <div class="form-group col-md-6">
        <label for="exampleFormControlSelect1">เลขทะเบียนรถ</label>

        <div v-for="(li, k) in licensedata" :key="k">
          <div class="input-group mb-3">
            <input
              v-model="li.license"
              type="text"
              class="form-control form-control-sm"
              id="exampleFormControlInput1"
              placeholder="เลขทะเบียนรถ"
              @keyup="searchlicense(li.license, k)"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
            <div class="input-group-append">
              <button
                class="btn btn-danger btn-sm"
                type="button"
                @click="deletelicense(k)"
              >
                ลบทะเบียนรถ
              </button>
            </div>
          </div>
        </div>
        <div v-if="index >= 0 && filteredList.length > 0">
          <ul
            class="list-group"
            v-for="(s, i) in filteredList"
            v-bind:value="s.car_id"
            :key="i + 1"
          >
            <div
              class="
                form-control form-control-sm
                d-flex
                justify-content-between
                align-items-center
              "
              style="border-color: blue"
            >
              <a href="#search" @click="getlicense(s)"> {{ s.license }}</a>
            </div>
          </ul>
        </div>
        <!-- <input
                v-model="license"
                type="text"
                class="form-control form-control-sm"
                id="exampleFormControlInput1"
                placeholder="เลขทะเบียนรถ"
              />
               <div v-if="license != '' && filteredList.length > 1">
                <ul
                  class="list-group"
                  v-for="(s, i) in filteredList"
                  v-bind:value="s.car_id"
                  :key="i + 1"
                >
                  <div
                    class="
                      form-control form-control-sm
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    style="border-color: blue;"
                  >
                    <a href="#search" @click="getlicense(s.license,s.car_id)">
                      {{ s.license }}</a
                    >
                  </div>
                </ul>
              </div> -->
        <button type="button" class="btn btn-info mb-3 btn-sm" @click="addlicense()">
          เพิ่มทะเบียนรถ
        </button>
      </div>
      <div class="col-md-12"></div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">ชื่อเจ้าของรถ</label>
        <input
          v-model="owner.name"
          type="text"
          class="form-control form-control-sm"
          id="exampleFormControlInput1"
          placeholder="ชื่อเจ้าของรถ"
        />
      </div>
      <div class="col-md-12"></div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">เบอร์โทรศัพท์</label>
        <input
          v-model="owner.phone"
          type="number"
          class="form-control form-control-sm"
          id="exampleFormControlInput1"
          placeholder="เบอร์โทรศัพท์"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">Line Token</label>
        <input
          v-model="owner.access_token"
          type="text"
          class="form-control form-control-sm"
          id="exampleFormControlInput1"
          placeholder="Line Token"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">บ้านเลขที่</label>
        <input
          v-model="owner.house_no"
          type="text"
          class="form-control form-control-sm"
          id="exampleFormControlInput1"
          placeholder="บ้านเลขที่"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="exampleFormControlInput1">หมู่ที่</label>
        <input
          v-model="owner.moo"
          type="number"
          class="form-control form-control-sm"
          id="exampleFormControlInput1"
          placeholder="หมู่ที่"
        />
      </div>
      <div class="form-group col-md-4">
        <label for="exampleFormControlInput1">จังหวัด</label>
        <select
          v-model="owner.province"
          @change="onChangeDistricts"
          class="form-control form-control-sm"
          id="exampleFormControlSelect1"
        >
          <option v-for="(s, i) in provinces" v-bind:value="s.id" :key="i + 1">
            {{ s.name_in_thai }}
          </option>
        </select>
      </div>

      <div class="form-group col-md-4">
        <label for="exampleFormControlInput1">อำเภอ</label>
        <select
          v-model="owner.district"
          @change="onChangeSubdistricts"
          class="form-control form-control-sm"
          id="exampleFormControlSelect1"
        >
          <option v-for="(s, i) in districts" v-bind:value="s.id" :key="i + 1">
            {{ s.name_in_thai }}
          </option>
        </select>
      </div>

      <div class="form-group col-md-4">
        <label for="exampleFormControlInput1">ตำบล</label>
        <select
          v-model="owner.sub_district"
          @change="onChangeZipcode"
          class="form-control form-control-sm"
          id="exampleFormControlSelect1"
        >
          <option
            v-for="(s, i) in subdistricts"
            v-bind:value="s.id"
            :key="i + 1"
          >
            {{ s.name_in_thai }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-3 mb-5">
      <div class="col-md-12 text-center">
        <!-- <button
            class="btn btn-warning"
            style="color: white; width: 200px"
            @click="back()"
          >
            ย้อนกลับ
          </button> -->
        &nbsp;
        <button
          class="btn btn-success"
          style="color: white; width: 200px"
          @click="save()"
        >
          บันทึก
        </button>
        &nbsp;
        <!-- <button
              class="btn btn-danger"
              style="color: white; width: 200px"
              @click="next()"
            >
              ลบ
            </button> -->
      </div>
    </div>
  </div>
</template>


<script>
import CarService from "../services/CarService";
import OwnerService from "../services/OwnerService";
import AddressService from "../services/AddressService";
import CarsOwnersService from "../services/CarsOwnersService";

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      carowner_id: 0,
      owners: [],
      licenses: [],
      owner: [],
      provinces: [],
      districts: [],
      subdistricts: [],
      license: "",
      licensedata: [],
      filteredList: [],
      index: "",
    };
  },
  methods: {
    deletelicense(index) {
      console.log(index);
      for (let l = 0; l < this.licensedata.length; l++) {
        if (l == index) {
          this.licensedata.splice(index, 1);
        }
      }
    },
    getlicense(license) {
      console.log(this.index);
      this.licensedata[this.index].car_id = license.car_id;
      this.licensedata[this.index].license = license.license;
      this.filteredList = [];
    },
    onChangeDistricts(event) {
      var province_id = event.target.value;
      this.getDistricts(province_id);
      this.owner.sub_district = "";
      this.subdistricts = [];
    },
    onChangeSubdistricts(event) {
      var district_id = event.target.value;
      this.getSubdistricts(district_id);
    },
    onChangeZipcode(event) {
      var subdistrict_id = event.target.value;
      this.getSubDistrict(subdistrict_id);
    },
    getProvinces() {
      AddressService.getProvinces().then((res) => {
        // console.log(res.data);
        this.provinces = res.data;
      });
    },
    getDistricts(id) {
      AddressService.getDistricts(id).then((res) => {
        // console.log(res.data);
        this.districts = res.data;
      });
    },
    getSubdistricts(id) {
      AddressService.getSubDistricts(id).then((res) => {
        // console.log(res.data);
        this.subdistricts = res.data;
      });
    },
    getSubDistrict(id) {
      AddressService.getSubDistrict(id).then((res) => {
        // console.log(res.data);
        this.owner.zipcode = res.data.zipcode;
      });
    },
    getLicense() {
      CarService.getLicense().then((res) => {
        // console.log(res.data);
        this.licenses = res.data;
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    saveowner() {
      if (this.owner.name == null) {
        alert("กรุณากรอกชื่อเจ้าของรถ");
      } else if (this.owner.house_no == null) {
        alert("กรุณากรอกบ้านเลขที่");
      } else if (this.owner.moo == null) {
        alert("กรุณากรอกหมู่ที่");
      } else if (this.owner.sub_district == null) {
        alert("กรุณาเลือกตำบล");
      } else if (this.owner.district == null) {
        alert("กรุณาเลือกอำเภอ");
      } else if (this.owner.province == null) {
        alert("กรุณาเลือกจังหวัด");
      } else {
        var car = {
          name: this.owner.name,
          house_no: this.owner.house_no,
          moo: this.owner.moo,
          sub_district: this.owner.sub_district,
          district: this.owner.district,
          province: this.owner.province,
        };
        OwnerService.createOwner(car).then((res) => {
          console.log(res.data);
          alert("บันทึกสำเร็จ");
          document.getElementById("close").click();
          this.getOwner();
        });
      }
    },
    save() {
      if (this.licensedata[0].car_id == null) {
        alert("กรุณาเลือกทะเบียนรถ");
      } else if (this.owner.name == null) {
        alert("กรุณากรอกชื่อเจ้าของรถ");
      } else if (this.owner.phone == null) {
        alert("กรุณากรอกเบอร์โทรศัพท์");
      } else if (this.owner.access_token == null) {
        alert("กรุณากรอก Line Token");
      } else if (this.owner.house_no == null) {
        alert("กรุณากรอกบ้านเลขที่");
      } else if (this.owner.moo == null) {
        alert("กรุณากรอกหมู่ที่");
      } else if (this.owner.sub_district == null) {
        alert("กรุณาเลือกตำบล");
      } else if (this.owner.district == null) {
        alert("กรุณาเลือกอำเภอ");
      } else if (this.owner.province == null) {
        alert("กรุณาเลือกจังหวัด");
      } else {
        console.log(this.licensedata);
        var car = {
          car_id: this.owner.car_id,
          name: this.owner.name,
          house_no: this.owner.house_no,
          moo: this.owner.moo,
          sub_district: this.owner.sub_district,
          district: this.owner.district,
          province: this.owner.province,
          phone: this.owner.phone,
          access_token: this.owner.access_token,
        };
        if (this.carowner_id != 0) {
          CarsOwnersService.deleteCarAllByOwner(this.carowner_id).then(
            () => {}
          );
          OwnerService.updateOwner(this.carowner_id, car).then(() => {
            for (let c = 0; c < this.licensedata.length; c++) {
              if (this.licensedata[c].car_id != null) {
                var ownercar = {
                  car_id: this.licensedata[c].car_id,
                  owner_id: this.carowner_id,
                };
                CarsOwnersService.createCarOwner(ownercar).then((res) => {
                  console.log(res.data);
                });
              }
            }
            this.$router.push("/ownercars/" + this.carowner_id);
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 1000);
            window.scrollTo(0, 0);
          });
        } else {
          OwnerService.createOwner(car).then((res) => {
            console.log(res.data);
            this.carowner_id = res.data.id;
            for (let c = 0; c < this.licensedata.length; c++) {
              if (this.licensedata[c].car_id != null) {
                var ownercar = {
                  car_id: this.licensedata[c].car_id,
                  owner_id: this.carowner_id,
                };
                CarsOwnersService.createCarOwner(ownercar).then((res) => {
                  console.log(res.data);
                });
              }
            }
            this.$router.push("/ownercars/" + this.carowner_id);
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 1000);
            window.scrollTo(0, 0);
          });
        }
      }
    },
    getcar() {
      OwnerService.getOwner(this.carowner_id).then((res) => {
        console.log(res.data);
        this.owner = res.data;
        this.itemPreviewImage = this.owner.image;
        this.license = this.owner.license;
        this.getProvinces();
        this.getDistricts(this.owner.province);
        this.getSubdistricts(this.owner.district);
      });
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.createImage(files[0]);
      // this.createBase64Image(selectedImage);
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.itemPreviewImage = evt.target.result;
        this.image = evt.target.result;
      };

      reader.readAsDataURL(file);
    },
    addlicense() {
      this.licensedata.push({
        car_id: "",
        license: "",
      });
    },
    searchlicense(data, index) {
      console.log(index);
      this.index = index;
      this.filteredList = this.licenses.filter((post) => {
        return post.license.toLowerCase().includes(data.toLowerCase());
      });
    },
    getCarAll() {
      CarsOwnersService.getCarAllByOwner(this.carowner_id).then((res) => {
        console.log(res.data);
        this.licensedata = res.data;
      });
    },
  },
  mounted() {
    this.carowner_id = this.$route.params.id;
    console.log(this.carowner_id);
    if (this.carowner_id != 0) {
      this.getcar();
      this.getCarAll();
    } else {
      this.owner.status = 1;
      this.getProvinces();
      this.getDistricts("");
      this.getSubdistricts("");
      this.addlicense();
    }
    this.getLicense();
  },
  computed: {
    // filteredList() {
    //   return this.licenses.filter((post) => {
    //     return post.license.toLowerCase().includes(this.license.toLowerCase());
    //   });
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-weight: bold;
}
a {
  color: black;
}
</style>
