<template>
  <div>
    <div class="container mt-3 mb-3">
      <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3"></div>
            <div class="form-group col-md-6">
              <label for="exampleFormControlInput1">ชื่อเจ้าของรถ</label>
              <input
                v-model="name"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="ชื่อเจ้าของรถ"
              />
            </div>
            <div class="col-md-3"></div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <!-- <button
            class="btn btn-warning"
            style="color: white; width: 200px"
            @click="back()"
          >
            ย้อนกลับ
          </button> -->
              &nbsp;
              <button
                class="btn btn-primary"
                style="color: white; width: 200px"
                @click="search()"
              >
                ค้นหา
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 5px; padding: 5px">
      <div class="col text-right mb-3">
        <a href="owner/0">
          <button
            class="btn btn-success"
            style="color: white; background-color: #09cc68"
          >
            <i class="fa fa-plus"></i> เพิ่มข้อมูล
          </button></a
        >
      </div>
      <!-- <div v-for="(l, i) in list" :key="i">
      {{ l.code }} {{ l.name }} -->
      <table class="table table-bordered" v-if="list.length > 0">
        <thead>
          <tr class="table-active">
            <th scope="col">ชื่อเจ้าของรถ</th>
            <th scope="col">ที่อยู่</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in list" :key="i">
            <td scope="row">
              <a :href="'owner/' + d.owner_id"> {{ d.name }}</a>
            </td>
            <td>
              <a :href="'owner/' + d.owner_id"
                >{{ d.house_no }} <span v-if="d.moo">{{ 'ม.' + d.moo }}</span> {{ d.sub_district_name }} {{ d.district_name }} {{ d.province_name }}</a
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else style="text-align: center">ไม่พบข้อมูล</div>
    </div>
  </div>
</template>

<script>
import OwnerService from "../services/OwnerService";
// import CarTypesService from "../services/CarTypesService";

export default {
  name: "Carlist",
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      car_types: [],
      license: "",
      car_type: "",
      name: "",
    };
  },
  methods: {
    getList() {
      OwnerService.getOwners('').then((res) => {
        console.log(res.data);
        this.list = res.data;
      });
    },
    // getcartypes() {
    //   CarTypesService.getCarTypes().then((res) => {
    //     console.log(res.data);
    //     this.car_types.push({
    //       car_type_id: 0,
    //       name: "ทั้งหมด",
    //     })
    //     for (let c = 0; c < res.data.length; c++) {
    //       this.car_types.push({
    //       car_type_id: res.data[c].car_type_id,
    //       name: res.data[c].code +" "+ res.data[c].name,
    //     })
    //     }
    //   });
    // },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    search() {
      console.log(this.name);
      OwnerService.getOwners(this.name).then((res) => {
        console.log(res.data);
        this.list = [];
        this.list = res.data;
      });
    },
  },
  mounted() {
    this.getList();
    this.car_type = 0;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
  text-decoration: none;
}
</style>
